<template>
  <div class="flex flex-col min-h-screen">
    <div class="flex flex-col flex-grow">
      <main class="flex flex-grow">
        <Sidebar />
        <slot />
      </main>
    </div>
    <t-footer />
  </div>
</template>

<script>
import Sidebar from '@/components/dashboard/sidebar';

export default {
  name: 'DashboardLayout',
  components: { Sidebar }
};
</script>
