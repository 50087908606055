<template>
  <div>
    <h1 class="mb-8 text-xl font-bold text-primary">
      Facturation
    </h1>

    <div v-if="isCurrentAccountPro" class="flex items-center">
      <input type="checkbox" class="mr-3 cursor-pointer" v-model="clientBilling" @click="handleClientBillingForm" />
      <label for="fullname" class="billing-label" @click="handleClientBillingForm">Facturer votre client ?</label>
    </div>

    <t-alert v-if="isCurrentAccountPro" class="mt-5">
      <p>Votre client recevra un lien de paiement par email et il renseignera ses informations de facturation lors du paiement.</p>
    </t-alert>

    <div v-if="clientBilling && isCurrentAccountPro">
      <div class="grid grid-cols-12 mt-4 mb-8">
        <div class="col-span-12">
          <div class="input">
            <label for="fullname">Email client</label>
            <input type="email" v-model="clientEmail" />
            <div v-if="error" class="error">Veuillez entrer une adresse email valide</div>
          </div>
        </div>
      </div>
      <t-submit-button
        type="button"
        :labels="{ default: 'Envoyer un lien de paiement au client' }"
        @click="sendPaymentLinkToClient"
      />
    </div>

    <div v-if="!isCurrentAccountPro">
      <div class="grid grid-cols-12 gap-4 mt-4">
      <div class="col-span-5">
        <div class="input">
          <label for="fullname">Nom et prénom</label>
          <input type="text" v-model="fullname" />
        </div>
      </div>

      <div class="col-span-2 text-center required input">
        <label><b>et/ou</b></label>
      </div>

      <div class="col-span-5">
        <div class="input">
          <label for="companyName">Nom entreprise</label>
          <input type="text" v-model="companyName" />
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 mt-4">
      <div class="col-span-1">
        <div class="input required">
          <label for="street">Adresse</label>
          <input type="text" v-model="street" />
        </div>
      </div>
    </div>

    <div class="grid grid-cols-3 gap-8 mt-4">
      <div class="col-span-1">
        <div class="input required">
          <label for="zipCode">Code postal</label>
          <input type="text" maxlength="5" v-on:keypress="checkInput" v-model="zipCode" />
          <div v-if="isNumbersOnly" class="text-sm text-red-500">Chiffres uniquement</div>
        </div>
      </div>

        <div class="col-span-2">
          <div class="input required">
            <label for="locality">Ville</label>
            <input type="text" v-model="locality" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="!isCurrentAccountPro && !billingDone" class="mt-8 text-right">
      <t-submit-button
        type="button"
        :disabled="isDisabled"
        :labels="{ default: 'Continuer' }"
        :class="{'cursor-not-allowed': isDisabled}"
        @click="confirmBillingAddress"
      />
    </div>
  </div>
</template>

<script>
import * as ClientBillingService from '@/services/client-billing-service';
import { mapActions, mapState } from 'vuex';
import { numbersOnly, createComputed } from '@/helpers';
import { regex } from '@/constants';

export default {
  name: 'BillingAddressForm',
  props: {
    encodedLegalAdId: {
      default: '',
      type: String,
      required: false,
    }
  },
  data() {
    return {
      isNumbersOnly: false,
      clientBilling: false,
      billingDone: false,
      clientEmail: null,
      error: false
    }
  },
  computed: {
    ...mapState(['legalAd']),
    isDisabled() {
      return (!this.fullname || !this.companyName) && !this.street && !this.zipCode && !this.locality;
    },
    fullname: createComputed('fullname'),
    companyName: createComputed('companyName'),
    street: createComputed('street'),
    zipCode: createComputed('zipCode'),
    locality: createComputed('locality')
  },
  mounted() {
    this.setBillingAddressForPro();
  },
  methods: {
    ...mapActions(['changeLegalAdBillingAddress']),
    change(attribute, value) {
      this.changeLegalAdBillingAddress({ changes: { [attribute]: value } })
    },
    checkInput(event) {
      this.isNumbersOnly = numbersOnly(event);
    },
    confirmBillingAddress() {
      this.billingDone = true;
      this.$emit('confirm-billing-address');
    },
    handleClientBillingForm() {
      this.clientBilling = !this.clientBilling;
      this.$emit('confirm-billing-address', this.clientBilling);

      if (this.clientBilling) {
        this.resetBillingAddressForPro();
      } else {
        this.setBillingAddressForPro();
      }
    },
    setBillingAddressForPro() {
      if (this.isCurrentAccountPro && this.currentAccount.addressAttributes) {
        this.fullname = this.currentAccount?.addressAttributes?.fullname;
        this.companyName = this.currentAccount?.addressAttributes?.companyName;
        this.street = this.currentAccount?.addressAttributes?.street;
        this.zipCode = this.currentAccount?.addressAttributes?.zipCode;
        this.locality = this.currentAccount?.addressAttributes?.locality;
      }
    },
    resetBillingAddressForPro() {
      this.fullname = null;
      this.companyName = null;
      this.street = null;
      this.zipCode = null;
      this.locality = null;
    },
    async sendPaymentLinkToClient() {
      if (regex.email.test(this.clientEmail) && this.encodedLegalAdId) {
        ClientBillingService.sendPaymentLink(this.clientEmail, this.encodedLegalAdId)
          .then((status) => {
            if (status === 204) {
              this.error = false;
              this.$router.push({ name: 'myProfile' })
            }
          })
      } else {
        this.error = true;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .billing-label {
    cursor: pointer;
  }
</style>
