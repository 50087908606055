<template>
  <div v-if="legalAd" class="html-preview">
    <div class="bg-gradient-to-b from-gray-100 px-4 py-4"> 
      <div v-if="content.logo" class="text-center">
        <img :src="content.logo.data" alt="Logo preview" class="mx-auto" style="max-height: 8rem" />
      </div>
      <hr class="w-1/2 my-3 mx-auto" />
      <div v-if="content.preBlock1.enabled" class="text-center">
        <h4 v-html="format(content.preBlock1.title)" />
        <p v-html="format(content.preBlock1.body)" />
        <hr class="w-1/2 my-3 mx-auto" />
      </div>
      <div v-if="content.preBlock2.enabled" class="text-center">
        <h2 v-html="format(content.preBlock2.title)" />
        <p v-html="format(content.preBlock2.body)" />
        <hr class="w-1/2 my-3 mx-auto" />
      </div>
      <div v-if="content.title">
        <h4 class="text-center" v-html="format(content.title)" />
        <hr class="w-1/2 my-3 mx-auto" />
      </div>
      <div class="text-justify" v-html="format(content.body)"></div>      
      <div class="text-right" v-if="content.signature" v-html="format(content.signature)" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HTMLPreview',
  props: {
    legalAd: { type: Object, default: undefined },
  },
  computed: {
    content() {
      return this.legalAd?.content;
    }
  },
  methods: {
    format(content) {
      return content.replace(/\n/g, '<br/>');
    }
  },
};
</script>

<style lang="scss" scoped>
  .html-preview {
    position: sticky;
    top: 0;
    height: 50vh;
    overflow: scroll;
  }

  .logo {
    max-height: 4rem;
  }

  @media (max-width: 992px) {
    .html-preview {
      display: none;
    }
  }
</style>
