import { CIVILITY, validatePresenceOf } from '@/shared/utils';

export const validate = (person, options) => {
  var fields = ['type', 'address', 'zipCode', 'city'];
  switch (person.type) {
    case 'physical':
      fields = fields.concat(['civility', 'firstName', 'lastName']);
      break;
    case 'physicalAndRegistered':
      fields = fields.concat(['civility', 'firstName', 'lastName', 'registrationPlace', 'registrationNumber']);
      break;
    case 'moral':
      fields = fields.concat(['companyName', 'legalForm', 'registrationPlace', 'registrationNumber', 'shareCapital']);
      break;
    case 'moralAndRegistering':
      fields = fields.concat(['companyName', 'legalForm']);
      break;
  }
  return validatePresenceOf(person, fields, options);
};

export const buildSimpleContent = (person) => {
  var line = '';
  var address = `${person.address} ${person.zipCode} ${person.city}`;

  switch (person.type) {
    case 'physical':
      line = `${CIVILITY[person.civility]} ${person.firstName} ${person.lastName} demeurant ${address}`;
      break;
    case 'physicalAndRegistered':
      line = `${CIVILITY[person.civility]} ${person.firstName} ${person.lastName} demeurant ${address}`;
      line = `${line}, immatriculé au RCS de ${person.registrationPlace} sous le numéro ${person.registrationNumber}`;
      break;
    case 'moral':
      line = `${person.companyName} ${person.legalForm}`;
      if (person.shareCapital) line = `${line} au capital de ${person.shareCapital} euros`;
      line = `${line}, sise ${address}`;
      line = `${line}, immatriculé au RCS de ${person.registrationPlace} sous le numéro ${person.registrationNumber}`;
      break;
    case 'moralAndRegistering':
      line = `${person.companyName} ${person.legalForm}`;
      line = `${line}, sise ${address}`;
      line = `${line}, société en cours de constitution.`;
      break;
  }

  return line;
};

export const buildContent = (person, title) => {
  var line = `${title} : ${buildSimpleContent(person)}`;
  return [line];
};
