<template>
  <div
    class="px-4 py-3 text-center"
    :class="{
      'bg-yellow-100 text-yellow-600': level === 'warning',
      'bg-green-100 text-green-600': level === 'success',
      'bg-purple-200 text-primary': level === 'notice',
      'bg-red-100 text-red-600': level === 'danger',
      'bg-light-gray text-gray-600': level === 'secondary',
    }"
    role="alert"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'KitAlert',
  props: {
    level: { type: String, default: 'warning' },
  },
};
</script>
