import * as Company from '@/services/legal-ad-form/components/modification/company';
import * as HeadOffice from '@/services/legal-ad-form/components/modification/head-office';
import * as Decision from '@/services/legal-ad-form/components/modification/decision';
import * as Modification from '@/services/legal-ad-form/components/modification';
import * as Mention from '@/services/legal-ad-form/components/modification/mention';
import * as Person from '@/services/legal-ad-form/components/transformation/person';
import { trimObject } from '@/shared/utils';

export default { //transfert_siege_hors_ressort_nouveau_departement_societe_civile
  id: 'transfert-siege-nouveau-departement-commerciale',
  label: 'Transfert de siège hors ressort - Nouveau département (société commerciale)',
  shortLabel: 'Transfert de siège\nsocial',
  componentsData: {
    company: { legalForm: '' },
    headOffice: {},
    decision: { type: '', actor: '' },
    modification: {},
    ownerList: [],
    chairmanList: [],
    ceoList: [],
    chiefExecutiveList: [],
    assistantChiefExecutiveList: [],
    otherMemberList: [],
    mention: { registry: 'Radiation du RCS [Ancien greffe] et immatriculation au RCS [Nouveau greffe]' } 
  },
  component: () => import('@/components/legal-ads/forms/models/modification/transfer-new-state-2.vue'),   
  validate(modelData) {
    return trimObject({ 
      company: Company.validate(modelData.company),
      headOffice: HeadOffice.validate(modelData.headOffice),    
      decision: Decision.validate(modelData.decision),
      modification: Modification.validateTransfer(modelData.modification),
      mention: Mention.validate(modelData.mention),
      ownerList: Person.validateMany(modelData.ownerList, false),
      chairmanList: Person.validateMany(modelData.chairmanList, false),
      ceoList: Person.validateMany(modelData.ceoList, false),
      chiefExecutiveList: Person.validateMany(modelData.chiefExecutiveList, false),
      assistantChiefExecutiveList: Person.validateMany(modelData.assistantChiefExecutiveList, false),
      otherMemberList: Person.validateMany(modelData.otherMemberList, false),
    });
  },  
  buildContent(modelData) {
    return [
      Company.buildContent(modelData.company, { withShareCapital: true }),
      HeadOffice.buildContent(modelData.headOffice),     
      Modification.buildTransferContent(modelData),
      Modification.buildSocialObjectContent(modelData),
      Mention.buildContent(modelData.mention),
      Person.buildManyContent(modelData.ownerList, 'Gérant'),
      Person.buildManyContent(modelData.chairmanList, 'Président'),
      Person.buildManyContent(modelData.ceoList, 'Président directeur général'),
      Person.buildManyContent(modelData.chiefExecutiveList, 'Directeur général'),
      Person.buildManyContent(modelData.assistantChiefExecutiveList, 'Directeur général délégué'),
      Person.buildManyContent(modelData.otherMemberList, 'Personne ayant le pouvoir d\'engager la société')
    ].flat().join("\n").replace(/undefined/g, '?');
  }
};