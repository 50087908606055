<template>
  <div>
    <h1 class="text-primary text-lg font-extrabold flex">
      <span class="border-b border-yellow-300 pb-4">Mot de passe oublié</span>
    </h1>

    <t-alert v-if="hasError" class="mt-8">
      Nous n'avons pas pu trouver un compte associé à cet email.
    </t-alert>

    <t-alert v-if="isSuccess" class="mt-8" level="success">
      Les instructions pour définir votre nouveau mot de passe vous ont été envoyées par email.
    </t-alert>

    <div v-else class="mt-8">
      <p class="text-gray-700">
        Entrez votre adresse email et nous vous enverrons les instructions pour définir votre nouveau mot de passe.
      </p>

      <form class="mt-8" @submit.prevent="forgotPassword">
        <div class="mb-10 input">
          <label for="auth-email">Adresse email</label>
          <input id="auth-email" v-model="email" type="text" />
        </div>

        <div class="mt-12 text-right">
          <t-submit-button
            :button-state="buttonState"
            :labels="{ default: 'Envoyez les instructions', inProgress: 'Envoi en cours', success: 'Instructions envoyées !', fail: 'Échec' }"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import * as AuthService from '@/services/authentication';

export default {
  name: 'AuthForgotPassword',
  data() {
    return {
      isSuccess: false,
      hasError: false,
      email: null,
      buttonState: 'default'
    };
  },
  methods: {
    forgotPassword() {
      this.buttonState = 'inProgress';
      this.hasError = false;
      AuthService.sendResetPasswordInstructions(this.email)
      .then(() => this.onSuccess())
      .catch(() => this.onError())
      .then(() => this.buttonState = 'default');
    },
    onSuccess() {
      this.email = null;
      this.isSuccess = true;
    },
    onError() {
      this.hasError = true;
    },
  }
};
</script>
