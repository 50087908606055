import api from '@/shared/api';
import { isBlank, toDataURL } from '@/shared/utils';

export const isValid = (legalAd, isPro = false) => {
  const isConstitution = legalAd?.content?.adType === 'constitution';
  const isModel = legalAd?.model;
  const hasPublishingInfo =  !isBlank(legalAd.newspaperId) && 
                             !isBlank(legalAd.format) &&
                             !isBlank(legalAd.publishingAt)

  if (isPro) {
    if (isConstitution) {
      return  hasPublishingInfo &&
              !isBlank(legalAd.content?.adType) &&
              !isBlank(legalAd.content?.companyType) && 
              !isBlank(legalAd.altName);
    } else if (isModel) {
      return  hasPublishingInfo &&
              !isBlank(legalAd.altName);
    } else {
      return  hasPublishingInfo &&
              !isBlank(legalAd.content?.adType) &&
              !isBlank(legalAd.altName);
    }
  } else {
    if (isConstitution) {
      return  hasPublishingInfo &&
              !isBlank(legalAd.content?.adType) &&
              !isBlank(legalAd.content?.companyType);
    } else if (isModel) {
      return  hasPublishingInfo;
    } else {
      return  hasPublishingInfo &&
              !isBlank(legalAd.content?.adType);
    }
  }
}

export const getBase64Pdf = legalAd => {
  var attributes = { ...legalAd, content: { ...legalAd?.content }, base64Logo: legalAd?.content?.logo?.data };
  delete(attributes.content.logo);
  return api.post('/pdf_previews', { legalAd: attributes }).then(({ data }) => data.pdf) 
};

export const findAll = page => {
  return api.get('/legal_ads', { params: { page } })
  .then(({ data }) => data)
  .catch(error => ({ list: [], meta: {}, error }));
};

export const find = id => {
  return api.get(`/legal_ads/${id}`)
  .then(async ({ data: { name, altName, confirmed, publishingAt, content, model, logoUrl, newspaperId, format, address } }) => {
    return { 
      name,
      altName,
      newspaperId,
      format,
      confirmed,
      publishingAt: publishingAt ? new Date(publishingAt) : null,
      content: { ...content, logo: logoUrl ? await toDataURL(logoUrl) : null },
      model,
      address
    };
  })
  .catch(error => {
    console.log('error loading a legal ad', error);
    return null;
  })
}

export const update = (id, legalAd) => {
  var attributes = { ...legalAd, content: { ...legalAd.content }, base64Logo: legalAd.content.logo?.data };
  delete(attributes.content.logo);
  if (attributes.publishingAt) {
    var date = attributes.publishingAt;
    attributes.publishingAt = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;    
  }
  return api.put(`/legal_ads/${id}`, { legalAd: attributes }).then(({ data }) => data); 
}