<template>
  <nav v-if="isVisible" aria-label="Pagination des annonces légales">
    <ul class="pagination justify-content-center">
      <li class="page-item" :class="{ 'disabled': !meta.prev }">
        <button class="page-link" tabindex="-1" @click="loadPage(meta.prev)">Précédent</button>
      </li>

      <li
        v-for="page in meta.series"
        :key="`page-${page}`"
        class="page-item"
        :class="{ 'active': parseInt(page) === meta.page }"
      >
        <span v-if="page === 'gap'" class="page-link">
          ...
        </span>
        <span v-else>
          <span v-if="parseInt(page) === meta.page" class="page-link">
            {{ page }}
            <span class="sr-only">(page courante)</span>
          </span>
          <button
            v-else
            class="page-link"
            @click="loadPage(page)"
          >
            {{ page }}
          </button>
        </span>
      </li>

      <li class="page-item" :class="{ 'disabled': !meta.next }">
        <button class="page-link" @click="loadPage(meta.next)">Suivant</button>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'MyLegalAdPagination',
  props: {
    meta: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    isVisible() {
      return this.meta.pages > 1;
    }
  },
  methods: {
    loadPage(page) {
      this.$emit('loadPage', page);
    }
  }

};
</script>

<style lang="scss" scoped>

  .pagination {
    @apply flex justify-center;

    .page-link {
      @apply relative block px-3 py-1 ml-0 text-gray-600 bg-white;
    }

    .page-link:hover {
      @apply text-gray-700 hover:bg-gray-200;
    }

    .page-item.active .page-link {
      @apply text-white bg-primary;
    }

    .page-item.disabled .page-link {
      @apply text-gray-300 pointer-events-none cursor-auto;
    }
  }

</style>>
