import { validatePresenceOf, formatLegalActDate } from '@/shared/utils';

export const validate = act => {
  var errors = validatePresenceOf(act, ['type']);  
  if (act &&!act.date) errors.date = 'Doit être rempli.';
  return errors;
}

export const buildContent = (act, registrationType) => {
  var lines = [];
  if (act) {
    if (act.type === 'private_deed')
      lines.push(`Il a été constitué ${registrationType} par acte sous seing privé`)
    else if (act.type === 'notarial_deed') {
      var line = `Il a été constitué ${registrationType} par acte authentique`;
      if (act.officer?.trim()?.length > 0) 
        line += ` reçu par ${act.officer}`;
      lines.push(line);
    }
    if (act.date)
      lines.push(`, en date du ${formatLegalActDate(act.date)}`);
    if (act.place?.trim()?.length > 0) 
      lines.push(`, à ${act.place}`);
    
    if (lines.length > 0) lines.push('.');
  }
  
  return [lines.join('')];
}