<template>
  <div class="flex flex-col h-full">
    <nav class="flex flex-col sm:flex-row" :class="sharedClass">
      <button
        v-for="(tab, index) in tabs"
        :key="`tab-${index}`"
        type="button"
        class="z-10 flex items-center py-1 pt-2 pl-8 pr-20 border-t-2 border-gray-300 hover:text-editor-primary focus:outline-none"
        :class="{
          'text-black font-bold border-primary': index === currentIndex,
          'text-gray-500': index !== currentIndex
        }"
        @click="selectTab(index)"
      >
        <span
          class="flex-shrink-0 w-3 h-3 mr-2 rounded-full"
          :class="{
            'bg-primary': index === currentIndex,
            'border-dark-gray border': index !== currentIndex
          }"
        >
          &nbsp;
        </span>
        <span>{{ tab.name }}</span>
      </button>
    </nav>
    <div ref="tabBody" class="flex-1 pb-4 mt-4" :class="sharedClass">
      <transition :name="slideDirection" mode="out-in">
        <component :is="currentTabComponent" v-bind="{...otherProps}" v-on="$listeners" />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    tabs: { type: Array, default: () => ([]) },
    firstIndex: { type: Number, default: 0 },
    otherProps: { type: Object, default: () => ({}) },
    sharedClass: { type: [String, Object], default: () => ({}) },
  },
  data() {
    return { currentIndex: 0, slideDirection: null };
  },
  computed: {
    currentTabComponent() {
      return this.tabs[this.currentIndex].tab;
    },
  },
  watch: {
    firstIndex: {
      immediate: true,
      handler() {
        this.currentIndex = this.firstIndex;
      },
    },
    currentIndex(newIndex, oldIndex) {
      this.slideDirection = newIndex > oldIndex ? 'slide-left' : 'slide-right';
    }
  },
  methods: {
    selectTab(index) {
      const nextTab = this.tabs[index];
      if (nextTab.disabled) return;
      if (this.currentIndex !== index) this.$emit('tab-changed', index);
      this.currentIndex = index;
      window.scroll({ top: this.$refs.tabBody.offsetTop, behavior: 'smooth' });
    }
  }
};
</script>
