import api from '@/shared/api';

export const sendRequest = async (accountId, contactRequest) => {
  const { fullName, email, phoneNumber, message } = contactRequest;

  try {
    const response = await api.get(`/additional_services?id=${accountId}&fullname=${fullName}&email=${email}&phone_number=${phoneNumber}&message=${message}`);
    return response.status;
  } catch (error) {
    return console.error(error);
  }
};
