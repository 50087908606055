<template>
  <div v-if="editor" class="editor">
    <EditorMenuBubble v-slot="{ commands, isActive, menu }" :editor="editor" :keep-in-bounds="keepInBounds">
      <div
        class="editor-menububble"
        :class="{ 'is-active': menu.isActive }"
        :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
      >
        <button
          class="btn btn-secondary btn-xs"
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
        >
          <font-awesome-icon icon="bold" />
        </button>
      </div>
    </EditorMenuBubble>
    <EditorContent class="editor-content" :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorMenuBubble, EditorContent } from 'tiptap';
import { Bold, Placeholder  } from 'tiptap-extensions';
import CustomHardBreak from '@/shared/editor_hard_break';

export default {
  name: 'RichTextEditor', 
  components: { EditorMenuBubble, EditorContent },
  props: {
    body: {
      type: String,
      default: undefined
    },
    isAdmin: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      keepInBounds: true,
      editor: null,
      bodyClick: true
    };
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        new Bold(),
        new CustomHardBreak(),
        new Placeholder({
          emptyEditorClass: 'is-editor-empty',
          emptyNodeClass: 'is-empty',
          emptyNodeText: 'Ici vous pouvez saisir ou copier/coller et mettre en page votre annonce.',
          showOnlyWhenEditable: true,
          showOnlyCurrent: true,
        }),
      ],
      content: this.body,
      onUpdate: ({ getHTML }) => {
        var content = getHTML()
        .replace(/&nbsp;/g, ' ')
        .replace(/&amp;/g, '&')
        .replace(/<br\s*\/?>/g, '\n');
        this.$emit('change', content);
      },
      onFocus: () => {
        if (this.bodyClick && this.isClearingInput) {
          this.editor?.clearContent();
          this.bodyClick = false;
        }
      }
    });
  },
  beforeDestroy() {
    // Always destroy your editor instance when it's no longer needed
    this.editor?.destroy();
  }
};
</script>
