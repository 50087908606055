import Vue from 'vue';
import App from './App.vue';
import store from '@/store';
import '@/plugins';
import '@/mixins';
import '@/components/kit';
import router from '@/router';
import * as AuthService from '@/services/authentication';
import TextareaAutosize from 'vue-textarea-autosize';

// Sentry integration
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import { SENTRY_DSN, SENTRY_ENV, SENTRY_TRACES_SAMPLE } from '@/shared/config';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV,
  ignoreErrors: [/unauthorized access/],
  integrations: [
    new Integrations.BrowserTracing(),
    new VueIntegration({
      Vue,
      tracing: true,
      attachProps: true,
      logErrors: true
    })
  ],
  tracesSampleRate: SENTRY_TRACES_SAMPLE
});

Vue.use(TextareaAutosize);
Vue.config.productionTip = false;

AuthService.localAuthenticate();

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app');
