<template>
  <div class="flex flex-col">
    <modal name="client-billing-modal"
      height="auto"
      :scrollable="true"
    >
      <ClientBillingModal :encodedId="encodedId" :legalAdId="legalAdId" @modal-closed="loadPage(1)" />
    </modal>

    <div v-if="isLoading" class="flex items-center justify-center flex-grow">
      <font-awesome-icon icon="spinner" size="2x" spin />
    </div>

    <div v-else>
      <t-alert v-if="apiBlocked">
        <h5 class="text-lg font-bold">
          Nous ne sommes pas en mesure d'afficher vos annonces
        </h5>
        <p class="mt-2">
          Veuillez vérifier sur votre navigateur que vous avez désactivé tout
          plugin bloquant les publicités.
        </p>
      </t-alert>

      <div class="py-8" v-else>
        <t-alert v-if="!notEmpty">
          <h5 class="text-lg font-bold">
            Vous n'avez pas encore crée de devis.
          </h5>
        </t-alert>

        <table class="table" v-else>
          <thead>
            <tr>
              <th scope="col">Référence</th>
              <th scope="col">Référence interne</th>
              <th scope="col" class="text-center">Montant TTC</th>
              <th scope="col" class="text-center">Facturation client</th>
              <th scope="col" class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <table-row
              v-for="legalAd in legalAds"
              :key="legalAd.reference"
              @on-delete="reloadPage"
              @on-client-billing="displayClientBillingModal"
              :legalAd="legalAd"
            />
          </tbody>
        </table>

        <Pagination
          :meta="meta"
          class="mt-8"
          v-if="meta"
          @loadPage="loadPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as LegalAdDraftService from "@/services/legal-ad-draft";
import TableRow from "./table-row";
import Pagination from "@/components/my-account/legal-ads/pagination";
import { ClientBillingModal } from '@/components/legal-ads/billing';

export default {
  name: "MyLegalAdDrafts",
  components: { TableRow, Pagination, ClientBillingModal },
  data() {
    return {
      isLoading: true,
      legalAds: null,
      meta: null,
      apiBlocked: false,
      currentPage: 1,
      encodedId: null,
      legalAdId: null
    };
  },
  mounted() {
    this.loadPage(1);
  },
  computed: {
    notEmpty() {
      return this.legalAds?.length > 0;
    },
  },
  methods: {
    async loadPage(page) {
      this.isLoading = true;
      var { list, meta, error } = await LegalAdDraftService.findAll(page);
      this.legalAds = list;
      this.meta = meta;
      this.apiBlocked = error?.blocked;
      this.isLoading = false;
      this.currentPage = page;
    },
    reloadPage() {
      this.loadPage(this.currentPage);
    },
    displayClientBillingModal(ids) {
      this.encodedId = ids.encodedId;
      this.legalAdId = ids.id;
      this.$modal.show('client-billing-modal');
    }
  },
};
</script>

<style lang="scss" scoped>
.table {
  @apply w-full;
  thead {
    th {
      @apply bg-primary text-white py-3 px-3;
    }
  }
  & > tbody {
    td {
      @apply border-b border-gray-500 py-3 px-3;
    }
  }
}
</style>
