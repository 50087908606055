import { validatePresenceOf, formatLegalActDate } from '@/shared/utils';

export const validate = (leasing, options) => {
  return validatePresenceOf(
    leasing, 
    ['activity', 'address', 'zipCode', 'city', 'date'],
    options
  );  
}

export const buildEndLeasingContent = leasing => {
  var line = `Portant sur un fonds de commerce de ${leasing.activity} exploité`;
  line = `${line} ${leasing.address}, ${leasing.zipCode} ${leasing.city} a pris fin le ${formatLegalActDate(leasing.date)}.`;
  return [line];
}

export const buildLeasingManagementContent = leasing => {
  var lines = [];

  var line = `Le fonds de commerce`;
  if (leasing.activity?.length > 0) line = `${line} ${leasing.activity}`;
  line = `${line} sis et exploité ${leasing.address}, ${leasing.zipCode} ${leasing.city}, sous l'enseigne ${leasing.sign}.`;
  lines.push(line);

  lines.push(`Pour une durée de ${leasing.duration} année(s), à compter du ${formatLegalActDate(leasing.startDate)} pour prendre fin le ${formatLegalActDate(leasing.endDate)}.`);
  
  if (leasing.renewal) lines.push(leasing.renewal);

  return lines;
}
