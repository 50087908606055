import { buildChildContent } from './person';
import { validatePresenceOf } from '@/shared/utils';

export const validate = (modification, options) => {
  return validatePresenceOf(modification, ['newName'], options);
};

export const buildNamingContent = ({ newName }) => {
  return [`Dépose une requête auprès du garde des sceaux à l'effet de substituer à son patronyme celui de : ${newName}.`];
};

export const buildMarriageContent = ({ newMarriageType }) => {
  var lines = [];

  lines.push(`Ont décidé de changer de régime matrimonial et d'adopter pour l'avenir le régime de la ${newMarriageType}.`);
  lines.push(`Les créanciers peuvent s'opposer à la modification dans les trois mois suivant la présente publication en l'étude de l'office notarial où domicile a été élu à cet effet, conformément à l'article 1397 alinéa 3 du Code civil.`);

  return lines;
};

export const buildChildremNamingContent = ({ modification: { newName }, childList }) => {
  var lines = [];

  if (childList.length === 1) {
    lines.push(`Agissant au nom de son enfant mineur :`);
    lines.push(`- ${buildChildContent(childList[0])}`);
    lines.push(`Dépose une requête auprès du garde des sceaux à l'effet de substituer à son patronyme celui de : ${newName}.`);
  } else if (childList.length > 1) {
    lines.push(`Agissant au nom de ses enfants mineurs :`);
    childList.forEach(child => lines.push(`- ${buildChildContent(child)}`));
    lines.push(`Dépose une requête auprès du garde des sceaux à l'effet de substituer à leur patronyme celui de : ${newName}.`);
  }

  return lines;
};