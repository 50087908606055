<template>
  <div class="flex flex-col min-h-screen">
    <t-header />
    <div class="flex flex-col flex-grow">
      <slot />
    </div>
    <t-footer />
  </div>
</template>

<script>
export default {
  name: 'DefaultLayout',
};
</script>
