import * as Company from '@/services/legal-ad-form/components/management-modification/company';
import * as HeadOffice from '@/services/legal-ad-form/components/management-modification/head-office';
import * as Decision from '@/services/legal-ad-form/components/management-modification/decision';
import * as Person from '@/services/legal-ad-form/components/management-modification/person';
import * as Mention from '@/services/legal-ad-form/components/management-modification/mention';
import { trimObject } from '@/shared/utils';

export default {
  id: 'commissaire',
  label: 'Commissaire aux comptes',
  shortLabel: 'Commissaire\naux comptes',
  componentsData: {
    company: { legalForm: '' },
    headOffice: {},
    decision: { type: '', actor: '' },
    replacementMainAuditorList: [],
    nominatedMainAuditorList: [],
    leavingMainAuditorList: [],    
    replacementAlternateAuditorList: [],
    nominatedAlternateAuditorList: [],
    leavingAlternateAuditorList: [], 
    mention: { registry: 'Mention sera portée au RCS de ...(à compléter)' } 
  },
  component: () => import('@/components/legal-ads/forms/models/management-modification/auditor.vue'),   
  validate(modelData) {
    return trimObject({ 
      company: Company.validate(modelData.company),
      headOffice: HeadOffice.validate(modelData.headOffice),    
      decision: Decision.validate(modelData.decision),
      replacementMainAuditorList: Person.validateMany(modelData.replacementMainAuditorList, false, { add: [] }),
      nominatedMainAuditorList: Person.validateMany(modelData.nominatedMainAuditorList, false),
      leavingMainAuditorList: Person.validateMany(modelData.leavingMainAuditorList, false, { add: [] }),
      replacementAlternateAuditorList: Person.validateMany(modelData.replacementAlternateAuditorList, false, { add: [] }),
      nominatedAlternateAuditorList: Person.validateMany(modelData.nominatedAlternateAuditorList, false),
      leavingAlternateAuditorList: Person.validateMany(modelData.leavingAlternateAuditorList, false, { add: [] }),
      mention: Mention.validate(modelData.mention)
    });
  },  
  buildContent(modelData) {
    return [
      Company.buildContent(modelData.company, { withShareCapital: false }),
      HeadOffice.buildContent(modelData.headOffice),     
      Decision.buildAuditorContent(modelData.decision),
      Person.buildManyContent(modelData.replacementMainAuditorList, null, 'titulaire'),      
      Person.buildManyContent(modelData.nominatedMainAuditorList, 'nomination titulaire'),
      Person.buildManyContent(modelData.leavingMainAuditorList, 'titulaire partant'),
      Person.buildManyContent(modelData.replacementAlternateAuditorList, null, 'suppléant'),      
      Person.buildManyContent(modelData.nominatedAlternateAuditorList, 'nomination suppléant'),
      Person.buildManyContent(modelData.leavingAlternateAuditorList, 'suppléant partant'),
      Mention.buildContent(modelData.mention)
    ].flat().join("\n").replace(/undefined/g, '?');
  }
};