<template>
  <div>
    <h2 class="font-bold text-dark">Informations générales</h2>

    <t-alert v-if="!isCurrentAccountAdmin" class="mt-8 mb-8" level="notice">
      <p>
        Selon votre formalité, les tarifs préfectoraux peuvent varier. Veuillez choisir le type d'annonce concerné avant
        de compléter les champs suivants.
      </p>
    </t-alert>

    <div v-if="legalAd" class="grid grid-cols-2 gap-8 mt-4">
      <div class="col-span-1">
        <div class="input required">
          <label for="newspaper-zip-code">Type d'annonce</label>
          <select v-model="adType" name="ad-type" :class="{ 'legal-ad-input-required': !adType }"
            @change="handleModelType()">
            <option value="" selected>Sélectionnez un type d'annonce</option>
            <option v-for="(type, index) in adTypes" :key="`${type.key}-${index}`" :value="type.key">{{ type.value }}
            </option>
          </select>
        </div>
        <t-alert v-if="displayMultipleEditInfo" class="mt-8 mb-8 font-bold border border-black" level="notice">
          <p>
            En cas de modifications multiples merci d'utiliser :
          </p>
          <p>Type d'annonce "Autres" puis détails de l'annonce "Modifications diverses"</p>
        </t-alert>
      </div>

      <div v-if="adType && filteredSubTypes.length > 1" class="col-span-1">
        <div class="input required">
          <label for="sub-type">Détails de l'annonce</label>
          <select v-model="subType" name="sub-type" :class="{ 'legal-ad-input-required': !subType }"
            @change="handleModelType()">
            <option value="" selected>Sélectionnez un sous-type d'annonce</option>
            <option v-for="(type, index) in filteredSubTypes" :key="`${type.key}-${index}`" :value="type.key">{{
              type.value }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { legalAds } from '@/constants';

export default {
  name: 'AdType',
  data() {
    return {
      adTypes: legalAds.adTypes,
      subType: null,
      companyTyped: 'constitution',
      otherTyped: ['transfert-siege', 'capital-social', 'transformation', 'location-gerance', 'additif', 'rectificatif', 'commissaire', 'objet-social', 'denomination-sociale', 'moitie-capital-social']
    };
  },
  computed: {
    ...mapState(['legalAd']),
    content() {
      return this.legalAd.content;
    },
    adType: {
      get() { return this.content.adType; },
      set(value) { this.change('adType', value); }
    },
    companyType: {
      get() { return this.content.companyType; },
      set(value) { this.change('companyType', value); }
    },
    isConstitutionAd() {
      return this.adType === this.companyTyped;
    },
    isOtherTyped() {
      return this.otherTyped.includes(this.adType);
    },
    filteredSubTypes() {
      const foundType = this.adTypes.find(type => type.key === this.adType);
      return foundType ? foundType.subTypes : null;
    },
    displayMultipleEditInfo() {
      return !this.isConstitutionAd;
    }
  },
  methods: {
    ...mapActions(['changeLegalAd', 'changeLegalAdContent']),
    change(attribute, value) {
      this.changeLegalAdContent({ changes: { [attribute]: value } });
    },
    setCompanyType() {
      if (this.content.adType && this.isConstitutionAd) {
        this.change('companyType', this.subType);
      } else {
        this.change('companyType', null);
        this.changeLegalAd({ changes: { model: { type: null } } });
      }
    },
    setModelType() {
      if (this.content.adType && this.isConstitutionAd && this.content.companyType) {
        this.changeLegalAd({ changes: { model: { type: this.content.companyType } } });
      } else {
        const type = this.isOtherTyped ? this.adType : this.subType;
        this.changeLegalAd({ changes: { model: { type: type } } });
      }
    },
    handleModelType() {
      this.setCompanyType();
      this.setModelType();
    }
  }
};
</script>
