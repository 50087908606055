export const SIGN_IN = 'signIn';
export const SIGN_OUT = 'signOut';
export const SET_NEWSPAPERS = 'setNewspapers';
export const SET_PAGE_CONTENT = 'setPageContent';
export const UPDATE_MY_ACCOUNT = 'updateMyAccount';
export const START_EDITING_LEGAL_AD = 'startEditingLegalAd';
export const STOP_EDITING_LEGAL_AD = 'stopEditingLegalAd';
export const CLEAR_LEGAL_AD_MODIFICATIONS_STATUS = 'clearLegalAdModificationsStatus';
export const CHANGE_LEGAL_AD = 'changeLegalAd';
export const CHANGE_LEGAL_AD_CONTENT = 'changeLegalAdContent';
export const CHANGE_LEGAL_AD_BILLING_ADDRESS = 'changeLegalAdBillingAddress';
export const CHANGE_LEGAL_AD_MODEL = 'changeLegalAdModel';
export const ADD_LEGAL_AD_MODEL_NESTED_OBJECT = 'addLegalAdModelNestObject';
export const REMOVE_LEGAL_AD_MODEL_NESTED_OBJECT = 'removeLegalAdModelNestObject';
export const UPDATE_LEGAL_AD_MODEL_CONTENT = 'updateLegalAdModelContent';
export const VALIDATE_LEGAL_AD_MODEL = 'validateLegalAdModel';
export const SET_LEGAL_ADS = 'setLegalAds';
export const SET_LEGAL_AD_DRAFTS = 'setLegalAdDrafts';
export const SET_LOADING = 'setloading';
export const SET_TOUCHED = 'setTouched';
