import axios from 'axios';

const baseUrl = 'https://geo.api.gouv.fr';
const endpoint = 'communes';

export const find = async (zipCode) => {
  try {
    const response = await axios.get(`${baseUrl}/${endpoint}?codePostal=${zipCode}&fields=nom&format=json&geometry=centre`);
    return response.data;
  } catch (error) {
    return console.error(error);
  }
};
