import api from '@/shared/api';

export const getBillingInformation = (legalAdId, legalAd) => {
  var attributes = { 
    id: legalAdId, newspaperId: legalAd.newspaperId, format: legalAd.format,
    content: { ...legalAd.content }, model: { type: legalAd.model?.type },
    base64Logo: legalAd.content.logo?.data 
  };
  delete(attributes.content.logo);
  return api.post('/estimations', { legalAd: attributes }).then(({ data }) => data); 
}

export const create = legalAd => {
  var attributes = { ...legalAd, content: { ...legalAd.content }, base64Logo: legalAd.content.logo?.data }
  delete(attributes.content.logo);
  if (attributes.publishingAt) {
    var date = attributes.publishingAt;
    attributes.publishingAt = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
  }
  return api.post('/orders', { legalAd: attributes, draftId: legalAd.id }).then(({ data }) => data); 
};

export const update = (paymentIntentId, addressAttributes = {}) => {
  return api.put(`/orders/${paymentIntentId}`, { paymentIntentId, addressAttributes }).then(({ data }) => data); 
};
