import { validatePresenceOf, formatLegalActDate } from '@/shared/utils';

export const validate = (company, options) => {
  return validatePresenceOf(
    company, 
    ['name', 'legalForm', 'socialObject', 'duration', 'shareCapital'], 
    options
  );  
}

export const buildContent = (company, options) => {
  var lines = [];
  if (company) {
    if (company.name) lines.push(`Dénomination : ${company?.name}.`);
    if (company.acronym) lines.push(`Sigle : ${company.acronym}.`);
    if (company.legalForm && !options?.withoutLegalForm) lines.push(`Forme : ${company.legalForm}.`);
    if (company.socialObject) lines.push(`Objet : ${company.socialObject}.`);
    if (company.duration) lines.push(`Durée de la société : ${company.duration} ans.`);
    if (company.shareCapital) {
      var line = null;
      if (company.variableShareCapital) 
        line = `Capital social variable : ${company.shareCapital} euros`;
      else
        line = `Capital social fixe : ${company.shareCapital} euros`;

      lines.push(`${line}.`);
    }
    if (company.cashContributionsAmount) lines.push(`Montant des apports en numéraire : ${company.cashContributionsAmount} euros.`);
    if (company.contributionInKind && company.contributionInKindAmount) {
      lines.push(`Apports en nature : ${company.contributionInKind} d'un montant de ${company.contributionInKindAmount} euros.`);
    }
    if (company.otherContributionInKind && company.otherContributionInKindAmount) {
      lines.push(`Apports en nature : ${company.otherContributionInKind} d'un montant de ${company.otherContributionInKindAmount} euros.`);
    }
    if (company.sharesTransferClause)
      lines.push(`Cession de parts et agrément : ${company.sharesTransferClause}`);
    if (company.departmentalCommitteeAgreementDate)    
      lines.push(`Agréé le : ${formatLegalActDate(company.departmentalCommitteeAgreementDate)}.`);    
    if (company.agreementNumber)
      lines.push(`Sous le numéro : ${company.agreementNumber}.`);  
  }

  return lines;
}