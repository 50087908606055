import * as Publishing from '@/services/legal-ad-form/components/amending/publishing';
import * as Company from '@/services/legal-ad-form/components/amending/company';
import { trimObject } from '@/shared/utils';

export default {
  id: 'rectificatif',
  label: 'Rectificatif',
  shortLabel: 'Rectificatif',
  componentsData: {
    publishing: { text: `Il y a lieu de lire ..., et non pas : ...` },
    company: {},    
  },
  component: () => import('@/components/legal-ads/forms/models/amending/corrective.vue'),   
  validate(modelData) {
    return trimObject({ 
      publishing: Publishing.validate(modelData.publishing),
      company: Company.validate(modelData.company),      
    });
  },  
  buildContent(modelData) {
    return [
      Publishing.buildContent(modelData, 'RECTIFICATIF'),          
    ].flat().join("\n").replace(/undefined/g, '?');
  }
};