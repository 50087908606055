<template>
  <Layout>
    <div class="container py-8 mx-auto">
      <div class="flex">
        <div class="flex-1 flex-shrink-0">
          <t-tabs
            ref="tabs"
            :tabs="tabs"
            :other-props="{ modelForm }"
            @model-selected="onModelSelected"
            @tab-changed="onTabChanged"
            @next="goToPaymentTab"
          />
        </div>
        <div v-if="legalAd" class="pt-24 ml-8 w-96">
          <PreviewToggler :mode="previewMode" @change="setPreviewMode" />
          <component
            :is="previewComponent"
            :legal-ad="legalAd"
          ></component>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import * as MyAccountService from '@/services/my-account';
import { buildContentEmptyAd } from '@/shared/pdf/samples';
import Layout from '@/views/layouts/default';
import ModelPicker from '@/components/legal-ads/forms/models/picker';
import NewModelLegalForm from '@/components/legal-ads/new/model';
import PaymentForm from '@/components/payment-form';
import { PreviewToggler } from '@/components/previews';
import PreviewMixin from '@/mixins/preview';

export default {
  name: 'NewModelLegalAdPage',
  components: { Layout, PreviewToggler },
  mixins: [PreviewMixin],
  data() {
    return { modelForm: null, isValid: false };
  },
  computed: {
    ...mapState(['legalAd', 'legalAdErrors']),
    tabs() {
      return [
        { name: this.tabNames[0], tab: ModelPicker, type: 'model-picker' },
        { name: this.tabNames[1], tab: NewModelLegalForm, disabled: this.isBlank(this.legalAd), type: 'legal-ad' },
        { name: this.tabNames[2], tab: PaymentForm, disabled: !this.isValid, type: 'payment' },
      ];
    },
    tabNames() {
      return [
        '1 - Nos catégories d\'annonce',
        '2 - Votre annonce',
        this.isCurrentAccountPro ? '3 - Attestation' : '3 - Paiement attestation',
      ];
    }
  },
  mounted() {
    this.stopEditingLegalAd();
  },
  methods: {
    ...mapActions(['startEditingLegalAd', 'stopEditingLegalAd']),
    async onModelSelected(modelForm) {
      const { account: { companyLogoUrl, defaultLegalAdContent } } = await MyAccountService.show().catch(() => { return { account: {} }; });
      const content = await buildContentEmptyAd(companyLogoUrl, modelForm.shortLabel, defaultLegalAdContent, this.isCurrentAccountPro);

      if (modelForm.id.includes('transfert')) {
        modelForm.id = 'transfert-siege';
      }

      this.startEditingLegalAd({
        name: modelForm.label,
        content,
        model: {
          type: modelForm.id,
          content: '',
          ...modelForm.componentsData
        },
      });

      this.modelForm = modelForm;

      this.$nextTick(() => this.$refs.tabs.selectTab(1));
    },
    onTabChanged(index) {
      if (index === 1)
        this.isValid = false;
    },
    goToPaymentTab() {
      this.isValid = true;
      this.$nextTick(() => this.$refs.tabs.selectTab(2));
    }
  }
};
</script>
