<template>
  <FormWrapper class="mt-8 mx-8" :with-publishing-info="false" :edit-mode="true">
    <template v-slot:title>
      <h1 class="text-red-600 text-xl font-bold mb-8">
        Modification Annonce légale
      </h1>
    </template>

    <DefaultForm class="mt-8" />
  </FormWrapper>
</template>

<script>
import FormWrapper from '@/components/legal-ads/forms/wrapper';
import DefaultForm from '@/components/legal-ads/forms/default';

export default {
  name: 'EditLegalAd',
  components: { FormWrapper, DefaultForm }
};
</script>
