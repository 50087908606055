<template>
  <form-wrapper :show-ad-type="false" :is-model="isModel" class="mt-8 mx-8">
    <template v-slot:title>
      <h1 class="text-primary text-xl font-bold mb-8">
        Ma nouvelle annonce légale
      </h1>
    </template>

    <template v-slot:error>
      <t-alert
        class="my-8"
        level="danger"
        v-if="showErrorMessage"
        ref="errorMessage"
      >
        <p>
          Vous devez remplir <u>obligatoirement</u> toutes les
          <strong>informations de publication</strong> ci-dessous.
        </p>
      </t-alert>
    </template>

    <component v-bind:is="safeModelForm.component" v-if="legalAd" />

    <template v-slot:buttons>
      <t-submit-button
        type="button"
        :secondary="true"
        :buttonState="draftButtonState"
        :labels="{
          default: 'Créer devis',
          inProgress: 'Création du devis',
          success: 'Devis crée !',
          fail: 'Échec',
        }"
        @click="createDraft"
        v-if="isCurrentAccountPro && !isDraft"
      />
      <t-submit-button
        type="button"
        :secondary="true"
        :buttonState="draftButtonState"
        :labels="{
          default: 'Mettre à jour le devis',
          inProgress: 'Mise à jour',
          success: 'Mise à jour effectuée !',
          fail: 'Échec',
        }"
        @click="updateDraft"
        v-if="isCurrentAccountPro && isDraft"
      />
      <button
        type="button"
        class="        
          px-12
          py-3
          hover:bg-opacity-90
          transition-all
          bg-primary
          text-white
          ml-6
        "
        @click="goNext"
      >
        Valider
      </button>
    </template>
  </form-wrapper>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as LegalAdFormService from "@/services/legal-ad-form";
import FormWrapper from "@/components/legal-ads/forms/wrapper";
import { PreviewToggler } from "@/components/previews";
import SafeEditingMixin from "@/mixins/safe-editing";
import PersistingDraftMixin from "@/mixins/persisting-draft";
import { isEmpty } from 'lodash';

export default {
  name: "ModelNewLegalAd",
  components: { FormWrapper, PreviewToggler },
  mixins: [SafeEditingMixin, PersistingDraftMixin],
  props: {
    modelForm: { type: Object }
  },
  computed: {
    ...mapState(["legalAd", "legalAdErrors"]),
    safeModelForm() {
      return this.modelForm || LegalAdFormService.find(this.legalAd.model.type);
    },
    isModel() {
      return this.legalAd?.model && !isEmpty(this.legalAd.model)
    }
  },
  methods: {
    ...mapActions([
      "startEditingLegalAd",
      "stopEditingLegalAd",
      "validateLegalAdModel",
      "clearLegalAdModificationsStatus",
    ]),
    goNext() {
      this.onlyIfPublishingInfoValid(() => {
        this.validateLegalAdModel();
        if (!this.legalAdErrors) {
          this.$emit("next");
        }
      });
    },
  },
};
</script>
