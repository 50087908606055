import * as LegalAdService from '@/services/legal-ad'
import * as LegalAdDraftService from '@/services/legal-ad-draft'
import store from '@/store';

export default {
  data() {
    return { draftButtonState: 'default', showErrorMessage: false }
  },
  computed: {
    isDraft() {
      return !this.isBlank(this.legalAd.id)
    }
  },
  methods: {
    isPublishingInfoValid() {
      const isPro = store.state.account?.pro === true;
      return LegalAdService.isValid(this.legalAd, isPro)
    },
    onlyIfPublishingInfoValid(fn) {
      this.showErrorMessage = false
      if (!this.isPublishingInfoValid()) {
        this.showErrorMessage = true
        this.$nextTick(() => this.$refs.errorMessage.$el.scrollIntoView({ behavior: 'smooth' }))
        return
      }      
      fn()
    },
    createDraft() { 
      this.onlyIfPublishingInfoValid(() => {
        this.draftButtonState = 'inProgress'
        LegalAdDraftService.create(this.legalAd)
        .then(({ id }) => {
          this.stopEditingLegalAd()
          if (id)
            this.$router.push({ name: 'myLegalAdDrafts'});  
            this.setTouched(false);
        })     
        .catch(() => this.draftButtonState = 'fail') 
      })        
    },
    updateDraft() {
      this.onlyIfPublishingInfoValid(() => {
        this.draftButtonState = 'inProgress'    
        LegalAdDraftService.update(this.legalAd.id, this.legalAd)
        .then(() => {
          this.draftButtonState = 'success';
          this.clearLegalAdModificationsStatus()     
        })     
        .catch(() => this.draftButtonState = 'fail')
      })
    },    
  },
}
