import * as Act from '@/services/legal-ad-form/components/selling/act';
import * as SIE from '@/services/legal-ad-form/components/selling/sie';
import * as Person from '@/services/legal-ad-form/components/selling/person';
import * as Cession from '@/services/legal-ad-form/components/selling/cession';
import { trimObject } from '@/shared/utils';

export default {
  id: 'cession-fond-commerce',
  label: 'Cession de fonds de commerce',
  shortLabel: 'Cession de fonds\nde commerce',
  componentsData: {
    act: { type: '' },
    sie: { fileType: 'Bordereau', referenceType: 'Référence' },
    transferor: { type: '', civility: '', legalForm: '' },
    transferee: { type: '', civility: '', legalForm: '' },
    cession: { objections: `Les oppositions, s'il y a lieu, seront reçues dans les dix jours de la dernière en date des publications légales, pour la validité au ... et pour la correspondance au ...` },    
  },
  component: () => import('@/components/legal-ads/forms/models/selling/business.vue'),   
  validate(modelData) {
    return trimObject({ 
      act: Act.validate(modelData.act),
      sie: SIE.validate(modelData.sie, modelData.act.type),
      transferor: Person.validate(modelData.transferor, { skip: ['shareCapital'] }),   
      transferee: Person.validate(modelData.transferee, { skip: ['shareCapital'] }),
      cession: Cession.validate(modelData.cession),      
    });
  },  
  buildContent(modelData) {
    return [
      Act.buildContent(modelData),
      Person.buildSimpleContent(modelData.transferor),
      Person.buildContent(modelData.transferee, 'A cédé à'),
      Cession.buildContent(modelData),           
    ].flat().join("\n").replace(/undefined/g, '?');
  }
};