import api from '@/shared/api';

export const fetchLegalAd = async (encodedLegalAdId) => {
  try {
    const response = await api.get(`/legal_ads/${encodedLegalAdId}/client_payments`);
    return response;
  } catch (error) {
    return error?.response?.status;
  }
}

export const sendPaymentLink = async (clientEmail, encodedLegalAdId) => {
  try {
    const response = await api.post(`/legal_ads/${encodedLegalAdId}/client_payment_links`, { client_email: clientEmail });
    return response?.status;
  } catch (error) {
    return console.error(error);
  }
}
