<template>
  <div class="w-1/2 p-4 mx-auto rounded bg-primary bg-opacity-10">
    <h2 class="font-bold">Commande</h2>

    <hr class="my-4">

    <table class="w-full">
      <tbody>
        <tr>
          <th class="font-normal text-left text-gray-600">Origine</th>
          <td class="text-right">{{ info.fullName }}</td>
        </tr>
        <tr>
          <th class="font-normal text-left text-gray-600">Référence</th>
          <td class="text-right">{{ info.reference }}</td>
        </tr>
        <tr>
          <th class="font-normal text-left text-gray-600">Référence interne</th>
          <td class="text-right">{{ info.altName }}</td>
        </tr>
        <tr>
          <th class="font-normal text-left text-gray-600">Email</th>
          <td class="text-right">{{ info.email }}</td>
        </tr>
        <hr class="my-4">
        <tr>
          <th class="font-normal text-left text-gray-600">Journal</th>
          <td class="text-right">{{ info.newspaperTitle }}</td>
        </tr>
        <tr>
          <th class="font-normal text-left text-gray-600">Format</th>
          <td class="text-right">{{ legalAdFormat }}</td>
        </tr>
        <tr>
          <th class="font-normal text-left text-gray-600">Date de parution</th>
          <td class="text-right">{{ legalAdPublishingDate | formatDate }}</td>
        </tr>
      </tbody>
    </table>

    <hr class="my-4">

    <div>
      <div class="flex items-center mb-2">
        <h4 class="text-gray-600">Montant HT</h4>
        <div class="ml-auto">{{ info.amount }} €</div>
      </div>

      <div class="flex items-center mb-2">
        <h4 class="text-gray-600">Montant TVA <small>(20%)</small></h4>
        <div class="ml-auto">{{ info.taxAmount }} €</div>
      </div>

      <div class="flex items-center text-lg">
        <h4>Total TTC</h4>
        <div class="ml-auto font-bold">{{ info.totalAmount }} €</div>
      </div>
    </div>
  </div>
</template>

<script>
import { isBefore } from 'date-fns';
import { nextOpenDay } from '@/shared/utils';

export default {
  name: 'Info',
  props: {
    info: {
      type: Object,
      default: null,
      required: true,
    }
  },
  computed: {
    legalAdFormat() {
      return { web: 'Site internet', print: 'Journal papier' }[this.info.legalAdFormat];
    },
    legalAdPublishingDate() {
      const result = isBefore(new Date(this.info.publishingAt), new Date()) ? nextOpenDay() : this.info.publishingAt;

      return result;
    }
  }
};
</script>
