<template>
  <div class="py-6 text-white bg-gray-900">
    <div class="container px-4 mx-auto md:px-0">
      <div class="grid gap-4 grid-col-1 md:grid-cols-5 md:gap-10">
        <div class="md:col-span-2">
          <router-link :to="{ name: 'home' }">
            <img src="@/assets/logo.svg" alt="Logo Journal Toulousain" class="w-40" />
          </router-link>
          <p class="mt-8 text-xs" v-html="pageContent('footer.companyInfo')"></p>
        </div>
        <div>
          <h2 class="font-semibold">La société</h2>
          <ul class="mt-2 md:mt-8">
            <li class="my-3">
              <a class="hover:underline" href="tel:+768045293" target="_blank">
                <font-awesome-icon icon="phone"></font-awesome-icon>
                07 68 04 52 93
              </a>
            </li>
            <li class="my-3">
              <a
                class="hover:underline"
                href="https://formalites-annonceslegales.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Qui sommes-nous ?
              </a>
            </li>
            <li class="my-3">
              <a class="hover:underline" href="https://formalites-annonceslegales.com/contactez-nous/" target="_blank">Nous contacter</a>
            </li>
          </ul>
        </div>
        <div>
          <h2 class="font-semibold">Juridique</h2>
          <ul class="mt-2 md:mt-8">
            <li class="my-3">
              <a
                class="hover:underline"
                href="https://formalites-annonceslegales.com/mentions-legales/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Mentions légales
              </a>
            </li>
            <li class="my-3">
              <a
                class="hover:underline"
                href=" https://formalites-annonceslegales.com/politique-de-confidentialite/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Politique de confidentialité
              </a>
            </li>
            <li class="my-3">
              <a
                class="hover:underline"
                href="https://formalites-annonceslegales.com/conditions-generales-vente/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Conditions générales de vente
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h2 class="font-semibold">Réseaux sociaux</h2>
          <div class="mt-2 text-lg md:mt-8 ">
            <a class="mr-8" href="#" target="_blank" rel="noreferrer" title="Lien vers le compte Facebook">
              <font-awesome-icon :icon="['fab', 'facebook-f']" />
            </a>
            <a class="mr-8" href="#" target="_blank" rel="noreferrer" title="Lien vers le compte Instagram">
              <font-awesome-icon :icon="['fab', 'instagram']" />
            </a>
            <a class="mr-8" href="#" target="_blank" rel="noreferrer" title="Lien vers le compte Youtube">
              <font-awesome-icon :icon="['fab', 'youtube']" />
            </a>
            <a href="#" target="_blank" rel="noreferrer" title="Lien vers le compte Twitter">
              <font-awesome-icon :icon="['fab', 'twitter']" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KitFooter'
};
</script>
