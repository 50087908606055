import { validatePresenceOf } from '@/shared/utils';

export const validate = company => {
  return validatePresenceOf(company, ['name', 'legalForm', 'shareCapital']);
};

export const buildContent = (company, { withShareCapital }) => {
  var lines = [];
  if (company.name) lines.push(`Dénomination : ${company.name}.`);
  if (company.acronym) lines.push(`Sigle : ${company.acronym}.`);
  if (company.legalForm) {
    if (withShareCapital)
      lines.push(`Forme : ${company.legalForm} au capital de ${company.shareCapital} euros.`);
    else
    lines.push(`Forme : ${company.legalForm}.`);
  }
  return lines;
};
