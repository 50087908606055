<template>
  <div class="grid grid-cols-3 gap-8">
    <div class="col-span-1">
      <div class="counter">
        <h2>
          <span v-if="counters">{{ counters.totalNumber }}</span>
          <span v-else>&mdash;</span>
        </h2>
        <h3>Commande(s)</h3>
        <p>Nombre d'annonces légales commandées sur la plateforme depuis votre inscription.</p>
      </div>
    </div>

    <div class="col-span-1">
      <div class="counter">
        <h2>
          <span v-if="counters">{{ counters.unpaidNumber }}</span>
          <span v-else>&mdash;</span>
        </h2>
        <h3>Commande(s) non réglée(s)</h3>
        <p>Nombre d'annonces légales dont nous n'avons pas encore reçu le paiement.</p>
      </div>      
    </div>

    <div class="col-span-1">
      <div class="counter">
        <h2>
          <span v-if="counters">{{ unpaidAmount }}</span>
          <span v-else>&mdash;</span>
        </h2>
        <h3>Encours</h3>
        <p>Montant restant à payer pour les annonces dont nous n'avons pas reçu le paiement.</p>
      </div>    
    </div>
  </div>
</template>

<script>
import * as MyAccountService from '@/services/my-account';

export default {
  name: 'MyLegalAdCounters',
  data() {
    return {
      counters: undefined,
    }
  },
  async mounted() {
    this.counters = await MyAccountService.getStatistics();
  },
  computed: {
    unpaidAmount() {
      return this.numberToCurrency(this.counters?.unpaidAmount);
    },
  }
}
</script>
  
<style lang="scss" scoped>

  .counter {
    @apply text-center;

    h2 {
      @apply text-4xl font-extrabold text-primary;
    }

    h3 {
      @apply mt-3 text-lg text-black font-bold;
    }

    p {
      @apply text-gray-600;
    }
  }

</style>