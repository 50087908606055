import { validatePresenceOf, formatLegalActDate } from '@/shared/utils';

export const validate = decision => {
  return validatePresenceOf(decision, ['type', 'date', 'actor']);
};

export const buildHalfShareCapitalContent = decision => {
  var lines = [];

  lines.push(`Aux termes ${decision.type} en date du ${formatLegalActDate(decision.date)}, <strong>statuant en application de l'article L. 223 42 du Code de commerce</strong>, ${decision.actor ? decision.actor : 'a été décidé,'} malgré la perte de plus de la moitié du capital social, qu'il n'y avait pas lieu de prononcer la dissolution de la société.`);

  return lines;
};
