<template>
  <form v-if="myAccount" v-on:submit.prevent @submit="persist">          
    <t-alert v-if="errorMessage">
      {{ errorMessage }}
    </t-alert>

    <h2 class="font-bold mb-4">Informations générales</h2>
    
    <div class="grid grid-cols-2 gap-8 mb-12">
      <div class="col-span-1">
        <div class="input required">
          <label for="account-fullname">Nom complet</label>
          <input type="text" id="account-fullname" required placeholder="Entrez votre prénom et nom" v-model="myAccount.fullName" />
          <div class="error" v-if="errors.fullName">{{ errors.fullName }}</div>
        </div>
      </div>
      <div class="col-span-1">
        <div class="input required">
          <label for="account-email">Adresse Email</label>
          <input type="email" id="account-email" required placeholder="Entrez votre adresse email" v-model="myAccount.email" />
          <div class="error" v-if="errors.email">{{ errors.email }}</div>
          <div class="flex items-center mt-3">
            <input  type="checkbox" id="email-notifications"  value="1"  v-model="myAccount.emailNotifications" />
            <label class="ml-2" for="email-notifications">
              Recevoir les notifications quand une annonce a été créée ?
            </label>
          </div>
        </div>
      </div>
      <div class="col-span-1">
        <div class="input">
          <label for="account-phone-number">Numéro de téléphone</label>
          <input type="text" id="account-phone-number" placeholder="Entrez votre numéro de téléphone" v-model="myAccount.phoneNumber" />
          <div class="error" v-if="errors.phoneNumber">{{ errors.phoneNumber }}</div>
        </div>
      </div>
      <div class="col-span-1">
        <div class="input">
          <label for="account-password">Nouveau mot de passe</label>
          <input type="password" id="account-password" placeholder="Entrez votre mot de passe" v-model="myAccount.password" />
          <div class="error" v-if="errors.password">{{ errors.password }}</div>
        </div>
      </div>
      <div class="col-span-1">
        <div class="input">
          <label for="account-password-confirmation">Confirmation Nouveau mot de passe</label>
          <input type="password" id="account-password-confirmation" placeholder="Entrez la confirmation du mot de passe" v-model="myAccount.passwordConfirmation" />
          <div class="error" v-if="errors.passwordConfirmation">{{ errors.passwordConfirmation }}</div>
        </div>
      </div>
    </div>

    <h2 class="font-bold mb-4">Informations société</h2>
    
    <div class="grid grid-cols-2 gap-8">
      <div class="col-span-1">
        <div class="input">
          <label for="account-company">Nom de la société</label>
          <input type="text" id="account-company" placeholder="Entrez le nom de votre société" v-model="myAccount.companyName" />
          <div class="error" v-if="errors.companyName">{{ errors.companyName }}</div>
          <div class="hint">Cette information sera reprise lors de la facturation.</div>
        </div>
      </div>
      <div class="col-span-1">
        <div class="input">
          <label for="account-company-registration-number">Numéro de SIRET</label>
          <input type="text" id="account-company-registration-number" placeholder="Entrez le numéro de SIRET de votre société" v-model="myAccount.companyRegistrationNumber" />
          <div class="error" v-if="errors.companyRegistrationNumber">{{ errors.companyRegistrationNumber }}</div>
          <div class="hint">Cette information sera reprise lors de la facturation.</div>
        </div>
      </div>
    </div>

    <div class="mt-12 text-right">
      <t-submit-button
        :buttonState="buttonState"
        :labels="{ default: 'Enregistrer', inProgress: 'En cours...', success: 'Profil modifié !', fail: 'Échec' }"
      />  
    </div>      
  </form>      
</template>

<script>
import * as MyAccountService from '@/services/my-account';
import { toCamel } from '@/shared/utils';

export default {
  name: 'EditMyaccount',
  data() {
    return {
      myAccount: undefined,
      errorMessage: false,
      errors: {},
      buttonState: 'default'
    }
  },
  mounted() {
    MyAccountService.show().then(({ account }) => this.myAccount = account);
  },
  methods: {
    persist() {
      this.resetForm(false);
      MyAccountService.update({ ...this.myAccount })
      .then(account => this.onSuccess(account))
      .catch(exception => this.onError(exception.response?.data?.errors))
    },
    onSuccess(account) {
      this.buttonState = 'success';
      this.myAccount = account;
    },
    onError(errors) {
      this.buttonState = 'fail';
      if (errors) {
        this.errorMessage = "Le formulaire n'est pas valide";
        for (var key in errors || {})
        this.errors[toCamel(key)] = errors[key][0]; 
      } else {
        this.errorMessage = "Veuillez contacter notre support technique";
      }                 
    },
    resetForm(brandNew) {
      this.buttonState = brandNew ? 'default' : 'inProgress';
      this.errorMessage = null;
      this.errors = {};
    }
  }
}
</script>
