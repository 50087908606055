<template>
  <div>
    <h1 class="mb-8 text-xl font-bold text-primary">
      Finalisation sans paiement
    </h1>

    <t-alert>
      <p>Vous bénéficiez d'un compte professionnel donc vous n'avez pas de règlement à effectuer de suite. Nous vous adresserons vos pièces comptables après parution.</p>
    </t-alert>

    <div class="mt-8 text-right">
      <t-submit-button
        type="button"
        :buttonState="buttonState"
        :labels="{ default: 'Confirmer', inProgress: 'Confirmation en cours', success: 'Commande confirmée !', fail: 'Échec' }"
        @click="confirm"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import * as OrderService from '@/services/order'

export default {
  name: 'ProPaymentForm',
  data() {
    return { buttonState: 'default', order: null }
  },
  watch: {
    order(order) {
      this.$emit('send-payment-link', order.legalAdPaymentLink)
    }
  },
  async mounted() {
    this.order = await OrderService.create(this.legalAd);
  },
  computed: {
    ...mapState(['legalAd']),
    isReady() {
      return (this.buttonState === 'default' || this.buttonState === 'fail') && this.order;
    },
    legalAdId() {
      return this.legalAd.id ? this.legalAd.id.toString() : null
    }
  },
  methods: {
    ...mapActions(['stopEditingLegalAd', 'setTouched']),
    confirm() {
      if (!this.isReady) return false;
      this.buttonState = 'inProgress';

      OrderService.update(this.order.id, this.legalAd?.addressAttributes).then(() => {
        setTimeout(() => {
          this.stopEditingLegalAd()
          this.$router.push({ name: 'myLegalAds' });
          this.setTouched(false); 
        }, 2000) // a websocket based solution here would have been better
      })
      .catch(error => {
        console.log('ERROR during processing the PRO legal ad', error)
        this.buttonState = 'fail'
      });
    }
  }
}
</script>
