<template>
  <div>
    <h1 class="flex pb-4 text-lg font-extrabold border-b border-yellow-300 text-primary">
      <span v-if="referrer === 'sign-up'" class="mr-2">Je suis un professionnel du chiffre ou du droit,</span><span>{{ title }}</span>
    </h1> 

    <t-alert class="mt-8" v-if="errorMessage">
      {{ errorMessage }}
    </t-alert>

    <form @submit.prevent="signUp" class="mt-12">
      <div class="grid grid-cols-2 gap-8">
        <div class="col-span-1">
          <div class="input required">
            <label for="account-fullname">Nom complet</label>
            <input type="text" id="account-fullname" required placeholder="Entrez votre prénom et nom" v-model="account.fullName" />
            <div class="error" v-if="errors.fullName">{{ errors.fullName }}</div>
          </div>
        </div>
        <div class="col-span-1">
          <RcsInput :show-input="referrer === 'sign-up'" @pappers-data="fillForm" />

          <div class="input required">
            <label for="account-company">Nom de la société</label>
            <input type="text" id="account-company" required placeholder="Entrez le nom de votre société" v-model="account.companyName" />
            <div class="error" v-if="errors.companyName">{{ errors.companyName }}</div>
            <div class="hint">Cette information sera reprise lors de la facturation.</div>
          </div>
        </div>
        <div class="col-span-1">
          <div class="input required">
            <label for="account-email">Adresse Email</label>
            <input type="email" id="account-email" required placeholder="Entrez votre adresse email" v-model="account.email" />
            <div class="error" v-if="errors.email">{{ errors.email }}</div>
          </div>
        </div>
        <div class="col-span-1">
          <div class="input required">
            <label for="account-phone-number">Numéro de téléphone</label>
            <input type="text" id="account-phone-number" required placeholder="Entrez votre numéro de téléphone" v-on:keypress="checkInput" v-model="account.phoneNumber" />
            <div v-if="phoneNumbersOnly" class="text-sm text-red-500">Chiffres uniquement</div>
            <div class="error" v-if="errors.phoneNumber">{{ errors.phoneNumber }}</div>
          </div>
        </div>
        <div class="col-span-1">
          <div class="input required">
            <label for="account-password">Mot de passe</label>
            <input type="password" id="account-password" required placeholder="Entrez votre mot de passe" v-model="account.password" />
            <div class="error" v-if="errors.password">{{ errors.password }}</div>
          </div>
        </div>
        <div class="col-span-1">
          <div class="input required">
            <label for="account-password-confirmation">Confirmation Mot de passe</label>
            <input type="password" id="account-password-confirmation" required placeholder="Entrez la confirmation du mot de passe" v-model="account.passwordConfirmation" />
            <div class="error" v-if="errors.passwordConfirmation">{{ errors.passwordConfirmation }}</div>
          </div>
        </div>
      </div>

      <div class="mt-12 text-right">
        <t-submit-button
          :buttonState="buttonState"
          :labels="buttonLabels"
        />
      </div>
    </form>
  </div>
</template>

<script>
import RcsInput from '@/components/shared/rcs-input.vue';
import * as AuthService from '@/services/authentication'
import { CustomModal } from '@/components/shared/modals';
import { toCamel } from '@/shared/utils'
import { numbersOnly } from '@/helpers';

export default {
  name: 'AuthSignUp',
  components: { RcsInput },
  props: {
    title: { type: String, required: false, default: 'je crée un compte pro' },
    buttonLabels: {
      type: Object,
      required: false,
      default: () => ({
        default: "S'inscrire",
        inProgress: "En cours...",
        success: "Compte crée !",
        fail: "Échec",
      }),
    },
    referrer: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      errorMessage: false,
      errors: {},
      account: {
        fullName: null,
        companyName: null,
        companyRegistrationNumber: null,
        email: null,
        phoneNumber: null,
        password: null,
        passwordConfirmation: null
      },
      buttonState: 'default',
      phoneNumbersOnly: false
    }    
  },
  methods: {
    signUp() {
      const isProfessional = this.referrer === 'sign-up';
      event.preventDefault();
      this.resetForm(false);
      AuthService.signUp(this.account, isProfessional)
      .then(account => this.onSuccess(account))
      .then(() => this.showModal())
      .catch(exception => this.onError(exception.response?.data?.errors))
      .then(() => this.buttonState = 'default')
    },
    onSuccess() {
      this.$emit('on-success')
    },
    onError(errors) {
      this.buttonState = 'fail';
      if (errors) {
        this.errorMessage = "Le formulaire n'est pas valide";
        for (var key in errors || {})
        this.errors[toCamel(key)] = errors[key][0]; 
      } else {
        this.errorMessage = "Veuillez contacter notre support technique";
      }                 
    },
    resetForm(brandNew) {
      this.buttonState = brandNew ? 'default' : 'inProgress';
      this.errorMessage = null;
      this.errors = {};
      if (brandNew) this.account = {};
    },
    checkInput(event) {
      this.phoneNumbersOnly = numbersOnly(event);
    },
    fillForm(data) {
      if (data) this.account.companyName = data.nomEntreprise;
    },
     showModal() {
      if (this.referrer === 'sign-up') {
        this.$modal.show(CustomModal, {
          title: 'Information',
          paragraphs: [
            'Nous vous remercions de votre demande.',
            'Celle-ci sera validée sous une heure maximum du lundi au vendredi de 08H30 à 19H00.',
            'En cas d\'urgence, vous pouvez nous contacter au 07.62.89.26.21. Après validation, nous vous adresserons à l\'adresse électronique que vous avez renseignée votre code d\'accès temporaire et vous pourrez publier en bénéficiant des avantages que nous offrons aux professionnels du droit et du chiffre. Nous vous remercions de votre confiance.',
            'Le service des Annonces Légales'
          ]
        })
      }
    }
  }
}
</script>
