import * as Company from '@/services/legal-ad-form/components/closing/company';
import * as HeadOffice from '@/services/legal-ad-form/components/closing/head-office';
import * as Decision from '@/services/legal-ad-form/components/closing/decision';
import * as Opposition from '@/services/legal-ad-form/components/closing/opposition';
import { trimObject } from '@/shared/utils';

export default {
  id: 'transmission-patrimoine',
  label: 'Transmission universelle de patrimoine',
  shortLabel: 'Transmission universelle\nde patrimoine',
  componentsData: {
    company: { legalForm: '' },
    headOffice: {},
    decision: { type: '', actor: '' },
    partner: { legalForm: '' },    
    partnerHeadOffice: {},
    opposition: {} 
  },
  component: () => import('@/components/legal-ads/forms/models/closing/transmission.vue'),   
  validate(modelData) {
    return trimObject({ 
      company: Company.validate(modelData.company),
      headOffice: HeadOffice.validate(modelData.headOffice),    
      decision: Decision.validate(modelData.decision, { skip: ['type', 'actor'] }),
      partner: Company.validate(modelData.partner),
      partnerHeadOffice: HeadOffice.validate(modelData.partnerHeadOffice),    
      opposition: Opposition.validate(modelData.opposition)
    });
  },  
  buildContent(modelData) {
    return [
      Company.buildContent(modelData.company, { withShareCapital: true }),
      HeadOffice.buildContent(modelData.headOffice),     
      Decision.buildTransmissionContent(modelData),      
      Opposition.buildContent(modelData)
    ].flat().join("\n").replace(/undefined/g, '?');
  }
};