import NewLegalAdPage from '@/views/legal-ad/new';
import NewRawLegalAdPage from '@/views/legal-ad/new-raw';
import NewModelLegalAdPage from '@/views/legal-ad/new-model';
import EditLegalAdDraftPage from '@/views/legal-ad/edit-draft';
import BillingPage from '@/views/legal-ad/billing';

export default [
  {
    path: '/annonces-legales',
    redirect: '/annonces-legales/nouvelle/pro',
  },
  {
    name: 'newLegalAd',
    path: '/annonces-legales/nouvelle',
    component: NewLegalAdPage
  },
  {
    name: 'newRawLegalAd',
    path: '/annonces-legales/nouvelle/pro',
    component: NewRawLegalAdPage
  },
  {
    name: 'newModelLegalAd',
    path: '/annonces-legales/nouvelle/modele',
    component: NewModelLegalAdPage
  },
  {
    name: 'editLegalAdDraft',
    path: '/annonces-legales/devis/:legalAdId',
    component: EditLegalAdDraftPage
  },
  {
    name: 'billing',
    path: '/annonces-legales/paiement/:legalAdId',
    component: BillingPage
  }
];