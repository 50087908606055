import Vue from 'vue';

import THeader from './header';
import TFooter from './footer';

import TAlert from './alert';
import TTabs from './tabs';

import TSubmitButton from './submit-button';
import TFormInput from './forms/form-input';
import AddressInputs from './forms/address-inputs';


Vue.component('t-header', THeader);
Vue.component('t-footer', TFooter);
Vue.component('t-alert', TAlert);
Vue.component('t-tabs', TTabs);
Vue.component('t-submit-button', TSubmitButton);
Vue.component('t-form-input', TFormInput);
Vue.component('address-inputs', AddressInputs);
