<template>
  <button
    :type="type"
    class="px-4 py-3 transition-all hover:bg-opacity-90"
    :class="{ 
      'bg-primary text-white': !secondary && (isDefaultState || isInProgressState),
      'bg-gray-800 text-white': secondary && (isDefaultState || isInProgressState),
      'bg-success text-white': isSuccessState,
      'bg-yellow-200 text-yellow-600': isFailState,
    }"
    :disabled="disabled || isInProgressState"
    @click="click"
  >
    <span v-if="isDefaultState" data-button-label>{{ defaultLabel }}</span>

    <span
      v-if="isInProgressState"
      class="d-flex align-items-center justify-content-center"
    >
      <font-awesome-icon icon="circle-notch" spin />
      <span class="ml-3" data-button-label>{{ inProgressLabel }}</span>
    </span>

    <span
      v-if="isSuccessState"
      class="d-flex align-items-center justify-content-center"
    >
      <font-awesome-icon icon="check" />
      <span class="ml-3" data-button-label>{{ successLabel }}</span>
    </span>

    <span
      v-if="isFailState"
      class="d-flex align-items-center justify-content-center"
    >
      <font-awesome-icon icon="exclamation" />
      <span class="ml-3" data-button-label>{{ failLabel }}</span>
    </span>
  </button>
</template>

<script>
export default {
  name: 'KitSubmitButton',
  props: {
    type: {
      type: String,
      default: 'submit'
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    labels: {
      type: Object,
      default: undefined,
    },
    buttonState: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      internalButtonState: 'default',
      timeout: undefined,
    };
  },
  computed: {
    isDefaultState() {
      return this.internalButtonState === 'default';
    },
    defaultLabel() {
      return this.labels?.default || 'Submit';
    },
    isInProgressState() {
      return this.internalButtonState === 'inProgress';
    },
    inProgressLabel() {
      return this.labels?.inProgress || 'Loading';
    },
    isSuccessState() {
      return this.internalButtonState === 'success';
    },
    successLabel() {
      return this.labels?.success || 'Success!';
    },
    isFailState() {
      return this.internalButtonState === 'fail';
    },
    failLabel() {
      return this.labels?.fail || 'Failure!';
    },
  },
  methods: {
    click() {
      if (this.type === 'button') 
        this.$emit('click');
    }
  },
  watch: {
    buttonState: {
      immediate: true,
      handler(newValue) {
        this.internalButtonState = newValue;
        if (newValue === 'success' || newValue === 'fail') {
          if (this.timeout) clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.internalButtonState = 'default';
          }, 3000);
        }
      },
    },
  },
};
</script>
