<template>
  <Layout>
    <div class="container mx-auto py-8 px-8 flex flex-col flex-grow">
      <h1 class="text-primary text-xl font-bold">
        Mes annonces légales
      </h1>

      <MyLegalAds class="flex-grow" />
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/default';
import MyLegalAds from '@/components/my-account/legal-ads';

export default {
  name: 'MyLegalAdsPage',
  components: { Layout, MyLegalAds },
};
</script>
