import { validatePresenceOf, formatLegalActDate } from '@/shared/utils';

export const validate = company => {
  return validatePresenceOf(company, ['newspaper', 'date', 'text']);
};

export const buildContent = ({ publishing, company }, title) => {
  var line = `${title} à l'insertion parue sur ${publishing.newspaper} du ${formatLegalActDate(publishing.date)}`;

  line = `${line}, concernant la société ${company.name}, sise ${company.address}, ${company.zipCode} ${company.city}`;
  line = `${line}. ${publishing.text}`;

  return [line];
};