<template>
  <div>
    <slot name="title"></slot>

    <slot name="error" :error="true"></slot>

    <form @submit.stop.prevent="() => {}">
      <ad-type
        v-if="displayAdType"
        class="mb-12"
      />

      <publishing-info-pane class="mb-12" :with-publishing-info="withPublishingInfo" />

      <RcsInput
        :show-input="showRcsInput"
        @pappers-data="fillForm"
      />

      <div v-if="isCurrentAccountPro || isCurrentAccountAdmin">
        <header-pane title="Activer votre en-tête" scope="preBlock1" class="mb-6" :edit-mode="editMode" />
        <header-pane title="Activer l'en-tête du client" scope="preBlock2" class="mb-12" :edit-mode="editMode" :registration-number="true" />
      </div>

      <div v-else>
        <header-pane title="Activer votre en-tête" scope="preBlock2" class="mb-12" :edit-mode="editMode" :registration-number="true" />
      </div>

      <slot />

      <div class="px-6 py-6 mt-6 bg-light-gray">
        <p class="font-bold text-dark">
          <span v-if="isCurrentAccountPro">Voulez-vous ajouter votre logo ?</span>
          <span v-else>Pour 5€ Rajoutez votre logo !</span>
        </p>
        <image-uploader class="mt-2" :image="content.logo" @change="changeLogo" />
      </div>

      <div class="mt-6 text-right">
        <slot name="buttons"></slot>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { legalAdModelStateInput as modelStateInput } from '@/store';
import AdType from './panes/ad-type';
import RcsInput from '@/components/shared/rcs-input.vue';
import PublishingInfoPane from './panes/publishing-info'
import ImageUploader from '@/components/legal-ads/forms/shared/image-uploader'
import HeaderPane from './panes/header'
 import { regex } from '@/constants';

export default {
  name: 'LegalAdFormWrapper',
  components: { AdType, RcsInput, HeaderPane, PublishingInfoPane, ImageUploader },  
  props: {
    withPublishingInfo: { type: Boolean, default: true, required: false },
    showAdType: { type: Boolean, default: true, required: false },
    showError: { type: Boolean, default: false, required: false },
    editMode: { type: Boolean, default: false, required: false },
    isModel: { type: Boolean, default: false, required: false }
  },
  computed: {
    ...mapState(['legalAd']),
    content() {
      return this.legalAd?.content;
    },
    displayAdType() {
      return (this.$route.name === 'editLegalAdDraft' && !this.isModel) || !this.isModel;
    },
    showRcsInput() {
      return this.isModel && !(this.content.title.includes('constitution') ||
        (this.legalAd.model.type.includes('declaration') ||
        this.legalAd.model.type.includes('changement')));
    },
    companyNameInput: modelStateInput('company', 'name'),
    companyAcronym: modelStateInput('company', 'acronym'),
    companyForm: modelStateInput('company', 'legalForm'),
    companyCapital: modelStateInput('company', 'shareCapital'),
    addressInput: modelStateInput('headOffice', 'address'),
    zipCodeInput: modelStateInput('headOffice', 'zipCode'),
    cityInput: modelStateInput('headOffice', 'city'),
    headOfficeGreffe: modelStateInput('headOffice', 'registrationPlace'),
    headOfficeRegistration: modelStateInput('headOffice', 'registrationNumber')
  },
  methods: {
    ...mapActions(['changeLegalAdContent']),
    async changeLogo(logo) {
      this.changeLegalAdContent({ changes: { logo }});
    },
    // Prefill and format data in forms
    fillForm(data) {
      if (data) {
        const siege = data.siege;

        this.companyAcronym = data.sigle || '';
        this.companyForm = this.formatLegalForm(data.formeJuridique) || '';
        this.companyNameInput = data.nomEntreprise || '';
        this.companyCapital = data.capitalFormate || '0 €';

        this.addressInput = siege.adresse_ligne_1 || '';
        this.zipCodeInput = siege.code_postal || '';
        this.cityInput = siege.ville || '';
        this.headOfficeGreffe = data.greffe || '';
        this.headOfficeRegistration = data.sirenFormate || '';
      }
    },
    // Find capital letter for legal form returned by Pappers API with regex
    formatLegalForm(string) {
      return string.replace(regex.capitalLetters, '');
    }
  },
}
</script>
