<template>
  <div class="flex flex-col">
    <modal name="additional-services-modal"
      height="auto"
      :scrollable="true"
    >
      <AdditionalServicesModal />
    </modal>

    <div v-if="isLoading" class="flex items-center justify-center flex-grow">
      <font-awesome-icon icon="spinner" size="2x" spin />
    </div>

    <div class="flex flex-col flex-grow" v-else>
      <div class="flex items-center justify-center flex-grow" v-if="hasAlert">
        <t-alert v-if="apiBlocked">
          <h5 class="text-lg font-bold">
            Nous ne sommes pas en mesure d'afficher vos annonces
          </h5>
          <p class="mt-2">
            Veuillez vérifier sur votre navigateur que vous avez désactivé tout
            plugin bloquant les publicités.
          </p>
        </t-alert>

        <t-alert level="secondary" class="px-16" v-if="!notEmpty">
          <h5 class="text-lg font-bold">
            Vous n'avez pas encore crée d'annonces légales.
          </h5>
          <p class="my-8">
            <router-link
              :to="{ name: 'newRawLegalAd' }"
              class="px-4 py-3 text-white transition-all bg-primary hover:bg-opacity-90"
              >Créer une annonce sans modèle</router-link
            >
            <strong class="mx-4">ou</strong>
            <router-link
              :to="{ name: 'newModelLegalAd' }"
              class="px-4 py-3 text-white transition-all bg-primary hover:bg-opacity-90"
              >Utiliser un modèle d'annonce</router-link
            >
          </p>
        </t-alert>
      </div>

      <div class="mt-8" v-else>
        <Counters v-if="isCurrentAccountPro" class="pt-8 mb-8" />

        <table class="table" :class="{ 'table-responsive': isMobile }">
          <thead>
            <tr>
              <th>Référence</th>
              <th>Commandée le</th>
              <th>Publiée le</th>
              <th>Montant TTC</th>
              <th>Facturation client</th>
              <th class="text-center">Téléchargement</th>
            </tr>
          </thead>
          <tbody>
            <LegalAd
              v-for="legalAd in legalAds"
              :key="legalAd.reference"
              :legalAd="legalAd"
              @show-modal="showModal"
              @refresh="refresh"
            />
          </tbody>
        </table>

        <Pagination
          :meta="meta"
          class="mt-8"
          v-if="meta"
          @loadPage="loadPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as LegalAdService from "@/services/legal-ad";
import LegalAd from "./legal-ad";
import Counters from "./counters";
import Pagination from "./pagination";
import { AdditionalServicesModal } from '@/components/shared/modals';

export default {
  name: "MyLegalAds",
  components: { LegalAd, Counters, Pagination, AdditionalServicesModal },
  data() {
    return {
      isLoading: true,
      legalAds: null,
      meta: null,
      apiBlocked: false,
      currentPage: 1,
      showAdditionalServices: false
    };
  },
  mounted() {
    this.loadPage(1);
  },
  computed: {
    hasAlert() {
      return this.apiBlocked || !this.notEmpty;
    },
    notEmpty() {
      return this.legalAds?.length > 0;
    },
  },
  methods: {
    async loadPage(page) {
      this.currentPage = page;
      this.isLoading = true;
      await this.coreLoadPage(page);
      this.isLoading = false;
    },
    async refresh() {
      await this.coreLoadPage(this.currentPage);
    },
    async coreLoadPage(page) {
      let { list, meta, error, additionalServices } = await LegalAdService.findAll(page);
      this.legalAds = list;
      this.meta = meta;
      this.apiBlocked = error?.blocked;
      this.showAdditionalServices = additionalServices;

      this.showModal();
    },
    showModal() {
      if (this.$route?.params?.payment && this.showAdditionalServices) {
        this.$modal.show('additional-services-modal');
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.table {
  @apply w-full;
  thead {
    th {
      @apply bg-primary text-white py-3 px-3;
    }
  }
  & > tbody {
    td {
      @apply border-b border-gray-500 py-3 px-3;
    }
  }
}
</style>
