import * as Act from '@/services/legal-ad-form/components/act';
import * as Company from '@/services/legal-ad-form/components/company';
import * as HeadOffice from '@/services/legal-ad-form/components/head-office';
import * as OwnerList from '@/services/legal-ad-form/components/owner-list';
import * as PartnerList from '@/services/legal-ad-form/components/partner-list';
import { trimObject } from '@/shared/utils';

export default {
  id: 'snc',
  label: 'Avis de constitution SNC',
  shortLabel: 'Avis de constitution',
  componentsData: {
    act: { type: '' },
    company: { legalForm: 'Société en nom collectif' },
    headOffice: {},
    ownerList: [],
    partnerList: []
  },
  component: () => import('@/components/legal-ads/forms/models/registration/snc.vue'),   
  validate(modelData) {
    return trimObject({ 
      act: Act.validate(modelData.act),
      company: Company.validate(modelData.company),
      headOffice: HeadOffice.validate(modelData.headOffice),
      ownerList: OwnerList.validate(modelData.ownerList),
      partnerList: PartnerList.validate(modelData.partnerList)
    });
  },  
  buildContent(modelData) {
    return [
      Act.buildContent(modelData.act, 'société'),
      Company.buildContent(modelData.company),
      HeadOffice.buildContent(modelData.headOffice),
      OwnerList.buildContent(modelData.ownerList),
      PartnerList.buildContent(modelData.partnerList),
      HeadOffice.buildRegistrationContent(modelData.headOffice),
    ].flat().join("\n").replace(/undefined/g, '?');
  }
};