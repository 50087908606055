<template>
  <div>
    <h2 class="font-semibold" v-html="pageContent('signup.pro.title')" />
    <div v-html="pageContent('signup.pro.features')" />
  </div>
</template>

<script>
export default {
  name: 'AuthText'
};
</script>
