<template>
  <div>
    <h1 class="text-primary text-lg font-extrabold flex">
      <span class="border-b border-yellow-300 pb-4">Changement mot de passe</span>
    </h1>

    <t-alert v-if="hasError" class="mt-8">
      Votre mot de passe ou/et sa confirmation ne sont pas valides ou bien vous avez déjà effectué le changement.
    </t-alert>

    <t-alert v-if="isSuccess" class="mt-8" level="success">
      Votre mot de passe a été modifié avec succès.
    </t-alert>

    <div v-else class="mt-8">
      <p class="text-gray-700">
        Vous avez demandé la modification de votre mot de passe. Si ce n'est pas le cas, veuillez nous contacter.
      </p>

      <form class="mt-8" @submit.prevent="resetPassword">
        <div class="mb-10 input">
          <label for="account-password">Votre nouveau mot de passe</label>
          <input id="account-password" v-model="account.password" type="password" />
        </div>

        <div class="mb-10 input">
          <label for="account-password">Confirmation de votre nouveau mot de passe</label>
          <input id="account-password-confirmation" v-model="account.passwordConfirmation" type="password" />
        </div>

        <div class="mt-12 text-right">
          <t-submit-button
            :button-state="buttonState"
            :labels="{ default: 'Modifier', inProgress: 'Modification...', success: 'Modifié !', fail: 'Échec' }"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import * as AuthService from '@/services/authentication';

export default {
  name: 'AuthResetPassword',
  data() {
    return {
      isSuccess: false,
      hasError: false,
      account: {
        resetPasswordToken: null,
        password: null,
        passwordConfirmation: null
      },
      buttonState: 'default'
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.account.resetPasswordToken = this.$route.params.resetPasswordToken;
      }
    }
  },
  methods: {
    prepare(event) {
      this.account.resetPasswordToken = event.params.resetPasswordToken;
    },
    resetPassword() {
      this.buttonState = 'inProgress';
      this.hasError = false;
      AuthService.resetPassword(this.account)
      .then(() => this.onSuccess())
      .catch(() => this.onError())
      .then(() => this.buttonState = 'default');
    },
    onSuccess() {
      this.isSuccess = true;
      this.account = { resetPasswordToken: null, password: null, passwordConfirmation: null };
    },
    onError() {
      this.hasError = true;
    },
  }
};
</script>
