import Vue from 'vue';
import Vuex from 'vuex';
import { NewspaperService, PageContentService, LegalAdForm, LegalAdService, LegalAdDraftService } from '@/services';
import {
  SIGN_IN, SIGN_OUT, SET_NEWSPAPERS, SET_PAGE_CONTENT, UPDATE_MY_ACCOUNT,
  START_EDITING_LEGAL_AD, STOP_EDITING_LEGAL_AD, CLEAR_LEGAL_AD_MODIFICATIONS_STATUS,
  CHANGE_LEGAL_AD, CHANGE_LEGAL_AD_CONTENT, CHANGE_LEGAL_AD_BILLING_ADDRESS,
  CHANGE_LEGAL_AD_MODEL, ADD_LEGAL_AD_MODEL_NESTED_OBJECT, REMOVE_LEGAL_AD_MODEL_NESTED_OBJECT,
  UPDATE_LEGAL_AD_MODEL_CONTENT, VALIDATE_LEGAL_AD_MODEL, SET_LEGAL_ADS,
  SET_LEGAL_AD_DRAFTS, SET_LOADING, SET_TOUCHED } from './mutation-types';

Vue.use(Vuex);

// load the initial state
const defaultState = {
  isAuthenticated: false,
  account: null,
  token: null,
  legalAd: null,
  legalAdErrors: null,
  legalAdModified: false,
  newspapers: [],
  pageContent: [],
  legalAds: [],
  legalAdDrafts: [],
  loading: false,
  touched: false
};
const state = {...defaultState};

const mutations = {
  [SIGN_IN](state, { token, ...account }) {
    state.isAuthenticated = true;
    state.account = account;
    state.token = token;
  },
  [SIGN_OUT](state) {
    state.isAuthenticated = false;
    state.account = null;
    state.token = null;
  },
  [SET_NEWSPAPERS](state, newspapers) {
    state.newspapers = newspapers;
  },
  [SET_PAGE_CONTENT](state, content) {
    state.pageContent = content;
  },
  [UPDATE_MY_ACCOUNT](state, newAccount) {
    localStorage.setItem('account', JSON.stringify(newAccount));
    state.account = { ...newAccount };
  },
  [START_EDITING_LEGAL_AD](state, newLegalAd) {
    state.legalAd = newLegalAd;
    state.legalAdErrors = null;
    state.legalAdModified = false;
  },
  [STOP_EDITING_LEGAL_AD](state) {
    state.legalAd = null;
    state.legalAdModified = false;
  },
  [CLEAR_LEGAL_AD_MODIFICATIONS_STATUS](state) {
    state.legalAdModified = false;
  },
  [CHANGE_LEGAL_AD](state, { changes }) {
    state.legalAd = {
      ...state.legalAd,
      ...changes
    };
    state.legalAdModified = true;
  },
  [CHANGE_LEGAL_AD_CONTENT](state, { changes }) {
    state.legalAd = {
      ...state.legalAd,
      content: {
        ...state.legalAd.content,
        ...changes
      }
    };
    state.legalAdModified = true;
  },
  [CHANGE_LEGAL_AD_BILLING_ADDRESS](state, { changes }) {
    state.legalAd = {
      ...state.legalAd,
      addressAttributes: {
        ...state.legalAd.addressAttributes,
        ...changes
      }
    };
    state.legalAdModified = true;
  },
  [CHANGE_LEGAL_AD_MODEL](state, { component, changes }) {
    let newComponent;
    if (Array.isArray(changes)) {
      newComponent = changes;
    } else if (typeof(changes) === 'object') {
      newComponent = {
        ...state.legalAd.model[component],
        ...changes
      };
    } else {
      newComponent = changes;
    }
    state.legalAd = {
      ...state.legalAd,
      model: {
        ...state.legalAd.model,
        [component]: newComponent
      }
    };
    state.legalAdModified = true;
  },
  [ADD_LEGAL_AD_MODEL_NESTED_OBJECT](state, { component, attributes }) {
    const list = state.legalAd.model[component];
    const id = list.reduce((max, o) => o.id > max ? o.id : max, 0) + 1;
    const newList = [].concat(list, [{ id, ...attributes }]);
    state.legalAd.model[component] = newList;
    state.legalAdModified = true;
  },
  [REMOVE_LEGAL_AD_MODEL_NESTED_OBJECT](state, { component, id }) {
    const list = state.legalAd.model[component];
    const index = list.findIndex(o => o.id === id);
    var newList = [].concat(list);
    newList.splice(index, 1);
    state.legalAd.model[component] = newList;
    state.legalAdModified = true;
  },
  [UPDATE_LEGAL_AD_MODEL_CONTENT](state) {
    const modelForm = LegalAdForm.find(state.legalAd.model.type);

    if (Object.keys(state.legalAd.model).length > 1) {
      const newContent = modelForm.buildContent(state.legalAd.model,);
      state.legalAd.model.content = newContent;
      state.legalAd.content.body = newContent;
    }
  },
  [VALIDATE_LEGAL_AD_MODEL](state) {
    const modelForm = LegalAdForm.find(state.legalAd.model.type);
    state.legalAdErrors = modelForm.validate(state.legalAd.model);
  },
  [SET_LEGAL_ADS](state, legalAds) {
    state.legalAds = legalAds;
  },
  [SET_LEGAL_AD_DRAFTS](state, legalAdDrafts) {
    state.legalAdDrafts = legalAdDrafts;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
  [SET_TOUCHED](state, touched) {
    state.touched = touched;
  }
};

const actions = {
  signIn({ commit }, account) {
    commit(SIGN_IN, account);
  },
  signOut({ commit }) {
    commit(SIGN_OUT);
  },
  fetchNewspapers({ commit }) {
    NewspaperService.findAll().then(response => {
      commit(SET_NEWSPAPERS, response.list);
    });
  },
  fetchPageContent({ commit }) {
    PageContentService.fetchPageContent().then(response => {
      commit(SET_PAGE_CONTENT, response);
    });
  },
  updateMyAccount({ commit }, newAccount) {
    commit(UPDATE_MY_ACCOUNT, newAccount);
  },
  startEditingLegalAd({ commit }, newLegalAd) {
    commit(START_EDITING_LEGAL_AD, newLegalAd);
    commit(CHANGE_LEGAL_AD_BILLING_ADDRESS, newLegalAd);
    if (newLegalAd.model)
      commit(UPDATE_LEGAL_AD_MODEL_CONTENT);
  },
  stopEditingLegalAd({ commit }) {
    commit(STOP_EDITING_LEGAL_AD);
  },
  clearLegalAdModificationsStatus({ commit }) {
    commit(CLEAR_LEGAL_AD_MODIFICATIONS_STATUS);
  },
  changeLegalAd({ commit }, { changes }) {
    commit(CHANGE_LEGAL_AD, { changes });
  },
  changeLegalAdContent({ commit }, { changes }) {
    commit(CHANGE_LEGAL_AD_CONTENT, { changes });
  },
  changeLegalAdBillingAddress({ commit }, { changes }) {
    commit(CHANGE_LEGAL_AD_BILLING_ADDRESS, { changes });
  },
  changeLegalAdModel({ commit }, { component, changes }) {
    commit(CHANGE_LEGAL_AD_MODEL, { component, changes });
    commit(UPDATE_LEGAL_AD_MODEL_CONTENT);
  },
  addLegalAdModelNestedObject({ commit }, { component, attributes }) {
    commit(ADD_LEGAL_AD_MODEL_NESTED_OBJECT, { component, attributes });
    commit(UPDATE_LEGAL_AD_MODEL_CONTENT);
  },
  removeLegalAdModelNestedObject({ commit }, { component, id }) {
    commit(REMOVE_LEGAL_AD_MODEL_NESTED_OBJECT, { component, id });
    commit(UPDATE_LEGAL_AD_MODEL_CONTENT);
  },
  validateLegalAdModel({ commit }) {
    commit(VALIDATE_LEGAL_AD_MODEL);
  },
  setLoading({ commit }, loading) {
    commit(SET_LOADING, loading);
  },
  setTouched({ commit }, touched) {
    commit(SET_TOUCHED, touched);
  },
  fetchLegalAds({ commit, dispatch }) {
    dispatch('setLoading', true);
    LegalAdService.findAll().then(response => {
      commit(SET_LEGAL_ADS, response.list);
      dispatch('setLoading', false);
    });
  },
  fetchLegalAdDrafts({ commit, dispatch }) {
    dispatch('setLoading', true);
    LegalAdDraftService.findAll().then(response => {
      commit(SET_LEGAL_AD_DRAFTS, response.list);
      dispatch('setLoading', false);
    });
  }
};

const getters = {
  newspaperZipCodes: state => {
    const uniqueList = [];
    const alreadyProcessed = {};
    state.newspapers.forEach(newspaper => {
      if (alreadyProcessed[newspaper.zipCode] === undefined) {
        uniqueList.push({ label: newspaper.zipCodeName, value: newspaper.zipCode });
        alreadyProcessed[newspaper.zipCode] = true;
      }
    });
    return uniqueList;
  },
  newspaperTitle: state => newspaperId => {
    const newspaper = state.newspapers.find(newspaper => newspaper.id === newspaperId);
    return `${newspaper.title} (${newspaper.zipCode})`;
  }
};

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state,
  mutations,
  actions,
  getters,
});

export const legalAdContentStateInput = (attribute) => {
  return {
    get() { return store.state.legalAd.content[attribute]; },
    set(value) { store.dispatch('changeLegalAdContent', { changes: { [attribute]: value } }); }
  };
};

export const legalAdModelStateInput = function(component, attribute) {
  return {
    get() {
      const safeComponent = component || this.modelName;
      return store.state.legalAd.model[safeComponent][attribute];
    },
    set(value) {
      const safeComponent = component || this.modelName;
      store.dispatch('changeLegalAdModel', { component: safeComponent, changes: { [attribute]: value } });
    }
  };
};

export const legalAdModelStateNestedInput = (component, propName, attribute) => {
  return {
    get() {
      const safeComponent = component || `${this.modelName}List`;
      const safePropName = propName || this.modelName;
      const list = store.state.legalAd.model[safeComponent];
      const object = list.find(o => o.id === this[safePropName].id);
      return object[attribute];
    },
    set(value) {
      const safeComponent = component || `${this.modelName}List`;
      const safePropName = propName || this.modelName;
      var newList = [].concat(store.state.legalAd.model[safeComponent]);
      var object = newList.find(o => o.id === this[safePropName].id);
      const index = newList.findIndex(o => o.id === this[safePropName].id);
      const newObject = { ...object, [attribute]: value };
      newList.splice(index, 1, newObject);
      store.dispatch('changeLegalAdModel', { component: safeComponent, changes: newList });
    }
  };
};

export default store;
