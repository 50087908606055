import { PdfPreview, HtmlPreview } from '@/components/previews';

export default {
  components: { PdfPreview, HtmlPreview },
  data() {
    return {
      previewMode: 'PDF',
    };
  },
  computed: {
    previewComponent() {
      return this.previewMode === 'PDF' ? PdfPreview : HtmlPreview;
    }
  },
  methods: {
    setPreviewMode(mode) {
      this.previewMode = mode;
    }
  }
};