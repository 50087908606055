<template>
  <FormWrapper class="mx-8 mt-8" :edit-mode="editMode">
    <template v-slot:title>
      <h1 class="mb-8 text-xl font-bold text-primary">
        Formulaire de rédaction libre
      </h1>
    </template>

    <template v-slot:error>
      <t-alert
        v-if="showErrorMessage"
        ref="errorMessage"
        class="my-8"
        level="danger"
      >
        <p>
          Vous devez remplir <u>obligatoirement</u> toutes les
          <strong>informations de publication</strong> ci-dessous.
        </p>
      </t-alert>
    </template>

    <DefaultForm v-if="legalAd" class="mt-8" />

    <template v-slot:buttons>
      <t-submit-button
        v-if="isCurrentAccountPro && !isDraft"
        type="button"
        :secondary="true"
        :button-state="draftButtonState"
        :labels="{
          default: 'Créer devis',
          inProgress: 'Création du devis',
          success: 'Devis crée !',
          fail: 'Échec',
        }"
        @click="createDraft"
      />
      <t-submit-button
        v-if="isCurrentAccountPro && isDraft"
        type="button"
        :secondary="true"
        :button-state="draftButtonState"
        :labels="{
          default: 'Mettre à jour le devis',
          inProgress: 'Mise à jour',
          success: 'Mise à jour effectuée !',
          fail: 'Échec',
        }"
        @click="updateDraft"
      />
      <button
        type="button"
        class="px-12 py-3 ml-6 text-white transition-all hover:bg-opacity-90 bg-primary"
        @click="goNext"
      >
        Valider
      </button>
    </template>
  </FormWrapper>
</template>

<script>
import { mapActions } from "vuex";
import FormWrapper from "@/components/legal-ads/forms/wrapper";
import DefaultForm from "@/components/legal-ads/forms/default";
import SafeEditingMixin from "@/mixins/safe-editing";
import PersistingDraftMixin from "@/mixins/persisting-draft";

export default {
  name: "RawNewLegalAd",
  components: { FormWrapper, DefaultForm },
  mixins: [PersistingDraftMixin, SafeEditingMixin],
  props: {
    legalAd: { type: Object, required: true }
  },
  computed: {
    editMode() {
      return (this.$route.name.includes('edit')) || (this.$route.name.includes('new') && this.$store.state.touched) || this.proHasLegalAdModel;
    }
  },
  methods: {
    ...mapActions([
      'startEditingLegalAd',
      'stopEditingLegalAd',
      'clearLegalAdModificationsStatus',
      'setTouched'
    ]),
    goNext() {
      this.onlyIfPublishingInfoValid(() => this.$emit('next'));
      this.setTouched(true);
    },
  },
};
</script>
