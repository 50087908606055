import { CIVILITY, validatePresenceOf } from '@/shared/utils';

export const validateMany = (list, required, validateFunc) => {
  var errors = { list: {} };
  if (list.length === 0 && required) {
    errors.self = 'Cette liste ne peut pas être vide';
  } else {
    list.forEach(person => errors.list[person.id] = (validateFunc || validate)(person));
  }
  return errors;
};

export const validate = (person, extraFields) => {
  var fields = ['type', 'address', 'zipCode', 'city'].concat(extraFields || []);
  switch (person.type) {
    case 'physical':
      fields = fields.concat(['civility', 'firstName', 'lastName']);
      break;
    case 'moral':
      fields = fields.concat(['companyName', 'legalForm', 'registrationPlace', 'registrationNumber']);
      break;
  }
  return validatePresenceOf(person, fields);
};

export const buildManyContent = (list, title) => {
  return list.map(person => buildContent(person, title));
};

export const buildContent = (person, title) => {
  var line = '';
  var address = `${person.address} ${person.zipCode} ${person.city}`;
  const fullTitle = title ? `${title} : ` : '';

  switch (person.type) {
    case 'physical':
      line = `${fullTitle}${CIVILITY[person.civility]} ${person.firstName} ${person.lastName} demeurant ${address}.`;
      break;
    case 'moral':
      line = `${fullTitle}${person.legalForm} ${person.companyName}`;
      line = `${line}, sise ${address}`;
      line = `${line}, immatriculé au RCS de ${person.registrationPlace} sous le numéro ${person.registrationNumber}`;
      if (person.representative) {
        line = `${line} et représenté par ${person.representative}`;
        line = `${line} demeurant ${person.representativeAddress}.`;
      }
      break;
  }

  return line !== '' ? [line] : [];
};