export const CIVILITY = {
  mr: 'Monsieur',
  mrs: 'Madame'
}

export const isBlank = object => {
  return (
    object === undefined ||
    object === null ||
    (typeof object === 'string' && object.length === 0) ||
    (typeof object === 'object' && object.length === 0)
  )
}

const base64ToImage = base64 => {
  var data = base64.replace('; charset=utf-8;', '');
  var typeMatches = data.match(/image\/([a-z]+)/);
  var type = typeMatches ? typeMatches[1].toUpperCase() : 'PNG';
  
  return new Promise((resolve, reject) => {
    var image = new Image(); 
    image.onload = () => resolve({ data, type, width: image.width, height: image.height })
    image.onerror = reject
    image.src = base64;
  });
}

export const toDataURL = url => fetch(url)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  }))
  .then(base64ToImage);

export const inputFileToBase64 = file => {
  return new Promise(function(resolve, reject) {
    var reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => { resolve(`data:${file.type};base64,${btoa(reader.result)}`) },
    reader.onerror = reject;
  })
  .then(base64ToImage);
}

export const numberToHumanSize = size => {
  if(size < 1024)
    return size + ' octets';
  else if(size < 1024.0 * 1024.0)
    return (size / 1024.0).toFixed(2).replace('.00', '') + ' Ko'
  else if(size < 1024.0 * 1024.0 * 1024.0)
    return (size / 1024.0 / 1024.0).toFixed(2) + ' Mo'
  else
    return (size / 1024.0 / 1024.0 / 1024.0).toFixed(2) + ' Go';
}

export const toCamel = (s) => {
  return s.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

export const nextOpenDay = (isAdmin, threshold) => {
  const today = new Date();
  var nextDay = new Date(today);
  nextDay.setDate(nextDay.getDate() + (isAdmin ? 0 : threshold || 2));
  do {
    nextDay.setDate(nextDay.getDate() + 1)
  } while(nextDay.getDay() === 0 || nextDay.getDay() === 6)
  return nextDay;
}

export const trimObject = object => {
  if (typeof(object) === 'object') {
    if (Array.isArray(object)) {
      object.forEach(deepObject => {
        trimObject(deepObject);
      });
      if (object.length === 0)
        return undefined;
    } else {
      for (var key in object) {
        if (object[key]) {
          object[key] = trimObject(object[key]);
        } 
        if (!object[key]) 
          delete(object[key]);
      }
      if (Object.keys(object).length === 0)
        return undefined;
    }   
  }
  return object;
}

export const isMobile = () => {
  let check = false;
  (function(agent) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(agent) || 
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(agent.substr(0,4))) {
      check = true;
    }
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

export const formatDate = date => {
  return formatLegalActDate(date);
};

export const formatLegalActDate = date => {
  date = typeof(date) === 'string' ? Date.parse(date) : date;
  var options = { year: 'numeric', month: 'long', day: 'numeric' };
  var formatter = new Intl.DateTimeFormat('fr-FR', { style: 'long', ...options });
  return formatter.format(date);
}

export const validatePresenceOf = (model, attributes, options) => {
  var errors = {};
  var safeList = attributes.filter(f => !(options?.skip || []).includes(f));
  safeList = safeList.concat(options?.add || []);
  safeList.forEach(attribute => {
    var value = model && model[attribute];
    if (typeof(value) === 'string') value = value.trim().length === 0 ? undefined : value;
    if (!value) errors[attribute] = "Doit être rempli."
  });
  return errors;
}

export const benchmark = name => {
  var start = new Date();
  return {
    stop: function() {
      var end  = new Date();
      var time = end.getTime() - start.getTime();
      console.log('Timer:', name, 'finished in', time, 'ms');
    }
  }
};

export const parseDateRecursively = (object, parentObject, parentKey) => {
  if (typeof(object) === 'object') {
    if (Array.isArray(object)) {
      object.forEach(deepObject => {
        parseDateRecursively(deepObject);
      });      
    } else {
      for (var key in object) {
        if (object[key]) {
          parseDateRecursively(object[key], object, key);
        }        
      }      
    }   
  } else if (typeof(object) === 'string' && RegExp('^20[0-9]{2}-[0-9]{2}-[0-9]{2}T').test(object)) {
    parentObject[parentKey] = new Date(Date.parse(object));    
  }
  return object;
}

export const weekDayName = (weekDay) => {
  return ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'][weekDay]
}