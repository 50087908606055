<template>
  <div class="mt-10">
    <h1 class="mb-8 text-xl font-bold text-primary">
      Paiement via Stripe
    </h1>

    <div class="mt-8">
      <div class="flex items-center">
        <div>
          <h3 class="font-bold">Carte de crédit</h3>
          <p class="mt-2 text-gray-600">
            Le paiement est sécurisé et assuré via <a class="underline" href="https://stripe.com/fr">Stripe</a>. Nous acceptons les cartes Mastercard et Visa.
          </p>
        </div>
        <div class="flex ml-auto flex-nowrap">
          <img alt="mastercard" src="@/assets/billing/mastercard.png" class="h-8 mr-2">
          <img alt="visa" src="@/assets/billing/visa.png" class="h-8">
        </div>
      </div>

      <div class="w-full px-3 pt-2 pb-2 mt-5 text-gray-500 border-b border-gray-400 outline-none bg-light-gray" data-target="stripe.card">
      </div>

      <t-alert class="mt-5 font-bold text-danger" v-if="error">
        <p>Une erreur est survenue. Veuillez réessayer plus tard.</p>
        <p v-if="(typeof error === 'string')">{{ error }}</p>
      </t-alert>
    </div>

    <div class="mt-8 text-right">
      <t-submit-button
        type="button"
        :buttonState="buttonState"
        :labels="{ default: 'Payer', inProgress: 'Paiement en cours', success: 'Paiement avec Succès !', fail: 'Échec' }"
        @click="pay"
        :disabled="!isReady"
      />
    </div>
  </div>
</template>

<script>
import api from '@/shared/api'
import { STRIPE_PUBLISHABLE_KEY } from '@/shared/config'

export default {
  name: 'Stripe',
  props: {
    info: {
      type: Object,
      default: null,
      required: true
    },
    address: {
      type: Object,
      default: null,
      required: true
    }
  },
  data() {
    return { 
      paymentInformation: null,
      buttonState: 'default',
      stripeAPIToken: STRIPE_PUBLISHABLE_KEY,
      stripe: null,
      elements: null,
      card: null,
      error: null
    }
  },
  async mounted() {
    this.includeStripe('js.stripe.com/v3/', () => this.configureStripe());
    const response = await api.post(`/legal_ads/${this.info.id}/client_payments`);
    this.paymentInformation = response.data;
  },
  computed: {
    isReady() {
      return this.buttonState === 'default' && this.paymentInformation && this.stripe && this.card;
    }, 
  },
  methods:{
    pay() {
      if (!this.isReady) return false;
      this.buttonState = 'inProgress';

      this.stripe.confirmCardPayment(this.paymentInformation.clientPaymentSecret, {
        payment_method: {
          card: this.card,
          billing_details: {
            name: this.address.fullName,
            email: this.address.email
          }
        }
      }).then(result => {
        if (result.error) {
          // Show error to your customer (e.g., insufficient funds)
          this.buttonState = 'fail';
          this.error = result.error?.message;
        } else {
          delete this.address.email;
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {
            api.put(`/legal_ads/${this.info.id}/client_payments`, {
              params: {
                payment_intent_id: result.paymentIntent.id,
                id: this.info.order.id,
                address_attributes: this.address
              }
            }).then(({ status }) => {
              if (status === 204) (
                this.$emit('payment-result')
              )
            })
            .catch(() => {
              this.buttonState = 'fail';
              this.error = true;
            })
          }
        }
      }).catch(error => {
        console.log('Stripe error', error);
      });
    },
    includeStripe(URL, callback ) {
      let documentTag = document, tag = 'script',
          object = documentTag.createElement(tag),
          scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = '//' + URL;
      if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    configureStripe() {
      this.stripe = window.Stripe(this.stripeAPIToken, { locale: 'fr' });
      var style = {
        base: {
          // Add your base input styles here. For example:
          fontSize: '16px',
          color: '#32325d',
        }
      }

      this.elements = this.stripe.elements();
      this.card = this.elements.create('card', { style });

      this.card.addEventListener('change', ({ error }) => {
        this.error = error?.message
        this.buttonState = error ? 'fail' : 'default';
      });
      this.card.mount(this.$el.querySelectorAll('[data-target="stripe.card"]')[0]);
    },
  }
}
</script>
