import store from '@/store';

const MESSAGE = 'Vos modifications seront perdues si vous quittez cette page.';

export default {
  mounted() {
    window.onbeforeunload = () => {
      if (store.state.legalAdModified) {
        return MESSAGE;
      }
    };
  },
  beforeRouteLeave(to, from, next) {
    // we don't want the next view to trigger the onbeforeunload event
    window.onbeforeunload = null;

    if (store.state.legalAdModified) {
      const answer = window.confirm(MESSAGE);
      if (answer) {
        // reset the legal ad to avoid conflicts with the next legal ad form
        store.dispatch('stopEditingLegalAd');

        next();
      } else {
        next(false);
      }
    } else {
      // reset the legal ad to avoid conflicts with the next legal ad form
      store.dispatch('stopEditingLegalAd');

      next();
    }
  }
};