import EditLegalAd from '@/views/admin/legal-ads/edit';

export default [
  {
    path: '/admin',
    redirect: '/',
  },
  {
    name: 'editAdminLegalAd',
    path: '/admin/annonces-legales/:legalAdId',
    component: EditLegalAd,
    meta: {
      admin: true,
    }
  }
];