import { CIVILITY, validatePresenceOf } from '@/shared/utils';

export const validateMany = (list, required, options) => {
  var errors = { list: {} };
  if (list.length === 0 && required) {
    errors.self = 'Cette liste ne peut pas être vide';
  } else {
    list.forEach(person => errors.list[person.id] = validate(person, options));
  }
  return errors;
};

export const validate = (person, options) => {
  var fields = ['type', 'address', 'zipCode', 'city'];
  switch (person.type) {
    case 'physical':
      fields = fields.concat(['civility', 'firstName', 'lastName']);
      break;
    case 'moral':
      fields = fields.concat(['companyName', 'legalForm', 'registrationPlace', 'registrationNumber']);
      break;
    case 'none':
      fields = [];
  }
  return validatePresenceOf(person, fields, options);
};

export const buildManyContent = (list, title, occupation) => {
  return list.map(person => buildContent(person, title, occupation));
};

export const buildSimpleContent = (person, title, occupation) => {
  var line = '';
  var address = `${person.address} ${person.zipCode} ${person.city}`;

  switch (person.type) {
    case 'physical':
      line = `${CIVILITY[person.civility]} ${person.firstName} ${person.lastName} demeurant ${address}`;
      break;
    case 'moral':
      line = `${person.legalForm} ${person.companyName}`;
      line = `${line}, sise ${address}`;
      line = `${line}, immatriculé au RCS de ${person.registrationPlace} sous le numéro ${person.registrationNumber}`;
      if (person.representative) {
        line = `${line} et représenté par ${person.representative}`;
        line = `${line} demeurant ${person.representativeAddress}`;
      }
      break;
    default:
      if (person.simpleName) line = `${person.simpleName}`;
  }

  if (occupation) line = `${line} ${occupation}`;
  line = title ? `${line} (${title})` : `${line} en remplacement de ${person.simpleName}`;

  return line;
};

export const buildContent = (person, title, occupation) => {
  var line = buildSimpleContent(person, title, occupation);
  line = person.quality ? `- ${person.quality} : ${line}.` : `- ${line}.`;
  return [line];
};