<template>
  <tr class="row">
    <th>
      <p class="text-gray-900">{{ legalAd.reference }}</p>
      <p class="mt-1 font-normal text-gray-500" v-if="legalAd.altName">{{ legalAd.altName }}</p>   
    </th>
    <td class="text-center">{{ orderedAt }}</td>
    <td class="text-center">{{ publishingAt }}</td>
    <td class="text-center">
      {{ totalAmount }}
      <span class="px-2 py-1 text-xs bg-green-100 text-success rounded-xl" v-if="isCurrentAccountPro && paid">payée</span>
    </td>
    <td class="text-center">
      <font-awesome-icon v-if="clientBilling" icon="check" class="text-success" />
      <div v-else>-</div>
    </td>
    <td class="text-center">      
      <div class="inline-block ml-3 btn btn-success" v-if="isPending">
        <font-awesome-icon icon="spinner" spin class="mr-2" size="lg" />
        Attestation en cours de création...
      </div>
      <a :href="legalAd.certificatePDFUrl" class="ml-3 btn btn-success" target="_blank" v-else>
        <font-awesome-icon icon="file-download" class="mr-2" size="lg" />
        Attestation
      </a>

      <a :href="legalAd.proofPDFUrl" class="ml-3 btn btn-success" target="_blank" v-if="legalAd.proofPDFUrl">
        <font-awesome-icon icon="file-download" class="mr-2" size="lg" />
        Justificatif
      </a>
      <a :href="legalAd.invoicePDFUrl" class="ml-3 btn btn-purple" target="_blank" v-if="legalAd.invoicePDFUrl">
        <font-awesome-icon icon="file-download" class="mr-2" size="lg" />
        Facture
      </a>
      <a :href="legalAd.creditPDFUrl" class="ml-3 btn btn-purple" target="_blank" v-if="legalAd.creditPDFUrl">
        <font-awesome-icon icon="file-download" class="mr-2" size="lg" />
        Avoir
      </a>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'MyAccountLegalAd',
  props: {
    legalAd: {
      type: Object,
      default: null
    }
  },
  destroyed() {
    this.stopRefreshing();
  },
  computed: {
    paid() {
      return this.legalAd.proPaid || (this.legalAd.paid && this.legalAd?.clientEmail?.length > 0);
    },
    totalAmount() {
      return this.numberToCurrency(this.legalAd.totalAmount);
    },
    orderedAt() {
      return this.legalAd.orderedAt ? this.formatDate(new Date(this.legalAd.orderedAt)) : null;
    },
    publishingAt() {     
      return this.legalAd.publishingAt ? this.formatDate(new Date(this.legalAd.publishingAt)) : null;
    },
    isPending() {
      return this.legalAd.certificatePDFUrl === undefined;
    },
    clientBilling() {
      return this.legalAd?.clientEmail?.length > 0;
    }
  },
  methods: {
    stopRefreshing() {
      if (this.refreshingInterval) {
        clearInterval(this.refreshingInterval)
      }
    }
  },
  watch: {
    isPending: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue && !oldValue) {
          this.refreshingInterval = setInterval(() => {
            this.$emit('refresh');
          }, 10000)
        } else {
          this.stopRefreshing();
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .row {
    td, th {
      @apply border-b border-gray-200 py-3 px-3;
    }
  }

  .btn {
    @apply px-4 py-2 text-xs rounded rounded-xl transition-all;
    &.btn-success {
      @apply bg-success text-white hover:bg-opacity-90;
    }

    &.btn-purple {
      @apply bg-primary text-white hover:bg-opacity-90;
    }
  }
</style>
