import { CIVILITY, validatePresenceOf } from '@/shared/utils';

export const validateMany = (list, required, options) => {
  var errors = { list: {} };
  if (list.length === 0 && required) {
    errors.self = 'Cette liste ne peut pas être vide';
  } else {
    list.forEach(person => errors.list[person.id] = validate(person, options));    
  }
  return errors;
}

export const validate = (person, options) => {
  if (person) {
    var fields = ['type', 'address', 'zipCode', 'city'];
    switch (person.type) {
      case 'physical': 
        fields = fields.concat(['civility', 'firstName', 'lastName']);          
        break;
      case 'moral': 
        fields = fields.concat(['companyName', 'legalForm', 'registrationPlace', 'registrationNumber']);
        break;
      case 'none':
        fields = [];
    }       
    return validatePresenceOf(person, fields, options);
  }  
}

export const buildManyContent = (list, title) => {
  return list.map(person => buildContent(person, title));
}

export const buildSimpleContent = (person) => {  
  let line = '';
  let address = `${person?.address} ${person?.zipCode} ${person?.city}`;  
  
  switch (person?.type) {
    case 'physical': 
      line = `${CIVILITY[person.civility]} ${person.firstName} ${person.lastName} demeurant ${address}`;
      break;
    case 'moral': 
      line = `${person.legalForm} ${person.companyName}`;
      line = `${line}, sise ${address}`;
      line = `${line}, immatriculé au RCS de ${person.registrationPlace} sous le numéro ${person.registrationNumber}`;
      if (person.representative) {
        line = `${line} et représenté par ${person.representative}`;
        line = `${line} demeurant ${person.representativeAddress}`
      }
      break;
    default:
      if (person?.simpleName) line = `${person?.simpleName}`;
  }    

  return line;
}

export const buildContent = (person) => {  
  return [buildSimpleContent(person)];  
}