<template>
  <aside class="sidebar">
    <modal name="additional-services-modal"
      height="auto"
      :scrollable="true"
    >
      <AdditionalServicesModal />
    </modal>

    <div class="sidebar-header">
      <router-link :to="{ name: 'home' }">
        <img src="@/assets/logo.svg" alt="Logo Journal Toulousain" />
      </router-link>
    </div>
    <div class="sidebar-body">
      <router-link :to="{ name: 'newRawLegalAd' }" class="sidebar-item" active-class="active">
        Nouvelle annonce texte libre
      </router-link>
      <router-link :to="{ name: 'newModelLegalAd' }" class="sidebar-item" active-class="active">
        Nouvelle annonce via formulaire
      </router-link>
      <router-link :to="{ name: 'myLegalAds' }" class="sidebar-item" active-class="active">
        Mes annonces
      </router-link>
      <router-link :to="{ name: 'myLegalAdDrafts' }" class="sidebar-item" active-class="active">
        Mes devis
      </router-link>
      <router-link :to="{ name: 'editMyAccount' }" class="sidebar-item" active-class="active">
        Mon profil
      </router-link>
      <router-link :to="{ name: 'myLegalAdSettings' }" class="sidebar-item" active-class="active">
        Mon modèle d'annonce
      </router-link>
      <div class="cursor-pointer sidebar-additional-service" @click="showAdditionalServicesModal()">
        Besoin d'un site internet pour vos clients ?
      </div>
    </div>
    <div class="sidebar-footer">
      <button class="sidebar-signout" @click.prevent="signOut">
        <font-awesome-icon icon="sign-out-alt" class="mr-2" />
        Se déconnecter
      </button>
    </div>
  </aside>
</template>

<script>
import * as AuthService from "@/services/authentication";
import { AdditionalServicesModal } from '@/components/shared/modals';

export default {
    name: "Sidebar",
    components: { AdditionalServicesModal },
    methods: {
        signOut() {
            AuthService.signOut();
            try {
                this.$router.push({ name: "home" });
            }
            catch (err) {
                if (err.name !== "NavigationDuplicated")
                    throw err;
            }
        },
        showAdditionalServicesModal() {
            this.$modal.show('additional-services-modal');
        }
    }
};
</script>

<style lang="postcss" scoped>
.sidebar {
  @apply w-1/5 bg-gray-100 flex flex-col;
  min-width: 18.75rem;
}

.sidebar-header {
  @apply w-full bg-primary py-8 px-4;
}

.sidebar-body {
  @apply mt-11 flex-grow;
}

.sidebar-item {
  @apply block pb-10 pl-3 border-l-4 border-gray-300 hover:border-primary hover:border-opacity-60 duration-200;
}

.sidebar-item.active {
  @apply border-primary;
}

.sidebar-additional-service {
  @apply block py-8 px-4 bg-accent text-white;
}

.sidebar-footer {
  @apply sticky bottom-0;
}

.sidebar-signout {
  @apply w-full py-8 px-4 bg-primary text-white;
}
</style>