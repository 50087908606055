import { validatePresenceOf, formatLegalActDate } from '@/shared/utils';
import { buildSimpleContent as buildPersonContent  } from './person';

export const validate = (decision, options) => {
  return validatePresenceOf(decision, ['type', 'date', 'actor'], options);
}

export const buildLiquidationContent = ({ decision, liquidator }) => {
  let line = `Aux termes ${decision?.type} en date du ${formatLegalActDate(decision?.date)}`;
  line = `${line}, ${decision?.actor} les comptes de liquidation`;
  line = `${line}, donné quitus au liquidateur ${buildPersonContent(liquidator)} et prononcé la clôture de liquidation de la société.`;
  return [line];
}

export const buildDissolutionContent = ({ decision, liquidator }) => {
  var lines = [];
  if (decision) {
    var line = `Aux termes ${decision.type} en date du ${formatLegalActDate(decision.date)}`;
    line = `${line}, ${decision.actor} la dissolution anticipée de la société`;

    if (decision.effectiveDate) 
      line = `${line} à compter du ${formatLegalActDate(decision.effectiveDate)}`;
      
    line = `${line}. ${buildPersonContent(liquidator)} a été nommé liquidateur et lui a conféré les pouvoirs les plus étendus.`;

    lines.push(line);  
    lines.push(`Le siège de la liquidation est ${decision.place}, adresse où doit être envoyée la correspondance.`);
  }

  return lines;
};

export const buildTransmissionContent = ({ decision, partner, partnerHeadOffice }) => {
  var line = `Suivant décision du ${formatLegalActDate(decision.date)}`;
  line = `${line}, l'associée unique ${partner.name}, ${partner.legalForm} au capital de ${partner.shareCapital} euros`;
  line = `${line}, sise ${partnerHeadOffice.address}, ${partnerHeadOffice.zipCode} ${partnerHeadOffice.city}, ${partnerHeadOffice.registrationNumber} RCS ${partnerHeadOffice.registrationPlace}`;
  line = `${line}, a décidé la dissolution sans liquidation de la société dans les conditions de l'article 1844-5, al. 3 du Code Civil.`;
  return [line];
};
