import ShareCapitalModel from './share-capital';
import HalfShareCapitalModel from './half-share-capital';
import CompanyNameModel from './company-name';
import CompanyObjectModel from './company-object';
import DurationModel from './duration';
import TransferNewState1Model from './transfer-new-state-1';
import TransferNewState2Model from './transfer-new-state-2';
import TransferOldStateModel from './transfer-old-state';
import TransferModel from './transfer';

export default [
  ShareCapitalModel,
  HalfShareCapitalModel,
  CompanyNameModel,
  DurationModel,
  CompanyObjectModel,
  TransferModel,
  TransferNewState1Model,
  TransferNewState2Model,
  TransferOldStateModel
];