<template>
  <div>
    <PublicationDatePicker
      v-if="legalAd"
      :legal-ad="legalAd"
      @change="changePublishingAt"
    />

    <div v-if="legalAd" class="input mt-6">
      <label for="legalAdName">Nom</label>
      <input id="legalAdName" v-model="name" type="text" />
    </div>

    <div v-if="legalAd" class="flex items-center mt-6">
      <input id="legalAdConfirmed" v-model="confirmed" type="checkbox" class="mr-3" />
      <label class="text-gray-600" for="legalAdConfirmed">Validée ?</label>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import PublicationDatePicker from './publication-date-picker';

export default {
  name: 'LegalAdSettingsForm',
  components: { PublicationDatePicker },
  computed: {
    ...mapState(['legalAd']),
    confirmed: {
      get() { return this.legalAd.confirmed; },
      set(value) { this.changeLegalAd({ changes: { confirmed: value }}); }
    },
    name: {
      get() { return this.legalAd.name; },
      set(value) { this.changeLegalAd({ changes: { name: value }}); }
    }
  },
  methods: {
    ...mapActions(['changeLegalAd']),
    changePublishingAt(newDate) {
      this.changeLegalAd({ changes: { publishingAt: newDate }});
    },
  }
};
</script>
