// Only digits and '+' for phone numbers
const numbersOnly = event => {
  const charCode = event.keyCode;

  if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 43) {
    event.preventDefault();

    return true;
  } else {
    return false;
  }
};

export { numbersOnly };