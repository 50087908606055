<template>
  <div class="text-center pdf-preview-container">
    <t-alert
      v-if="hasError"
      class="my-8"
      level="danger"
    >
      Un mauvais caractère semble s'être glissé dans le texte : {{ glyphError }}
    </t-alert>
    <t-alert
      class="my-4 text-sm font-bold"
      level="notice"
    >
      <div>
        Le fichier PDF s'actualise automatiquement toutes les 30 secondes. Si vous voulez rafraîchir le fichier, cliquez sur le bouton ci-dessous.
      </div>

      <button
      v-if="!isLoading"
      class="px-2 py-2 mt-2 text-sm text-white transition-all hover:bg-opacity-90 bg-primary"
      type="button"
      @click="generate()"
    >
      Mettre à jour le PDF
    </button>
    </t-alert>
    <div class="pdf-preview">
      <iframe
        ref="frame"
        :src="base64Pdf"
        frameborder="0"
        class="pdf-preview-iframe"
      >
      </iframe>
      <div v-if="isLoading" class="flex items-center justify-center pdf-preview-overlay">
        <font-awesome-icon icon="circle-notch" size="3x" spin class="text-white" />
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { getBase64Pdf } from '@/services/legal-ad';

export default {
  name: 'PDFPreview',
  props: {
    legalAd: { type: Object, default: undefined },
  },
  data() {
    return {
      isLoading: true,
      base64Pdf: null,
      hasError: false,
      glyphError: '',
      interval: null,
      isEdited: false
    };
  },
  watch: {
    legalAd: {
      immediate: true,
      handler(newLegalAd) {
        if (newLegalAd) {
          this.isEdited = true;
        }
      }
    }
  },
  created() {
    this.debouncedGenerate = debounce(this.generate, 3000);
  },
  mounted() {
    this.$refs.frame.onload = () => { this.onFrameReady(); };
    this.debouncedGenerate();

    // Init automatic reloading of the PDF preview
    this.interval = setInterval(() => {
      if (this.isEdited) {
        this.isLoading = true;
        this.generate();
      }
    }, 30000);
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    onFrameReady() {
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
    async generate() {
      this.isLoading = true;
      await getBase64Pdf(this.legalAd)
        .then(pdf => {
          this.base64Pdf = pdf;
          this.hasError = false;
          this.isLoading = false;
          this.isEdited = false;
        })
        .catch(error => {
          if (error.response) {
            const errorData = error.response.data;
            if ('glyph' in errorData) {
              this.hasError = true;
              this.glyphError = errorData.glyph;
            }
          }
        });
    }
  },
};
</script>

<style lang="scss">
  @use '@/design/_breakpoints';

  .pdf-preview-container {
    position: sticky;
    top: .625rem;
  }

  @media (max-width: breakpoints.$medium) {
    .pdf-preview-container {
      display: none;
    }
  }
</style>