import api, { authHeaders } from '@/shared/api';
import store from '@/store';
import addSeconds from 'date-fns/addSeconds';

const SESSION_DURATION = 3600 * 24 * 7; // in seconds (should match the one from the API server)

export const signUp = (account, isProfessional = false) => {
  return api.post('/accounts', { account, isProfessional })
  .then(({ data }) => {
    persistLocally(data);
    store.dispatch('signIn', data);
    return data;
  });
};

export const sendResetPasswordInstructions = email => {
  return api.post('/reset_password', { email });
};

export const resetPassword = account => {
  return api.put('/reset_password', { account });
};

export const authenticate = account => {
  return api.post('/accounts/sign_in', { account })
  .then(({ data }) => {
    persistLocally(data);
    store.dispatch('signIn', data);
    return data;
  });
};

export const authenticateFromToken = token => {
  return api.get('/my_account', { headers: authHeaders(token) })
  .then(({ data: { account } }) => {
    persistLocally({ ...account, token });
    store.dispatch('signIn', { ...account, token });
    return account;
  });
};

export const localAuthenticate = () => {
  const token = localStorage.getItem('token');
  const tokenExpiresAt = localStorage.getItem('tokenExpiresAt');
  const account = JSON.parse(localStorage.getItem('account'));

  if (token && tokenExpiresAt >= Date.now() && account)
    store.dispatch('signIn', { ...account, token });
};

export const signOut = () => {
  clear();
  store.dispatch('signOut');
};

const persistLocally = ({ token, ...account }) => {
  const expiredAt = addSeconds(Date.now(), SESSION_DURATION);
  localStorage.setItem('token', token);
  localStorage.setItem('tokenExpiresAt', Number(expiredAt));
  localStorage.setItem('account', JSON.stringify(account));
};

const clear = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('tokenExpiresAt');
  localStorage.removeItem('account');
};

