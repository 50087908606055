<template>
  <div v-if="legalAd">  
    <div class="mb-6 input" @click="clearInput('titleInput')">
      <label for="formTitle">Titre de l'acte</label>
       <textarea-autosize
          class="form-control"
          id="formTitle"
          :placeholder="placeholders.main.title" 
          v-model="titleInput"
          :min-height="30"
        />
    </div>

    <div class="mb-6 input">
      <label for="formText">Votre texte</label>
      <RichTextEditor :body="body" :is-admin="isCurrentAccountAdmin" @change="changeBody" />
    </div>

    <div class="mb-6 input" @click="clearInput('signatureInput')">
      <label for="formSignature">Signature</label>
      <textarea-autosize
        class="form-control"
        id="formSignature"
        :placeholder="placeholders.main.signature"
        v-model="signatureInput"
        :min-height="30"
      />
    </div>

    <slot></slot> 
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import store from '@/store';
import RichTextEditor from '@/components/legal-ads/forms/shared/rich-text-editor'
import { legalAds } from '@/constants';

export default {
  name: 'MainPaneLegalAdForm',
  components: { RichTextEditor },  
  computed: {    
    ...mapState(['legalAd']),
    content() {
      return this.legalAd.content;
    },
    titleInput: {
      get() { return this.content.title; },
      set(value) { this.change('title', value); }       
    },
    signatureInput: {
      get() { return this.content.signature; },
      set(value) { this.change('signature', value); }       
    },
    body() {
      return this.content.body.replace(/\n/g, '<br/>');
    },
    isAdmin() {
      return store.state.account?.admin === true;
    }
  },
  data() {
    return {
      placeholders: legalAds.placeholders,
      titleClick: true,
      bodyClick: true,
      signatureClick: true
    }
  },
  methods: {
    ...mapActions(['changeLegalAdContent']),
    change(attribute, value) {
      this.changeLegalAdContent({ changes: { [attribute]: value } });
    }, 
    changeBody(body) {
      this.change('body', body);
    },
    clearInput(model) {
      if (model.includes('title') && this.titleClick && this.isClearingInput) {
        this[model] = '';
        this.titleClick = false;
      }
      
      if (model.includes('signature') && this.signatureClick && this.isClearingInput) {
        this[model] = '';
        this.signatureClick = false;
      }
    }
  }
}
</script>
