<template>
  <Layout>
    <div class="container mx-auto py-8">
      <div class="flex">
        <div class="flex-1 flex-shrink-0">
          <t-tabs
            v-if="legalAd"
            ref="tabs"
            :tabs="tabs"
            :other-props="{ legalAd }"
            @next="goToPaymentTab"
          />
        </div>
        <div class="w-96 ml-8 pt-24">
          <PreviewToggler :mode="previewMode" @change="setPreviewMode" />
          <component
            :is="previewComponent"
            :legal-ad="legalAd"
          ></component>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as LegalAdService from "@/services/legal-ad";
import * as MyAccountService from "@/services/my-account";
import { buildContent } from "@/shared/pdf/samples";
import Layout from "@/views/layouts/default";
import NewRawLegalForm from "@/components/legal-ads/new/raw";
import PaymentForm from "@/components/payment-form";
import { PreviewToggler } from "@/components/previews";
import PreviewMixin from "@/mixins/preview";

export default {
  name: "NewRawLegalAdPage",
  components: { Layout, PreviewToggler },
  mixins: [PreviewMixin],
  async mounted() {
    this.stopEditingLegalAd();
    var {
      account: { companyLogoUrl, defaultLegalAdContent },
    } = await MyAccountService.show().catch(() => {
      return { account: {} };
    });
    var content = await buildContent(
      companyLogoUrl,
      defaultLegalAdContent,
      this.isCurrentAccountPro
    );
    this.startEditingLegalAd({ content });
  },
  computed: {
    ...mapState(["legalAd"]),
    tabs() {
      return [
        {
          name: "1 - Votre annonce légale",
          tab: NewRawLegalForm,
          type: "legal-ad",
        },
        {
          name: this.isCurrentAccountPro
            ? "2 - Attestation"
            : "2 - Paiement attestation",
          tab: PaymentForm,
          disabled: !this.isValid,
          type: "payment",
        },
      ];
    },
    isValid() {
      return LegalAdService.isValid(this.legalAd);
    },
  },
  methods: {
    ...mapActions(["startEditingLegalAd", "stopEditingLegalAd"]),
    goToPaymentTab() {
      if (!this.isValid) return;
      this.$refs.tabs.selectTab(1);
    },
  },
};
</script>
