<template>
  <Layout>
    <div class="w-full mx-auto px-8">
      <DashboardHeader></DashboardHeader>
      <main class="my-8 flex flex-wrap items-start">
        <div v-if="loading" class="w-full h-96 flex justify-center items-center self-center">
          <font-awesome-icon icon="spinner" size="2x" spin />
        </div>
        <template v-else>
          <DashboardPanel class="xl:mr-8" type="annonces">
            <DataTable
              :columns="['Référence', 'Référence interne',  'Commandée le', 'Montant TTC']"
              :keys="legalAdTableKeys"
              :rows="legalAds"
            ></DataTable>
          </DashboardPanel>
          <DashboardPanel type="devis">
            <DataTable
              :columns="['Référence', 'Référence interne', 'Montant TTC']"
              :keys="quoteTableKeys"
              :rows="quotes"
            ></DataTable>
          </DashboardPanel>
        </template>
      </main>

    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/dashboard-layout';
import DashboardHeader from '@/components/dashboard/header';
import DashboardPanel from '@/components/dashboard/panel';
import DataTable from '@/components/shared/data-table';

export default {
  name: 'DashboardPage',
  components: {
    Layout,
    DashboardHeader,
    DashboardPanel,
    DataTable
  },
  data() {
    return {
      legalAdTableKeys: [
        {name: 'reference', type: 'string'},
        {name: 'altName', type: 'string'},
        {name: 'orderedAt', type: 'date'},
        {name: 'totalAmount', type: 'price'}
      ],
      quoteTableKeys:[
        {name: 'reference', type: 'string'},
        {name: 'altName', type: 'string'},
        {name: 'totalAmount', type: 'price'}
      ]
    };
  },
  computed: {
    legalAds() {
      return this.$store.state.legalAds;
    },
    quotes() {
      return this.$store.state.legalAdDrafts;
    }
  },
  created() {
    this.$store.dispatch('fetchLegalAds');
    this.$store.dispatch('fetchLegalAdDrafts');
  }
};
</script>

<style lang="postcss" scoped>
.btn {
  @apply py-4 px-8;
}
</style>