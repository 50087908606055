import { validatePresenceOf } from '@/shared/utils';

export const validate = opposition => {
  return validatePresenceOf(opposition, ['court']);
};

export const buildContent = ({ company, headOffice, opposition }) => {
  var line = `Les créanciers de la société ${company.name} pourront former oppositions devant`;
  line = `${line} ${opposition.court} de ${headOffice.registrationPlace}`;
  line = `${line} dans les trente jours de la présente publication.`;
  return [line];
};
