const adTypes = [
  {
    key: 'cessation',
    value: "Cessation d'activité",
    subTypes: [
      {
        key: 'dissolution',
        value: 'Dissolution'
      },
      {
        key: 'liquidation',
        value: 'Liquidation'
      },
      {
        key: 'transmission-patrimoine',
        value: 'T.U.P'
      }
    ]
  },
  {
    key: 'cession',
    value: 'Cession',
    subTypes: [
      {
        key: 'cession-parts-sociales',
        value: 'Cession de parts sociales'
      },
      {
        key: 'cession-fond-commerce',
        value: 'Cession de fonds de commerce'
      },
    ]
  },
  {
    key: 'constitution',
    value: 'Constitution',
    subTypes: [
      {
        key: 'asl',
        value: 'ASL'
      },
      {
        key: 'sas',
        value: 'SAS'
      },
      {
        key: 'earl',
        value: 'EARL'
      },
      {
        key: 'eurl',
        value: 'EURL'
      },
      {
        key: 'gaec',
        value: 'GAEC'
      },
      {
        key: 'sarl',
        value: 'SARL'
      },
      {
        key: 'saca',
        value: "SA à conseil d'administration"
      },
      {
        key: 'sacs',
        value: 'SA à conseil de surveillance'
      },
      {
        key: 'sasu',
        value: 'SASU'
      },
      {
        key: 'sc',
        value: 'SC'
      },
      {
        key: 'sccv',
        value: 'SCCV'
      },
      {
        key: 'scea',
        value: 'SCEA'
      },
      {
        key: 'sci',
        value: 'SCI'
      },
      {
        key: 'snc',
        value: 'SNC'
      }
    ]
  },
  {
    key: 'location-gerance',
    value: 'Location gérance',
    subTypes: []
  },
  {
    key: 'commissaire',
    value: 'Modification commissaire aux comptes',
    subTypes: []
  },
  {
    key: 'denomination-sociale',
    value: 'Modification de dénomination sociale',
    subTypes: []
  },
  {
    key: 'organe-direction',
    value: 'Modification des organes de direction',
    subTypes: [
      {
        key: 'president',
        value: 'Président'
      },
      {
        key: 'gerant',
        value: 'Gérant'
      },
      {
        key: 'directeur',
        value: 'Directeur général / Directeur général délégué / Directeur général unique'
      },
      {
        key: 'administrateur',
        value: 'Administrateur'
      }
    ]
  },
  {
    key: 'capital-social',
    value: 'Modification du capital social',
    subTypes: [
      {
        key: 'capital-social-1',
        value: 'Réduction du capital social'
      },
      {
        key: 'capital-social-2',
        value: 'Augmentation du capital social'
      },
    ]
  },
  {
    key: 'objet-social',
    value: "Modification d'objet social",
    subTypes: []
  },
  {
    key: 'moitie-capital-social',
    value: 'Non dissolution',
    subTypes: []
  },
  {
    key: 'transfert-siege',
    value: 'Transfert du siège social',
    subTypes: [
      {
        key: 'transfert-siege-1',
        value: 'Transfert de siège social dans le département'
      },
      {
        key: 'transfert-siege-2',
        value: 'Transfert de siège social hors département (siège de départ)'
      },
      {
        key: 'transfert-siege-3',
        value: "Transfert de siège social hors département (siège d'arrivée)"
      },
    ]
  },
  {
    key: 'transformation',
    value: 'Transformation',
    subTypes: [
      {
        key: 'transformation-1',
        value: 'SARL en SAS'
      },
      {
        key: 'transformation-2',
        value: 'SARL en SCI'
      },
      {
        key: 'transformation-3',
        value: 'SAS en SARL'
      },
      {
        key: 'transformation-4',
        value: 'Autre'
      },
    ]
  },
  {
    key: 'other',
    value: 'Autres',
    subTypes: [
      {
        key: 'other',
        value: 'Modifications diverses'
      },
      {
        key: 'other',
        value: "Modification date d'exercice"
      },
      {
        key: 'changement-nom',
        value: 'Modification patronyme'
      },
      {
        key: 'changement-nom-enfants',
        value: 'Modification du patronyme des enfants'
      },
      {
        key: 'changement-regime-matrimonial',
        value: 'Modification régime matrimonial'
      },
      {
        key: 'declaration-insaisissabilite',
        value: "Déclaration d'insaisissabilité"
      },
      {
        key: 'other',
        value: 'Non dissolution'
      },
      {
        key: 'cession-droit-au-bail',
        value: 'Cession de droit au bail'
      },
      {
        key: 'other',
        value: 'Fusion'
      },
    ]
  },
  {
    key: 'additif',
    value: 'Additif',
    subTypes: []
  },
  {
    key: 'rectificatif',
    value: 'Rectificatif',
    subTypes: []
  }
];

const placeholders = {
  preBlock1: {
    title: 'Nom de votre société',
    body: 'Coordonnées complètes'
  },
  preBlock2: {
    title: "Nom de la société concernée par l'avis",
    body: 'Coordonnées complètes'
  },
  main: {
    title: 'Titre Acte',
    signature: 'Pour avis'
  }
};

export const legalAds = {
  adTypes,
  placeholders
};
