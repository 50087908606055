<template>
  <div :aria-labelledby="title" v-if="legalAd">    
    <div class="flex items-center font-bold" :class="{ 'mb-6': !isEnabled, 'mb-4': isEnabled }" >
      <input type="checkbox" class="mr-3" :id="`has${scope}`" v-model="enabledInput" />
      <label :for="`has${scope}`">{{ title }}</label>       
    </div>
    
    <div class="pl-6" v-if="isEnabled">
      <RcsInput
        :show-input="registrationNumber && !isConstitution"
        @pappers-data="fillForm"
      /> 

      <div class="mb-6 input" @click="clearInput('titleInput')">
        <label :for="toKebabCase(`form-${scope}Title`)">Dénomination sociale</label>
        <textarea-autosize
          class="form-control"
          :id="toKebabCase(`form-${scope}Title`)"
          :placeholder="placeholders[scope].title" 
          v-model="titleInput"
          :min-height="30"
        />
      </div>   

      <div class="input" @click="clearInput('bodyInput')">
        <label :for="toKebabCase(`form-${scope}Body`)">Coordonnées complètes</label>
         <textarea-autosize
          class="form-control"
          :id="toKebabCase(`form-${scope}Body`)"
          rows="3"
          :placeholder="placeholders[scope].body"
          v-model="bodyInput"
          :min-height="30"
        />
      </div>   
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import RcsInput from '@/components/shared/rcs-input';
import { legalAds } from '@/constants';
import { kebabCase } from 'lodash';

export default {
  name: 'HeaderPaneLegalAdForm',
  components: { RcsInput },
  props: {
    title: { type: String, default: undefined },
    scope: { type: String, default: undefined},
    editMode: { type: Boolean, default: false, required: false },
    registrationNumber: { type: Boolean, default: false, required: false }
  },
  computed: {    
    ...mapState(['legalAd']),    
    content() {
      return this.legalAd.content;
    },
    isEnabled() {
      return this.content[this.scope].enabled;
    },
    isConstitution() {
      return this.content.adType && this.content.adType.includes('constitution');
    },
    enabledInput: {
      get() { return this.content[this.scope].enabled; },
      set(value) { this.change('enabled', value); }
    },
    titleInput: {
      get() { return this.content[this.scope].title; },
      set(value) { this.change('title', value); }
    },
    bodyInput: {
      get() { return this.content[this.scope].body; },
      set(value) { this.change('body', value); }
    }
  },
  data() {
    return {
      placeholders: legalAds.placeholders,
      titleClick: true,
      bodyClick: true
    }
  },
  methods: {
    ...mapActions(['changeLegalAd', 'changeLegalAdContent']),
    change(attribute, value) {
      this.changeLegalAdContent({ changes: { [this.scope]: { ...this.content[this.scope], [attribute]: value } } });
    },
    clearInput(model) {
      const isEditing = !this.currentAccount?.admin && !this.editMode;
      if (isEditing && model.includes('title') && this.titleClick) {
        this[model] = '';
        this.titleClick = false;
      }

      if (isEditing && model.includes('body') && this.bodyClick) {
        this[model] = '';
        this.bodyClick = false;
      }
    },
    toKebabCase(scope) {
      return kebabCase(scope);
    },
    // Prefill and format data in forms and disable clearInput function
    fillForm(data) {
      if (data) {
        const siege = data.siege;

        // Change title input format depending on sigle attribute
        if (data.sigle) {
          this.titleInput = `${data.nomEntreprise}, ${data.sigle}`
        } else {
          this.titleInput = data.nomEntreprise
        }

        this.titleClick = false;

        this.bodyInput = `Siège social ${(siege?.adresse_ligne_1 || '...')} ${(siege?.code_postal || '')} ${(siege?.ville || '')}, Capital social ${data.capitalFormate || '0 €'}, ${data.siren} RCS ${(data.greffe || '')}.`;
        this.bodyClick = false;
      }
    }
  }
}
</script>
