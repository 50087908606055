import * as Company from '@/services/legal-ad-form/components/management-modification/company';
import * as HeadOffice from '@/services/legal-ad-form/components/management-modification/head-office';
import * as Decision from '@/services/legal-ad-form/components/management-modification/decision';
import * as Person from '@/services/legal-ad-form/components/management-modification/person';
import * as Mention from '@/services/legal-ad-form/components/management-modification/mention';
import { trimObject } from '@/shared/utils';

export default {
  id: 'administrateur',
  label: 'Administrateur',
  shortLabel: 'Modification des\norganes de direction',
  componentsData: {
    company: { legalForm: '' },
    headOffice: {},
    decision: { type: '', actor: '' },
    replacementAdministratorList: [],
    nominatedAdministratorList: [],
    leavingAdministratorList: [],    
    mention: { registry: 'Mention sera portée au RCS de ... (à compléter)' } 
  },
  component: () => import('@/components/legal-ads/forms/models/management-modification/administrator.vue'),   
  validate(modelData) {
    return trimObject({ 
      company: Company.validate(modelData.company),
      headOffice: HeadOffice.validate(modelData.headOffice),    
      decision: Decision.validate(modelData.decision),
      replacementAdministratorList: Person.validateMany(modelData.replacementAdministratorList, false, { add: ['simpleName'] }),
      nominatedAdministratorList: Person.validateMany(modelData.nominatedAdministratorList, false),
      leavingAdministratorList: Person.validateMany(modelData.leavingAdministratorList, false, { add: ['simpleName'] }),
      mention: Mention.validate(modelData.mention)
    });
  },  
  buildContent(modelData) {
    return [
      Company.buildContent(modelData.company, { withShareCapital: false }),
      HeadOffice.buildContent(modelData.headOffice),     
      Decision.buildAdministratorContent(modelData.decision),
      Person.buildManyContent(modelData.replacementAdministratorList),      
      Person.buildManyContent(modelData.nominatedAdministratorList, 'nomination'),
      Person.buildManyContent(modelData.leavingAdministratorList, 'partant'),
      Mention.buildContent(modelData.mention)
    ].flat().join("\n").replace(/undefined/g, '?');
  }
};