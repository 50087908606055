<template>
  <header class="header">
    <div class="w-full lg:w-2/3 lg:pr-8">
      <div class="box">
        <div class="flex items-center p-2 text-xl font-medium text-white uppercase rounded-full bg-primary">
          <span class="flex items-center justify-center w-10 h-10">
            {{ currentAccountInitials }}
          </span>
        </div>
        <p class="mx-4 font-bold">Bonjour {{ currentAccount.fullName }}, bienvenue sur votre espace pro</p>
        <div class="text-center w-60">
          <router-link :to="{ name: 'myLegalAds' }" class="block px-4 py-2 text-white bg-primary">
            <font-awesome-icon v-if="!counters" icon="spinner" spin />
            <span v-else>{{ unpaidAmount }}</span>
          </router-link>
          <p class="p-0 mt-2 text-xs text-primary">
            <span class="font-bold">Encours</span> <br>
            Montant restant à payer pour les annonces dont nous n'avons pas reçu le paiement
          </p>
        </div>
      </div>
      <div class="actions">
        <div class="action-box bg-primary xl:mr-8">
          <p class="mr-4 font-medium">Mon profil</p>
          <router-link :to="{ name: 'editMyAccount' }" class="btn-transparent">
            Modifier
          </router-link>
        </div>
        <div class="action-box bg-accent">
          <p class="mr-4 font-medium">Mon modèle d'annonce</p>
          <router-link :to="{ name: 'myLegalAdSettings' }" class="btn-transparent">
            Modifier
          </router-link>
        </div>
      </div>
    </div>
    <div class="header-contact">
      <img src="@/assets/backgrounds/dashboard-contact.jpeg" alt="contact journal toulousain - annonces légales">
      <div class="overlay"></div>
      <div class="my-4">
        <p class="text-xl font-bold text-white">Nous contacter</p>
      </div>
      <a
        class="px-16 py-4 text-white bg-primary"
        href="mailto:v.lapeyre@formalites-annonceslegales.fr?subject=Contact espace professionnels"
        target="_blank"
        rel="noopener noreferrer"
      >
        <font-awesome-icon icon="envelope"></font-awesome-icon>
        Par email
      </a>
      <span class="my-4 text-white">Ou</span>
      <a
        class="font-semibold text-white duration-150 hover:underline"
        href="tel:+33762892621"
        target="_blank"
        rel="noopener noreferrer"
      >
        <font-awesome-icon icon="phone"></font-awesome-icon>
        Au 07.62.89.26.21
      </a>
    </div>
  </header>
</template>

<script>
import * as MyAccountService from '@/services/my-account';

export default {
  name: 'DashboardHeader',
  data() {
    return {
      counters: null
    }
  },
  async created() {
    this.counters = await MyAccountService.getStatistics();
  },
  computed: {
    unpaidAmount() {
      return this.numberToCurrency(this.counters?.unpaidAmount);
    },
  }
}

</script>

<style lang="postcss" scoped>
.header {
  @apply w-full flex flex-wrap;
}

.box {
  @apply w-full flex items-center justify-between py-4 px-8 shadow;
}

.header-contact {
  @apply w-full mt-4 lg:mt-0 lg:w-1/3 py-8 border lg:border-t-0 border-primary bg-gray-100 flex flex-col items-center relative;
}

.header-contact img {
  @apply absolute top-0 bottom-0 left-0 right-0 w-full h-full z-0
  object-cover object-center;
}

.header-contact div, .header-contact a, .header-contact span {
  @apply z-10;
}

.header-contact .overlay {
  @apply absolute top-0 bottom-0 left-0 right-0 w-full h-full bg-gradient-to-t
  from-primary to-gray-500 opacity-50;
}

.actions {
  @apply flex items-stretch flex-wrap xl:flex-nowrap mt-8;
}

.action-box {
  @apply flex justify-between items-center p-7 text-white flex-1;
  min-width: 18rem;
}

.btn {
  @apply py-4 px-8;
}

.btn-transparent {
  @apply bg-gray-400 bg-opacity-40 py-4 px-8;
}
</style>