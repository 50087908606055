<template>
  <div class="flex flex-col">
    <div class="py-8 px-10 flex-grow">
      <SignInForm v-if="signInSelected" @on-success="$emit('on-success')" @forgot-password="setForm('forgotPassword')" />
      <SignUpForm v-if="signUpSelected" referrer="sign-up" @on-success="$emit('on-success')" />
      <ForgotPasswordForm v-if="forgotPasswordSelected" @on-success="setForm('signIn')" />
      <ResetPasswordForm v-if="resetPasswordSelected" @on-success="$emit('on-success')" />
    </div>
    <div class="flex">
      <button
        type="button" class="button" :class="{ 'active': signUpSelected }"
        @click="setForm('signUp')"
      >
        Je n'ai pas de compte
      </button>
      <button
        type="button" class="button" :class="{ 'active': signInSelected }"
        @click="setForm('signIn')"
      >
        Je possède un compte
      </button>
    </div>
  </div>
</template>

<script>
import SignInForm from '@/components/authentication/sign-in';
import SignUpForm from '@/components/authentication/sign-up';
import ForgotPasswordForm from '@/components/authentication/forgot-password';
import ResetPasswordForm from '@/components/authentication/reset-password';

export default {
  name: 'KitAuthentication',
  components: { SignInForm, SignUpForm, ForgotPasswordForm, ResetPasswordForm },
  props: {
    defaultForm: { type: String, default: 'signIn' }
  },
  data() {
    return { displayedForm: this.defaultForm };
  },
  computed: {
    signInSelected() {
      return this.displayedForm === 'signIn';
    },
    signUpSelected() {
      return this.displayedForm === 'signUp';
    },
    forgotPasswordSelected() {
      return this.displayedForm === 'forgotPassword';
    },
    resetPasswordSelected() {
      return this.displayedForm === 'resetPassword';
    },
  },
  watch: {
    defaultForm(newValue) {
      this.displayedForm = newValue;
    }
  },
  methods: {
    setForm(name) {
      this.displayedForm = name;
    },
  }
};
</script>

<style lang="scss" scoped>
  .button {
    @apply bg-gray-100 text-primary w-1/2 px-8 py-6 transition-all;

    &.active {
      @apply bg-primary text-white hover:bg-opacity-90;
    }
  }
</style>