<template>
  <div v-if="showInput" class="input mb-6 required">
    <label>Numéro de RCS</label>
    <input
      type="text"
      placeholder="Entrez votre numéro SIREN (ex: 123456789)"
      v-model="companyRegistrationNumber"
      v-on:keypress="checkInput"
      v-on:input="inputChange"
    />
    <div v-if="sirenNumbersOnly" class="text-red-500 text-sm">Chiffres uniquement</div>
  </div>
</template>

<script>
  import * as PappersService from '@/services/pappers'
  import { registrationNumber, regex } from '@/constants';
  import { numbersOnly } from '@/helpers';

  export default {
    name: 'RcsInput',
    props: {
      showInput: { type: Boolean, required: false, default: false }
    },
    data() {
      return {
        companyRegistrationNumber: null,
        registrationNumberMaxLength: registrationNumber.rcsMaxLength,
        rcsPlaceholder: registrationNumber.placeholder,
        sirenNumbersOnly: false
      }
    },
    methods: {
      fetchPappersData() {
        PappersService.fetchCompanyInfo(this.companyRegistrationNumber)
        .then(data => this.onSuccess(data))
        .catch(exception => this.onError(exception.response?.data?.errors))
      },
      onSuccess(data) {
        this.$emit('pappers-data', data);
      },
      checkInput(event) {
        this.sirenNumbersOnly = numbersOnly(event);
      },
      inputChange(event) {
        const value = event.target.value;
        
        if (value.length >= this.registrationNumberMaxLength) {
          this.companyRegistrationNumber = value.replace(regex.spaces, '')
          this.fetchPappersData()
        }
      }
    }
  }
</script>
