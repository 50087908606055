import { validatePresenceOf } from '@/shared/utils';

export const validate = company => {
  return validatePresenceOf(company, ['name', 'legalForm', 'shareCapital']);
};

export const buildContent = company => {
  var lines = [];
  if (company.name) lines.push(`Dénomination : ${company.name}.`);
  if (company.acronym) lines.push(`Sigle : ${company.acronym}.`);
  if (company.legalForm) lines.push(`Forme : ${company.legalForm}.`);
  if (company.shareCapital) lines.push(`Capital social : ${company.shareCapital} euros.`);
  if (company.minShareCapital) lines.push(`Capital variable minimum : ${company.minShareCapital} euros.`);
  return lines;
};