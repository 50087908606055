<template>
  <div class="p-3 mt-10 rounded bg-primary bg-opacity-10">
    <h2 class="font-bold">Adresse de facturation</h2>

    <hr class="my-4">

    <table class="w-full">
      <tbody>
        <tr>
          <th class="font-normal text-left text-gray-600">Nom complet</th>
          <td class="text-right">{{ address.fullname }}</td>
        </tr>
        <tr>
          <th class="font-normal text-left text-gray-600">Nom de l'entreprise</th>
          <td class="text-right">{{ address.company_name }}</td>
        </tr>
        <tr>
          <th class="font-normal text-left text-gray-600">Adresse</th>
          <td class="text-right">{{ address.street }}</td>
        </tr>
         <tr>
          <th class="font-normal text-left text-gray-600">Code postal</th>
          <td class="text-right">{{ address.zip_code }}</td>
        </tr>
         <tr>
          <th class="font-normal text-left text-gray-600">Ville</th>
          <td class="text-right">{{ address.locality }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    name: 'BillingRecap',
    props: {
      address: { type: Object, required: true }
    }
  };
</script>
