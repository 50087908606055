export const validate = () => {
  return {};
};

export const buildContent = meetings => {
  var lines = [];

  if (meetings) {
    if (meetings.condition1)
      lines.push('Dans les conditions statutaires et légales.');

    if (meetings.condition2)
      lines.push('Tout associé peut participer aux assemblées sur justification de son identité et de l\'inscription en compte de ses actions');

    if (meetings.condition3)
      lines.push('Tout actionnaire peut participer aux assemblées : chaque action donne droit à une voix');

    if (meetings.condition4 && meetings.condition4Text)
      lines.push(meetings.condition4Text);

    if (lines.length > 0)
      lines = ['Admission aux assemblées générales et exercice du droit de vote :', ...lines];
  }

  return lines;
};