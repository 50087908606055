<template>
  <layout>
    <div class="container py-8 mx-auto" v-if="legalAd">
      <div class="flex">
        <div class="flex-1 flex-shrink-0">
          <t-tabs :tabs="tabs" :otherProps="{ legalAd }" @next="goToPaymentTab" ref="tabs" />
        </div>
        <div class="pt-24 ml-8 w-96">  
          <preview-toggler @change="setPreviewMode" :mode="previewMode" />
          <component 
            v-bind:is="previewComponent"
            :legalAd="legalAd"
          ></component>
        </div> 
      </div>
    </div>
  </layout>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import * as LegalAdService from '@/services/legal-ad'
import * as LegalAdDraftService from '@/services/legal-ad-draft'
import Layout from '@/views/layouts/default'
import RawLegalForm from '@/components/legal-ads/new/raw'
import ModelLegalForm from '@/components/legal-ads/new/model'
import PaymentForm from '@/components/payment-form'
import { PreviewToggler } from '@/components/previews'
import PreviewMixin from '@/mixins/preview'

export default {
  name: 'EditLegalAdDraftPage',
  mixins: [PreviewMixin],
  components: { Layout, PreviewToggler },
  computed: {
    ...mapState(['legalAd', 'legalAdErrors']),
    rawType() {
      return this.legalAd && (Object.keys(this.legalAd?.model) || {}).length === 1;
    },
    tabs() {
      return [
        { name: '1 - Votre annonce légale', tab: this.rawType ? RawLegalForm : ModelLegalForm, type: 'legal-ad', error: true },
        { name: this.isCurrentAccountPro ? '2 - Attestation' : '2 - Paiement attestation', tab: PaymentForm, disabled: !this.isValid, type: 'payment' },
      ]
    },
    legalAdId() {
      return this.$route.params.legalAdId
    },
    isValid() {
      if (this.rawType)
        return LegalAdService.isValid(this.legalAd, this.isCurrentAccountPro)
      else {
        this.validateLegalAdModel()
        return !this.legalAdErrors
      }
    },
  },
  methods: {
    ...mapActions(['startEditingLegalAd', 'validateLegalAdModel']),
    goToPaymentTab() {
      if (!this.isValid) return
      this.$refs.tabs.selectTab(1)
    }
  },
  watch: {
    legalAdId: {
      immediate: true,
      async handler() {
        var legalAd = await LegalAdDraftService.find(this.legalAdId);
        if (legalAd === null) {
          this.$router.push({ name: 'pageNotFound' });
        } else if (legalAd?.clientEmail?.length > 0) {
          this.$router.push({ name: 'myLegalAdDrafts' });
        } else {
          this.startEditingLegalAd(legalAd);
        }
      }
    }
  }
}
</script>
