import NamingModel from './naming';
import ChildrenNamingModel from './children-naming';
import MarriageModel from './marriage';
import AssetsModel from './assets';

export default [
  NamingModel,
  ChildrenNamingModel,
  MarriageModel,
  AssetsModel
];