<template>
  <div id="app">
    <div class="hidden md:block">
      <router-view />
    </div>

    <div class="md:hidden">
      <MobilePage />
    </div>
  </div>
</template>

<script>
import MobilePage from '@/views/mobile';

export default {
  name: 'App',
  components: { MobilePage },
  watch: {
    isAuthenticated: {
      immediate: true,
      handler(isAuthenticated) {
        if (this.$route.meta.protected && !isAuthenticated)
          this.$router.push({ name: 'home' });
      }
    }
  },
  created() {
    this.$store.dispatch('fetchPageContent');
    this.$store.dispatch('fetchNewspapers');
  }
};
</script>

<style lang="scss">
  @use '@/design/application.scss';
</style>

