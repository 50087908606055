import { formatLegalActDate } from '@/shared/utils';
import { validate as validateAppointments } from './index';
import { validateWithFinancialInformation as validatePerson, validateManyWithFinancialInformation as validatePeople, buildContent as buildPersonContent } from '@/services/legal-ad-form/components/person';

export const validate = ({ appointments, administratorList, chairman, chiefExecutive }) => {
  if (appointments && !appointments.enabled) return {};  
  return {
    appointments: validateAppointments(appointments),
    administratorList: validatePeople(administratorList, true),
    chairman: chairman?.enabled ? validatePerson(chairman) : {},
    chiefExecutive: chiefExecutive?.enabled ? validatePerson(chiefExecutive) : {}
  }
}

export const buildContent = ({ appointments, administratorList, chairman, chiefExecutive }) => {
  var lines = [];

  if (appointments && !appointments.enabled) return lines;
    
  if (administratorList && administratorList.length > 0) {
    lines.push('Ont été nommés en qualité d\'administrateur :');

    administratorList.forEach(member => {
      const title = `Pour une durée de ${member.financialYears} exercices soit jusqu'à l'issue de l'assemblée générale ordinaire statuant sur les comptes de l'exercice clos le ${formatLegalActDate(member.fiscalYearEnd)}`;
      lines = lines.concat(buildPersonContent(member, title))
    });    

    lines.push(`Aux termes du procès-verbal de la première réunion du conseil d'administration en date du ${formatLegalActDate(appointments.date)} à ${appointments.place}, les administrateurs ont décidé de désigner :`);

    lines = lines.concat(
      buildPersonContent(chairman, `Président du conseil d'administration pour une durée de ${chairman.financialYears} exercices soit jusqu'à l'issue de l'assemblée générale ordinaire statuant sur les comptes de l'exercice clos le ${formatLegalActDate(chairman.fiscalYearEnd)}`), 
      buildPersonContent(chiefExecutive, `Directeur général pour une durée de ${chiefExecutive.financialYears} exercices soit jusqu'à l'issue de l'assemblée générale ordinaire statuant sur les comptes de l'exercice clos le ${formatLegalActDate(chiefExecutive.fiscalYearEnd)}`)
    );
  }

  return lines;
}
