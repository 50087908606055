import { CIVILITY, validatePresenceOf } from '@/shared/utils';

export const validateMany = (list, required, validateFunc) => {
  var errors = { list: {} };
  if (list) {
    if (list.length === 0 && required) {
      errors.self = 'Cette liste ne peut pas être vide';
    } else {
      list.forEach(person => errors.list[person.id] = (validateFunc || validate)(person));
    }
  }

  return errors;
};

export const validateManyWithFinancialInformation = (list, required) => {
  return validateMany(list, required, validateWithFinancialInformation);
};

export const validate = (person, extraFields) => {
  var fields = ['type', 'address', 'zipCode', 'city'].concat(extraFields || []);
  switch (person.type) {
    case 'physical':
      fields = fields.concat(['civility', 'firstName', 'lastName']);
      break;
    case 'moral':
      fields = fields.concat(['companyName', 'legalForm', 'registrationPlace', 'registrationNumber', 'permanentRepresentative']);
      break;
  }
  return validatePresenceOf(person, fields);
};

export const validateWithFinancialInformation = person => {
  return validate(person, ['financialYears', 'fiscalYearEnd']);
};

export const buildContent = (person, title) => {
  var lines = [];
  var address = `${person.address} ${person.zipCode} ${person.city}`;
  const fullTitle = title ? `${title} : ` : '';

  switch (person.type) {
    case 'physical':
      lines.push(`${fullTitle}${CIVILITY[person.civility]} ${person.firstName} ${person.lastName} ${address}.`);
      break;
    case 'moral':
      lines.push(`${fullTitle}${person.legalForm} ${person.companyName}`);
      if (person.companyAcronym) lines.push(`Sigle : ${person.companyAcronym}`);
      lines.push(`${address} immatriculée au RCS de ${person.registrationPlace} sous le numéro ${person.registrationNumber}.`);
      if (person.permanentRepresentative)
        lines.push(`Représentant permanent : ${person.permanentRepresentative}`);
      break;
  }

  return lines;
};