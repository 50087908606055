import { validate as validatePerson, validateMany as validatePeople, buildContent as buildPersonContent } from '@/services/legal-ad-form/components/person';

export const validate = ({ boardMemberList, chairman, chiefExecutive }) => {
  return {
    chairman: chairman?.enabled ? validatePerson(chairman) : { enabled: 'Doit être rempli.' },
    chiefExecutive: chiefExecutive?.enabled ? validatePerson(chiefExecutive) : {},
    boardMemberList: validatePeople(boardMemberList),    
  }
}

export const buildContent = ({ boardMemberList, chairman, chiefExecutive }) => {
  var lines = [];

  if (chairman?.enabled) lines.push(buildPersonContent(chairman, 'Président'));
  if (chiefExecutive?.enabled) lines.push(buildPersonContent(chiefExecutive, 'Directeur général'));

  if (boardMemberList.length > 0) {
    lines.push(`En qualité de membres d'un Comité direction :`);

    boardMemberList.forEach(member => {
      lines = lines.concat(buildPersonContent(member))
    });  
  }

  return lines;
}