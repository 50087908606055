import { validatePresenceOf, formatLegalActDate } from '@/shared/utils';

export const validate = act => {
  return validatePresenceOf(act, ['type', 'date']);
};

export const buildContent = ({ act, sie }) => {
  var line = null;
  switch (act.type) {
    case 'private_deed':
      line = `Aux termes d'un acte sous seing privé`;
      break;
    case 'notarial_deed':
      line = `Aux termes d'un acte authentique`;
      break;
    default:
      line = `Aux termes d'un acte`;
      break;
  }

  line = `${line} en date du ${formatLegalActDate(act.date)} à ${act.place}`;
  line = `${line}, enregistré le ${formatLegalActDate(sie.date)} au SIE - ${sie.place} -`;

  line = `${line} ${sie.fileType} ${sie.fileNumber}`;
  line = `${line} / ${sie.referenceType} ${sie.referenceNumber} :`;

  return [line];
};

export const buildEndLeasing = act => {
  var line = null;
  switch (act.type) {
    case 'private_deed':
      line = `Aux termes d'un acte sous seing privé`;
      break;
    case 'notarial_deed':
      line = `Aux termes d'un acte authentique`;
      break;
    default:
      line = `Aux termes d'un acte`;
      break;
  }
  line = `${line} en date du ${formatLegalActDate(act.date)} à ${act.place}`;
  line = `${line}, le contrat de location-gérance.`;
  line = `${line}\nConsenti par :`;
  return [line];
};