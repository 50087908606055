<template>
  <Layout>
    <div class="relative w-full">
      <div class="absolute inset-0 z-10 bg-blue-400">
        <img src="@/assets/backgrounds/auth-laptops.jpg" alt="Sign up image" class="object-cover w-full h-full" />
      </div>

      <div class="relative z-20 py-20 bg-transparent">
        <div class="container flex mx-auto">
          <div class="flex-shrink-0 w-3/5 bg-white">
            <Auth :default-form="defaultForm" class="h-full" @on-success="redirectToDashboard" />
          </div>
          <div class="relative z-10">
            <div class="absolute inset-0 bg-black bg-opacity-20" style="backdrop-filter: blur(25px);">
            </div>
            <div class="relative z-20 px-10 py-8 text-white">
              <AuthText />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/default';
import Auth from '@/components/authentication';
import AuthText from '@/components/authentication/text';

export default {
  name: 'AuthPage',
  components: { Layout, Auth, AuthText },
  computed: {
    defaultForm() {
      return this.$route.meta.form;
    }
  },
  methods: {
    redirectToDashboard() {
      if (this.isCurrentAccountPro) {
        this.$router.push({ name: 'myDashboard' });
      } else {
        this.$router.push({ name: 'myProfile' });
      }
    }
  }
};
</script>
