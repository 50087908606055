import RegistrationModels from '@/services/legal-ad-form/models/registration';
import ModificationModels from '@/services/legal-ad-form/models/modification';
import TransformationModels from '@/services/legal-ad-form/models/transformation';
import ManagementModificationModels from '@/services/legal-ad-form/models/management-modification';
import ClosingModels from '@/services/legal-ad-form/models/closing';
import SellingModels from '@/services/legal-ad-form/models/selling';
import PeopleModels from '@/services/legal-ad-form/models/people';
import AmendingModels from '@/services/legal-ad-form/models/amending';

export const findAllByCategory = category => {
  return category ? models[category.id] : [];
};

export const find = id => {
  return Object.values(models).flat().find(model => model.id === id);
};

export const models = {
  'registration': RegistrationModels,
  'modification': [].concat(ModificationModels, TransformationModels, ManagementModificationModels, SellingModels),
  'closing': ClosingModels,
  'people': PeopleModels,
  'amending': AmendingModels
};

export const categories = [
  {
    id: 'registration',
    label: 'Constitutions',
    completed: true
  },
  {
    id: 'modification',
    label: 'Modifications statutaires',
    completed: true
  },
  {
    id: 'closing',
    label: 'Cessation d\'activité',
    completed: true
  },
  {
    id: 'people',
    label: 'Avis relatif aux personnes',
    completed: true
  },
  {
    id:'amending',
    label: 'Additif / Rectificatif',
    completed: true
  }
];
