import * as Person from '@/services/legal-ad-form/components/people/person';
import * as Modification from '@/services/legal-ad-form/components/people/modification';
import { trimObject } from '@/shared/utils';

export default {
  id: 'changement-nom',
  label: 'Changement de nom',
  shortLabel: 'Changement de nom',
  componentsData: {
    appellant: { type: '', civility: '' },
    modification: {}
  },
  component: () => import('@/components/legal-ads/forms/models/people/naming.vue'),   
  validate(modelData) {
    return trimObject({ 
      appellant: Person.validate(modelData.appellant),    
      modification: Modification.validate(modelData.modification)
    });
  },  
  buildContent(modelData) {
    return [
      Person.buildContent(modelData.appellant),
      Modification.buildNamingContent(modelData.modification),           
    ].flat().join("\n").replace(/undefined/g, '?');
  }
};