<template>
  <div class="p-6">
    <h1 class="text-primary text-xl font-bold mb-2 w-full border-b border-primary">{{ title }}</h1>
    <p class="mb-2">{{ paragraphs[0] }}</p>
    <p class="mb-2">{{ paragraphs[1] }}</p>
    <p class="mb-2">{{ paragraphs[2] }}</p>
    <p>{{ paragraphs[3] }}</p>
  </div>
</template>

<script>
  export default {
    name: 'CustomModal',
    props: {
      title: { type: String, required: false, default: ''},
      paragraphs: { type: Array, required: false}
    }
  };
</script>