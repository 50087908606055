<template>
  <div :class="`${containerClass} grid grid-cols-2 gap-4 mt-4`">
    <div class="col-span-2">
        <t-form-input label="Adresse" :required="true" :error="errors.address">
          <input class="form-control form__input" 
            type="text" 
            name="address"
            v-model="data.address"
            @input="handleChange"
          />
        </t-form-input>
    </div>
    <div class="col-span-1">
      <t-form-input label="Code postal" :required="true" :error="errors.zipCode">
        <input class="form-control form__input" 
          type="text" 
          name="zipCode" 
          v-model="data.zipCode"
          @keydown="handleKeyDown"
        />
      </t-form-input>
    </div>
    <div class="col-span-1">
      <t-form-input label="Ville" :required="true" :error="errors.city">
        <select
          :disabled="cityOptions.length === 0"
          class="cursor-pointer form-control form__input"
          v-model="data.city"
          name="city"
          @change="handleChange"
        >
          <option value="">--- Veuillez choisir ---</option>
          <option
            v-for="(option, index) in cityOptions"
            :key="`city-options-${index}`"
            :value="option.nom"
          >
            {{ `${data.zipCode} - ${option.nom}` }}
          </option>
        </select>
      </t-form-input>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import * as ZipCodeService from '@/services/zipcode';

/**
 * @event input returns an object with 'address', 'zipCode' & 'city' values as string.
 * @example => { address: '32 Rue Pierre-Paul Riquet', zipCode: '31000', city: 'Toulouse' }
 */
export default {
  name: "AddressInputs",
  props: {
    containerClass: { type: String, required: true },
    errors: { type: Object, required: true },
    addressValues: { type: Object, required: true }
  },
  data() {
    return {
      data: {
        zipCode: '',
        city: '',
        address: ''
      },
      cityOptions: [],
      isTyping: false,
      onTypingStarted: null,
      onTypingStopped: null
    }
  },
  watch: {
    addressValues(newVal) {
      this.handleRcsAutocomplete(newVal)
    }
  },
  mounted() {
    this.data.address = this.addressValues.address;
    this.data.zipCode = this.addressValues.zipCode;
    this.setCity('zipCode');

    this.onTypingStarted = debounce(() => {
        this.isTyping = true
        }, 500, {
          leading: true,
          trailing: false
      });
    this.onTypingStopped = debounce((event) => {
        this.isTyping = false;
        this.handleChange(event);
      }, 500, {
        leading: false,
        trailing: true
      });
  },
  methods: {
    handleRcsAutocomplete(data) {
      this.data.address = data?.address;
      this.data.zipCode = data?.zipCode;
      if (data?.zipCode) this.setCity('zipCode');
    },
    handleChange(event) {
      const { value, name } = event.target;
      this.setCity(name);

      this.$emit('input', {
        ...this.data,
        [name]: value
      });
    },
    async setCity(name) {
      if (name === 'zipCode') {
        this.cityOptions = await ZipCodeService.find(this.data.zipCode);
      }
    },
    handleKeyDown(event) {
      this.onTypingStarted();
      this.onTypingStopped(event);
    }
  }
};
</script>
