<template>
  <div class="flex">
    <p class="text-gray-600">
      Fichier .jpg and .png. <br/>
      500 ko taille de fichier maximale.
    </p>
    <div class="ml-8 flex flex-wrap">
      <button type="button" class="px-4 py-3 hover:bg-opacity-90 transition-all bg-primary text-white" @click="openFileDialog">
        <span v-if="image">Remplacer</span>
        <span v-else>Ajouter mon logo</span>
      </button>
      <div v-if="image">
        <span class="mx-3">ou</span>
        <button class="px-4 py-3 hover:bg-opacity-90 transition-all bg-gray-800 text-white" @click="removeImage">Effacer</button>
      </div>
      <input
        ref="image"
        class="hidden"
        type="file"
        accept="image/*"
        @change="changeImage" />
    </div>
  </div>
</template>

<script>
import { inputFileToBase64, numberToHumanSize } from '@/shared/utils';

export default {
  name: 'ImageUploader',
  props: {
    image: {
      default: undefined
    },
    maxsize: {
      type: Number,
      default: 262144
    },
  },
  methods: {
    openFileDialog() {
      this.$refs.image.click();
    },
    async changeImage() {
      const file = this.$refs.image.files[0];
      if (file && file.size < this.maxsize) {
        const image = await inputFileToBase64(file);
        this.$emit('change', image);
      } else {
        alert(`La taille de l'image est trop grande (max: ${numberToHumanSize(this.maxsize)})`);
      }
    },
    removeImage(event) {
      event.preventDefault() & event.stopPropagation();
      this.$emit('change', null);
    },
  }
};
</script>
