<template>
  <Layout>
    <div class="container py-8 mx-auto">
      <div v-if="legalAd" class="flex">
        <div class="flex-1 flex-shrink-0">
          <EditRawLegalForm :legal-ad="legalAd" />
        </div>
        <div class="pt-24 ml-8 w-72">
          <PreviewToggler :mode="previewMode" @change="setPreviewMode" />
          <component
            :is="previewComponent"
            :legal-ad="legalAd"
          ></component>
        </div>
        <div class="pt-24 ml-8 w-80">
          <LegalAdSettingsForm />

          <Cart class="mt-8" :legal-ad="legalAd" :legal-ad-id="legalAdId" :display-button="false" />

          <BillingRecap :address="legalAd.address" />

          <div class="mt-8 text-center">
            <t-submit-button
              type="button"
              :button-state="buttonState"
              :labels="{ default: 'Enregistrer', inProgress: 'En cours', success: 'Enregistrée !', fail: 'Échec' }"
              @click="persist"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import * as LegalAdService from '@/services/legal-ad';
import PreviewMixin from '@/mixins/preview';
import Layout from '@/views/layouts/default';
import { PreviewToggler } from '@/components/previews';
import EditRawLegalForm from '@/components/legal-ads/edit';
import LegalAdSettingsForm from '@/components/legal-ads/edit/settings-form';
import Cart from '@/components/payment-form/cart';
import BillingRecap from '@/views/admin/legal-ads/billing-recap.vue';

export default {
  name: 'AdminEditLegalAdPage',
  components: { Layout, PreviewToggler, EditRawLegalForm, LegalAdSettingsForm, Cart, BillingRecap },
  mixins: [PreviewMixin],
  data() {
    return { buttonState: 'default' };
  },
  computed: {
    ...mapState(['legalAd', 'legalAdErrors']),
    legalAdId() {
      return this.$route.params.legalAdId;
    },
  },
  watch: {
    isCurrentAccountAdmin(newValue) {
      if (!newValue)
        this.$router.push({ name: 'home' });
    },
    legalAdId: {
      immediate: true,
      async handler() {
        const legalAd = await LegalAdService.find(this.legalAdId);

        if (legalAd === null) {
          this.$router.push({ name: 'pageNotFound' });
        } else {
          this.startEditingLegalAd(legalAd);
        }
      }
    }
  },
  methods: {
    ...mapActions(['startEditingLegalAd', 'changeLegalAd']),
    persist() {
      this.buttonState = 'inProgress';
      LegalAdService.update(this.legalAdId, this.legalAd)
      .then(() => this.buttonState = 'success')
      .catch(() => this.buttonState = 'fail');
    },
  }
};
</script>
