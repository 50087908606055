import * as Act from '@/services/legal-ad-form/components/people/act';
import * as Person from '@/services/legal-ad-form/components/people/person';
import * as Address from '@/services/legal-ad-form/components/people/address';
import * as Marriage from '@/services/legal-ad-form/components/people/marriage';
import * as Modification from '@/services/legal-ad-form/components/people/modification';
import { trimObject } from '@/shared/utils';

export default {
  id: 'changement-regime-matrimonial',
  label: 'Changement de régime matrimonial',
  shortLabel: 'Changement de\nrégime matrimonial',
  componentsData: {
    act: {},
    partner1: { type: '', civility: '' },
    partner2: { type: '', civility: '' },
    address: {},
    marriage: { type: '' },
    modification: { newMarriageType: '' }
  },
  component: () => import('@/components/legal-ads/forms/models/people/marriage.vue'),   
  validate(modelData) {
    return trimObject({ 
      act: Act.validate(modelData.act),
      partner1: Person.validate(modelData.partner1, { skip: ['address', 'city', 'zipCode'] }),    
      partner2: Person.validate(modelData.partner2, { skip: ['address', 'city', 'zipCode'] }),    
      address: Address.validate(modelData.address),
      marriage: Marriage.validate(modelData.marriage),
      modification: Modification.validate(modelData.modification, { add: ['newMarriageType'], skip: ['newName'] })
    });
  },  
  buildContent(modelData) {
    return [
      Act.buildContent(modelData.act),
      Person.buildCoupleContent(modelData),
      Address.buildContent(modelData.address),
      Marriage.buildContent(modelData.marriage),
      Modification.buildMarriageContent(modelData.modification),           
    ].flat().join("\n").replace(/undefined/g, '?');
  }
};