export const validateSAS = () => {
  return {};
};

export const validateSA = () => {
  return {};
};

export const buildSASContent = ({ transformation: { assemblyAdmission, shareTransfer, statusText } }) => {
  var lines = [];
  if (assemblyAdmission) lines.push(`Accès aux assemblées et vote : ${assemblyAdmission}.`);
  if (shareTransfer) lines.push(`Transmission des actions : ${shareTransfer}.`);
  if (statusText) lines.push(`${statusText}.`);
  return lines;
};

export const buildSAContent = ({ transformation: { assemblyAdmission, shareTransfer } }) => {
  var lines = [];
  if (assemblyAdmission) lines.push(`Admission aux assemblées générales et exercice du droit de vote : ${assemblyAdmission}.`);
  if (shareTransfer) lines.push(`Transmission des actions : ${shareTransfer}.`);
  return lines;
};