import VueRouter from 'vue-router';
import routes from './routes';
import { requireAuthentication } from './before-actions';

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  requireAuthentication(to, from, next);
});

// Use this to avoid NavigationDuplicated error,
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPushFunction = router.push;

router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPushFunction.call(this, location, onResolve, onReject);
  }

  return originalPushFunction.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err;
    }

    return Promise.reject(err);
  })
};

export default router;
