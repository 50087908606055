export const validate = asl => {
  var errors = {};
  ['name', 'composition', 'role'].forEach(attribute => {
    if (asl && (asl[attribute] || '').trim().length === 0) errors[attribute] = "Doit être rempli.";
  });
  return errors;
};

export const buildContent = asl => {
  var lines = [];
  if (asl) {
    if (asl.name) lines.push(`Dénomination : ${asl.name}.`);
    if (asl.composition) lines.push(`Composition : ${asl.composition}.`);
    if (asl.composition) lines.push(`Elle a pour objet d'assurer : ${asl.role}.`);
  }
  return lines;
};