import { validatePresenceOf, formatLegalActDate } from '@/shared/utils';

export const validate = decision => {
  return validatePresenceOf(decision, ['type', 'date', 'actor']);
};

export const buildSASContent = decision => {
  var lines = [];
  lines.push(`Aux termes ${decision.type} en date du ${formatLegalActDate(decision.date)}, ${decision.actor} de transformer la société en société par actions simplifiée.`);
  return lines;
};

export const buildSAContent = ({ decision, company: { legalForm } }) => {
  var lines = [];
  lines.push(`Aux termes ${decision.type} en date du ${formatLegalActDate(decision.date)}, statuant dans les conditions prévues par la Loi, ${decision.actor} la transformation de la société en SA à Conseil d'administration à compter du même jour, sans création d'un être moral nouveau et adopté le texte des statuts qui régiront désormais la société.`);
  lines.push(`L'objet de la société, sa durée, les dates de son exercice social, son siège social et sa dénomination demeurent inchangés.`);
  lines.push(`Cette transformation rend nécessaire la publication des mentions suivantes :`);
  lines.push(`Forme : La société, précédemment sous forme de ${legalForm}, a adopté celle de SA à Conseil d'administration.`);
  lines.push(`Ont été nommés :`);
  return lines;
};