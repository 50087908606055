import * as Company from '@/services/legal-ad-form/components/management-modification/company';
import * as HeadOffice from '@/services/legal-ad-form/components/management-modification/head-office';
import * as Decision from '@/services/legal-ad-form/components/management-modification/decision';
import * as Person from '@/services/legal-ad-form/components/management-modification/person';
import * as Mention from '@/services/legal-ad-form/components/management-modification/mention';
import { trimObject } from '@/shared/utils';

export default {
  id: 'president',
  label: 'Président',
  shortLabel: 'Président',
  componentsData: {
    company: { legalForm: '' },
    headOffice: {},
    decision: { type: '', actor: '' },
    nominatedChairman: { type: '', civility: '', legalForm: '' },    
    mention: { registry: 'Mention sera portée au RCS de ...(à compléter)' } 
  },
  component: () => import('@/components/legal-ads/forms/models/management-modification/chairman.vue'),   
  validate(modelData) {
    return trimObject({ 
      company: Company.validate(modelData.company),
      headOffice: HeadOffice.validate(modelData.headOffice),    
      decision: Decision.validate(modelData.decision),
      nominatedChairman: Person.validate(modelData.nominatedChairman, { add: ['quality', 'simpleName'] }),      
      mention: Mention.validate(modelData.mention)
    });
  },  
  buildContent(modelData) {
    return [
      Company.buildContent(modelData.company, { withShareCapital: false }),
      HeadOffice.buildContent(modelData.headOffice),     
      Decision.buildChairmanContent(modelData),
      Mention.buildContent(modelData.mention)
    ].flat().join("\n").replace(/undefined/g, '?');
  }
};