import api from '@/shared/api';
import store from '@/store';

export const show = () => {
  return api.get('/my_account').then(({ data }) => data);
};

export const getStatistics = async () => {
  return api.get('/my_account/statistics').then(({ data }) => data);
};

export const update = attributes => {
  return api.put('/my_account', { account: attributes })
  .then(({ data: { account } }) => {
    store.dispatch('updateMyAccount', account);
    return account;
  });
};