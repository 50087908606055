import * as Company from '@/services/legal-ad-form/components/modification/company';
import * as HeadOffice from '@/services/legal-ad-form/components/modification/head-office';
import * as Decision from '@/services/legal-ad-form/components/modification/decision';
import { trimObject } from '@/shared/utils';

export default {
  id: 'moitie-capital-social',
  label: 'Continuation de la société malgré la perte de plus de la moitié du capital social',
  shortLabel: 'Continuation de la\nsociété malgré la\nperte de plus\nde la moitié\ndu capital social',
  componentsData: {
    company: { legalForm: '' },
    headOffice: {},
    decision: { type: '', actor: '' },
  },
  component: () => import('@/components/legal-ads/forms/models/modification/half-share-capital.vue'),
  validate(modelData) {
    return trimObject({ 
      company: Company.validate(modelData.company),
      headOffice: HeadOffice.validate(modelData.headOffice),    
      decision: Decision.validate(modelData.decision),
    });
  },  
  buildContent(modelData) {
    return [
      Company.buildContent(modelData.company, { withShareCapital: false }),
      HeadOffice.buildContent(modelData.headOffice),
      Decision.buildHalfShareCapitalContent(modelData.decision),
    ].flat().join("\n").replace(/undefined/g, '?');
  }
};