<template>
  <div>
    <t-alert v-if="paymentSuccess" class="w-1/2 mx-auto mt-8 text-green-600 bg-green-100">
      <div>
        Paiement réalisé avec succès
      </div>
      <button
        type="button"
        class="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary-300 font-medium mt-4 rounded-lg text-sm px-5 py-2.5 text-center m-auto inline-flex items-center"
        @click="$router.push({ name: 'home' })"
      >
        Retour à l'accueil
      </button>
    </t-alert>

    <div v-else class="w-1/2 mx-auto mt-10">
      <h1 class="mb-8 text-xl font-bold text-primary">
        Adresse de facturation
      </h1>

      <div>
        <div class="grid grid-cols-1 mt-4">
          <div class="col-span-1">
            <div class="input">
              <label for="street">Email</label>
              <input v-model="address.email" type="email" class="cursor-not-allowed" disabled />
            </div>
          </div>
        </div>

        <div class="grid grid-cols-12 gap-4 mt-4">
          <div class="col-span-5">
            <div class="input">
              <label for="fullname">Nom et prénom</label>
              <input v-model="address.fullname" type="text" />
            </div>
          </div>

          <div class="col-span-2 text-center required input">
            <label><b>et/ou</b></label>
          </div>

          <div class="col-span-5">
            <div class="input">
              <label for="companyName">Nom entreprise</label>
              <input v-model="address.companyName" type="text" />
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 mt-4">
          <div class="col-span-1">
            <div class="input required">
              <label for="street">Adresse</label>
              <input v-model="address.street" type="text" />
            </div>
          </div>
        </div>

        <div class="grid grid-cols-3 gap-8 mt-4">
          <div class="col-span-1">
            <div class="input required">
              <label for="zipCode">Code postal</label>
              <input v-model="address.zipCode" type="text" maxlength="5" @keypress="checkInput" />
              <div v-if="numbers" class="text-sm text-red-500">Chiffres uniquement</div>
            </div>
          </div>

            <div class="col-span-2">
              <div class="input required">
                <label for="locality">Ville</label>
                <input v-model="address.locality" type="text" />
              </div>
            </div>
          </div>
        </div>

      <div v-if="showStripeForm">
        <Stripe :info="info" :address="address" @payment-result.once="paymentSuccess = true" />
      </div>
    </div>
  </div>
</template>

<script>
import Stripe  from '@/components/legal-ads/billing/Stripe.vue';
import { numbersOnly } from '@/helpers';

export default {
  name: 'Address',
  components: { Stripe },
  props: {
    info: {
      type: Object,
      default: null,
      required: true,
    }
  },
  data() {
    return {
      numbers: false,
      paymentSuccess: false,
      address: {
        email: null,
        fullname: null,
        companyName: null,
        street: null,
        zipCode: null,
        locality: null
      }
    };
  },
  computed: {
    showStripeForm() {
      return this.address.email && (this.address.fullname || this.address.companyName) && this.address.street && this.address.zipCode && this.address.locality;
    },
  },
  mounted() {
    this.address.email = this.info.clientEmail;
  },
  methods: {
    checkInput(event) {
      this.numbers = numbersOnly(event);
    },
  }
};
</script>
