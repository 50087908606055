import Vue from 'vue';
import { mapState } from 'vuex';
import store from '@/store';
import { isBlank, isMobile } from '@/shared/utils';
import { IS_STAGING } from '@/shared/config';

Vue.mixin({
  computed: {
    ...mapState(['isAuthenticated']),
    currentAccount() {
      return store.state.account;
    },
    currentAccountInitials() {
      return this.currentAccount.fullName.split(' ').map(word => word.charAt(0)).join('');
    },
    isMobile() {
      return isMobile();
    },
    isStaging() {
      return IS_STAGING;
    },
    isCurrentAccountAdmin() {
      return store.state.account?.admin === true;
    },
    isCurrentAccountPro() {
      return store.state.account?.pro === true;
    },
    currencyFormatter() {
      return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });
    },
    dateFormatter() {
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      return new Intl.DateTimeFormat('fr-FR', { style: 'long', ...options });
    },
    loading() {
      return store.state.loading;
    },
    initAddressValues() {
      return {
        address: this.addressInput,
        zipCode: this.zipCodeInput,
        city: this.cityInput
      };
    },
    proHasLegalAdModel() {
      return Object.keys(this.currentAccount?.defaultLegalAdContent || {}).length > 0
    },
    isClearingInput() {
      return  !this.$route.name.includes('edit') &&
              !this.isAdmin &&
              !this.$store.state.touched &&
              !this.proHasLegalAdModel
    }
  }, 
  methods: {
    numberToCurrency(number) {
      return this.currencyFormatter.format(number);
    },
    formatDate(date) {
      return this.dateFormatter.format(date);
    },
    isBlank(value) {
      return isBlank(value)
    },
    pageContent(key) {
      return store.state.pageContent[key] || key;
    },
    handleAddressChange(event) {
      this.zipCodeInput = event.zipCode;
      this.cityInput = event.city;
      this.addressInput = event.address;
    }
  }
});
