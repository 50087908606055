<template>
  <Layout>
    <div class="container mx-auto py-8 px-8">
      <h1 class="text-primary text-xl font-bold">
        Édition de mon compte
      </h1>

      <EditMyAccountForm class="mt-8" />
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/default';
import EditMyAccountForm from '@/components/my-account/edit';

export default {
  name: 'EditMyAccountPage',
  components: { Layout, EditMyAccountForm },
};
</script>
