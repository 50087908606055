import { formatLegalActDate } from '@/shared/utils';
import { validate as validatePerson, buildContent as buildPersonContent } from '@/services/legal-ad-form/components/person';

export const validate = ({ mainAuditor, alternateAuditor }) => {
  return {
    mainAuditor: mainAuditor?.enabled ? validatePerson(mainAuditor) : {},
    alternateAuditor: alternateAuditor?.enabled ? validatePerson(alternateAuditor) : {}
  }
}

export const buildContent = ({ auditorInformation, mainAuditor, alternateAuditor })  => {
  var lines = [];
  
  if (mainAuditor?.enabled || alternateAuditor?.enabled)
    lines.push(`Les commissaires aux comptes ont été nommés pour une durée de ${auditorInformation.financialYears} exercices, soit jusqu'à l'assemblée ordinaire statuant sur les comptes de l'exercice clos le ${formatLegalActDate(auditorInformation.fiscalYearEnd)}.`);

  if (mainAuditor?.enabled) lines = lines.concat(buildPersonContent(mainAuditor, 'Commissaire aux Comptes titulaire'));
  if (alternateAuditor?.enabled) lines = lines.concat(buildPersonContent(alternateAuditor, 'Commissaire aux Comptes suppléant'));

  return lines;
}