<template>
  <div
    class="h-20 text-white"
    :class="{
      'bg-red-600': isCurrentAccountAdmin,
      'bg-primary ': !isCurrentAccountAdmin,
    }"
  >
    <div class="container flex items-center h-full px-4 md:px-6 md:mx-auto">
      <router-link :to="{ name: 'home' }">
        <img src="@/assets/logo.svg" alt="Logo Journal Toulousain" />
      </router-link>

      <div class="hidden ml-auto md:block">
        <Account v-if="isAuthenticated" />

        <div
          v-else
          class="flex overflow-hidden border border-gray-100 rounded"
        >
          <router-link
            :to="{ name: 'signIn' }"
            class="px-5 py-3 bg-gray-100 text-primary hover:bg-opacity-95"
          >
            Me connecter à mon espace PRO
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Account from "./account";

export default {
  name: "KitHeader",
  components: { Account },
};
</script>
