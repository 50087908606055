import { validatePresenceOf, formatLegalActDate } from '@/shared/utils';

export const validate = (cession, options) => {
  return validatePresenceOf(
    cession,
    ['amount', 'activity', 'address', 'zipCode', 'city', 'date', 'objections'],
    options
  );
};

export const buildContent = ({ cession }) => {
  var lines = [];

  var line = `Moyennant le prix de ${cession.amount} euros son fonds de commerce de ${cession.activity}`;
  line = `${line} exploité ${cession.address}, ${cession.zipCode} ${cession.city}.`;
  lines.push(line);

  lines.push(`Entrée en jouissance au ${formatLegalActDate(cession.date)}.`);

  if (cession.objections.length > 0) lines.push(cession.objections);

  return lines;
};

export const buildLeaseholdContent = ({ cession }) => {
  var lines = [];

  lines.push(`Le droit au bail portant sur des locaux sis ${cession.address}, ${cession.zipCode} ${cession.city}.`);
  lines.push(`Moyennant le prix de ${cession.amount} euros.`);
  lines.push(`La prise de possession a été fixée au ${formatLegalActDate(cession.date)}.`);

  if (cession.objections.length > 0) lines.push(cession.objections);

  return lines;
};