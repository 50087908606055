<template>
  <Layout>
    <div class="container mx-auto">
      <div class="flex items-center py-12 mx-12">
        <h1 class="text-primary text-3xl font-extrabold flex-shrink-0" v-html="pageContent('homepage.hero.title')" />
        <p class="ml-24 text-primary" v-html="pageContent('homepage.hero.text')" />
      </div>
    </div>

    <div class="relative">
      <div class="absolute inset-0 z-10 flex">
        <div class="bg-gray-200 w-1/2 h-full">
          <img src="@/assets/backgrounds/new-legal-ad-typewriter.jpg" alt="Nouvelle annonce image 1" class="h-full w-full object-cover" />
        </div>
        <div class="bg-gray-200 w-1/2 h-full">
          <img src="@/assets/backgrounds/new-legal-ad-laptop.jpg" alt="Nouvelle annonce image 2" class="h-full w-full object-cover" />
        </div>
      </div>
      <div class="relative container mx-auto h-128 flex z-20 text-white">
        <router-link
          tag="div"
          :to="{ name: 'newRawLegalAd' }"
          class="w-1/2 h-full flex items-center justify-center cursor-pointer"
        >
          <div class="w-96">
            <div class="flex h-40 px-8 items-center bg-primary">
              <h3 class="font-bold text-3xl" v-html="pageContent('newLegalAd.cta.left.title')" />
            </div>
            <div class="bg-gray-100 px-8 py-4 text-primary font-semibold hover:bg-gray-200 transition-all" v-html="pageContent('newLegalAd.cta.left.action')" />
          </div>
        </router-link>

        <router-link
          tag="div"
          :to="{ name: 'newModelLegalAd' }"
          class="w-1/2 h-full flex items-center justify-center cursor-pointer"
        >
          <div class="w-96 bg-white">
            <div class="flex h-40 px-8 items-center bg-white text-primary">
              <h3 class="font-bold text-3xl" v-html="pageContent('newLegalAd.cta.right.title')" />
            </div>
            <div class="bg-primary px-8 py-4 text-white font-semibold hover:bg-opacity-90 transition-all" v-html="pageContent('newLegalAd.cta.right.action')" />
          </div>
        </router-link>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/default';

export default {
  name: 'NewLegalAdPage',
  components: { Layout },
};
</script>
