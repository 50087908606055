import * as Company from '@/services/legal-ad-form/components/transformation/company';
import * as HeadOffice from '@/services/legal-ad-form/components/transformation/head-office';
import * as Decision from '@/services/legal-ad-form/components/transformation/decision';
import * as Person from '@/services/legal-ad-form/components/transformation/person';
import * as Transformation from '@/services/legal-ad-form/components/transformation';
import * as Mention from '@/services/legal-ad-form/components/transformation/mention';
import { trimObject } from '@/shared/utils';

export default {
  id: 'transformation-sarl-en-sa',
  label: 'Transformation de société : Transformation d\'une SARL en SA',
  shortLabel: 'Transformation\nde société :\ntransformation\nd\'une SARL en SA',
  componentsData: {
    company: { legalForm: '' },
    headOffice: {},
    decision: {},
    transformation: {},
    chairmanList: [],
    chiefExecutiveList: [],
    representativeChiefExecutiveList: [],
    administratorList: [],
    mainAuditorList: [],
    alternateAuditorList: [],
    mention: { registry: 'Mention sera portée au RCS de ...(à compléter)' } 
  },
  component: () => import('@/components/legal-ads/forms/models/transformation/sa.vue'),   
  validate(modelData) {
    return trimObject({ 
      company: Company.validate(modelData.company),
      headOffice: HeadOffice.validate(modelData.headOffice),    
      decision: Decision.validate(modelData.decision),
      chairmanList: Person.validateMany(modelData.chairmanList, false),
      chiefExecutiveList: Person.validateMany(modelData.chiefExecutiveList, false),
      representativeChiefExecutiveList: Person.validateMany(modelData.representativeChiefExecutiveList, false),      
      administratorList: Person.validateMany(modelData.administratorList, false),      
      mainAuditorList: Person.validateMany(modelData.mainAuditorList, false),
      alternateAuditorList: Person.validateMany(modelData.alternateAuditorList, false),
      transformation: Transformation.validateSA(modelData.transformation),
      mention: Mention.validate(modelData.mention)
    });
  },  
  buildContent(modelData) {
    return [
      Company.buildContent(modelData.company, { withShareCapital: false }),
      HeadOffice.buildContent(modelData.headOffice),     
      Decision.buildSAContent(modelData),
      Person.buildManyContent(modelData.chairmanList, 'En qualité de président du conseil d\'administration'),      
      Person.buildManyContent(modelData.chiefExecutiveList, 'En qualité de directeur général'),
      Person.buildManyContent(modelData.representativeChiefExecutiveList, 'En qualité de directeur général délégué'),
      Person.buildManyContent(modelData.administratorList, 'En qualité d\'administrateur'),
      Person.buildManyContent(modelData.mainAuditorList, 'En qualité de dommissaire aux comptes titulaire'),
      Person.buildManyContent(modelData.alternateAuditorList, 'Commissaire aux comptes suppléant'),
      Transformation.buildSAContent(modelData),
      Mention.buildContent(modelData.mention)
    ].flat().join("\n").replace(/undefined/g, '?');
  }
};