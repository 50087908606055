<template>
  <div>
    <h1 class="text-primary text-lg font-extrabold flex">
      <span class="border-b border-yellow-300 pb-4">Se connecter</span>
    </h1>

    <t-alert v-if="hasError" class="mt-8">
      Votre email et/ou mot de passe sont invalides.
    </t-alert>

    <form class="mt-12" @submit.prevent="signIn">
      <div class="mb-10 input">
        <label for="auth-email">Adresse email</label>
        <input id="auth-email" v-model="account.email" type="text" />
      </div>

      <div class="mb-10 input">
        <label for="auth-password">Mot de passe</label>
        <input id="auth-password" v-model="account.password" type="password" />
        <div class="hint">
          <button
            type="button"
            class=" hover:text-gray-900 font-semibold"
            @click="() => $emit('forgot-password')"
          >
            Mot de passe oublié ?
          </button>
        </div>
      </div>

      <div class="mt-12 text-right">
        <t-submit-button
          :button-state="buttonState"
          :labels="{ default: 'Se connecter', inProgress: 'Connexion', success: 'Authentifié(e) !', fail: 'Échec' }"
        />
      </div>
    </form>
  </div>
</template>

<script>
import * as AuthService from '@/services/authentication';

export default {
  name: 'AuthSignIn',
  data() {
    return {
      hasError: false,
      account: { email: null, password: null },
      buttonState: 'default'
    };
  },
  methods: {
    signIn(event) {
      event.preventDefault();
      this.buttonState = 'inProgress';
      this.hasError = false;
      AuthService.authenticate(this.account)
      .then(account => this.onSuccess(account))
      .catch(() => this.onError())
      .then(() => this.buttonState = 'default');
    },
    onSuccess() {
      this.account = { email: null, password: null };
      this.$emit('on-success');
    },
    onError() {
      this.hasError = true;
    },
  }
};
</script>
