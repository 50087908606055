import * as Company from '@/services/legal-ad-form/components/closing/company';
import * as HeadOffice from '@/services/legal-ad-form/components/closing/head-office';
import * as Decision from '@/services/legal-ad-form/components/closing/decision';
import * as Person from '@/services/legal-ad-form/components/closing/person';
import * as Mention from '@/services/legal-ad-form/components/closing/mention';
import { trimObject } from '@/shared/utils';

export default {
  id: 'liquidation',
  label: 'Clôture de liquidation',
  shortLabel: 'Clôture de\nliquidation',
  componentsData: {
    company: { legalForm: '' },
    headOffice: {},
    decision: { type: '', actor: '' },
    liquidator: { type: '' },    
    mention: { registry: 'La société sera radiée du RCS du Toulouse' } 
  },
  component: () => import('@/components/legal-ads/forms/models/closing/liquidation.vue'),   
  validate(modelData) {
    return trimObject({ 
      company: Company.validate(modelData.company),
      headOffice: HeadOffice.validate(modelData.headOffice),    
      decision: Decision.validate(modelData.decision),
      liquidator: Person.validate(modelData.liquidator),
      mention: Mention.validate(modelData.mention)
    });
  },  
  buildContent(modelData) {
    return [
      Company.buildContent(modelData.company, { withShareCapital: false }),
      HeadOffice.buildContent(modelData.headOffice),     
      Decision.buildLiquidationContent(modelData),      
      Mention.buildContent(modelData.mention)
    ].flat().join("\n").replace(/undefined/g, '?');
  }
};