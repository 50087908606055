import ASLModel from './asl';
import EARLModel from './earl';
import EURLModel from './eurl';
import GAECModel from './gaec';
import SARLModel from './sarl';
import SACAModel from './saca';
import SACSModel from './sacs';
import SASModel from './sas';
import SASUModel from './sasu';
import SCModel from './sc';
import SCCVModel from './sccv';
import SCEAModel from './scea';
import SCIModel from './sci';
import SNCModel from './snc';

export default [
  ASLModel,
  EARLModel,
  EURLModel,
  GAECModel,
  SARLModel,
  SACAModel,
  SACSModel,
  SASModel,
  SASUModel,
  SCModel,
  SCCVModel,
  SCEAModel,
  SCIModel,
  SNCModel
];