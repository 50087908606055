<template>
  <div class="flex flex-col">
    <div class="py-8 flex-grow">
      <SignInForm
        v-if="signInSelected"
        @on-success="$emit('on-success')"
        @forgot-password="setForm('forgotPassword')"
      />
      <SignUpForm
        v-if="signUpSelected"
        title="Complétez les champs ci-dessous pour accèder au règlement et obtenir votre attestation de parution"
        :button-labels="{
          default: 'Valider',
          inProgress: 'En cours...',
          success: 'Parfait',
          fail: 'Échec',
        }"
        @on-success="$emit('on-success')"
      />

      <ForgotPasswordForm
        v-if="forgotPasswordSelected"
        @on-success="setForm('signIn')"
      />
      <ResetPasswordForm
        v-if="resetPasswordSelected"
        @on-success="$emit('on-success')"
      />
    </div>
    <div class="flex">
      <button
        type="button"
        class="ml-auto text-gray-500 underline hover:text-gray-900"
        :class="{ hidden: signUpSelected }"
        @click="setForm('signUp')"
      >
        Je n'ai jamais publié une annonce sur le JournalToulousain
      </button>
      <button
        type="button"
        class="ml-auto text-gray-500 underline hover:text-gray-900"
        :class="{ hidden: signInSelected }"
        @click="setForm('signIn')"
      >
        J'ai déjà publié une annonce sur le JournalToulousain
      </button>
    </div>
  </div>
</template>

<script>
import SignInForm from '@/components/authentication/sign-in';
import SignUpForm from '@/components/authentication/sign-up';
import ForgotPasswordForm from '@/components/authentication/forgot-password';
import ResetPasswordForm from '@/components/authentication/reset-password';

export default {
  name: 'AuthenticationForm',
  components: { SignInForm, SignUpForm, ForgotPasswordForm, ResetPasswordForm },
  props: {
    defaultForm: { type: String, default: 'signUp' },
  },
  data() {
    return { displayedForm: this.defaultForm };
  },
  computed: {
    signInSelected() {
      return this.displayedForm === 'signIn';
    },
    signUpSelected() {
      return this.displayedForm === 'signUp';
    },
    forgotPasswordSelected() {
      return this.displayedForm === 'forgotPassword';
    },
    resetPasswordSelected() {
      return this.displayedForm === 'resetPassword';
    },
  },
  watch: {
    defaultForm(newValue) {
      this.displayedForm = newValue;
    },
  },
  methods: {
    setForm(name) {
      this.displayedForm = name;
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  @apply bg-gray-100 text-primary w-1/2 px-8 py-6 transition-all;

  &.active {
    @apply bg-primary text-white hover:bg-opacity-90;
  }
}
</style>