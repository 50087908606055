<template>
  <div class="mx-8 mt-8">
    <Cart class="mt-8" :display-button="false" :legal-ad="legalAd" />
    <BillingAddressForm
      v-if="currentAccount"
      :encoded-legal-ad-id="encodedLegalAdId"
      class="mt-8"
      @confirm-billing-address="(event) => billing = event"
    />
    <component :is="formComponent" v-if="currentAccount && displayStripeForm" class="mt-8" @send-payment-link="handlePaymentLink"></component>
    <AuthForm v-if="!currentAccount" class="mt-4" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Cart from './cart';
import BillingAddressForm from './billing-address-form';
import ProForm from './pro-form';
import StripeForm from './stripe-form';
import AuthForm from './authentication-form';

export default {
  name: 'PaymentForm',
  components: { Cart, BillingAddressForm, AuthForm },
  data() {
    return {
      billing: false,
      encodedLegalAdId: null
    };
  },
  computed: {
    ...mapState(['legalAd']),
    formComponent() {
      return this.isCurrentAccountPro ? ProForm : StripeForm;
    },
    displayStripeForm() {
      let displayForm = false;

      if ((this.isCurrentAccountPro && !this.billing) || (!this.isCurrentAccountPro && !this.billing)) {
        displayForm = true;
      }

      return displayForm;
    }
  },
  methods: {
    handlePaymentLink(paymentLink) {
      this.encodedLegalAdId = paymentLink.split('/').pop();
    }
  },
};
</script>
