<template>
  <div class="input">
    <label for="formTitle">Date de publication</label>
    <vc-date-picker
      v-model="date"
      :min-date="nextOpenDay"
      :disabled-dates='{ weekdays: [1, 7] }'
      locale="fr"
    />
  </div>
</template>

<script>
import { nextOpenDay } from '@/shared/utils';

export default {
  name: 'PublicationDatePicker',
  props: {
    legalAd: {
      type: Object,
      default: null
    },
  },
  computed: {
    nextOpenDay() {
      return nextOpenDay(true);
    },
    date: {
      get() { return this.legalAd.publishingAt ? new Date(this.legalAd.publishingAt) : new Date(this.nextOpenDay()); },
      set(value) { this.$emit('change', value); }
    },
  }
};
</script>
