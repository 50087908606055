<template>
  <Layout>
    <div class="flex flex-col justify-center mt-10 mb-20">
      <h1 class="mb-8 text-2xl font-bold text-center text-primary">
        Paiement de votre annonce
      </h1>

      <t-alert v-if="alreadyPaid" class="w-1/2 mx-auto mt-8">
        <div>
          Cette annonce n'existe pas ou a déjà été payée
        </div>
        <button
          type="button"
          class="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary-300 font-medium mt-4 rounded-lg text-sm px-5 py-2.5 text-center m-auto inline-flex items-center"
          @click="$router.push({ name: 'home' })"
        >
          Retour à l'accueil
        </button>
      </t-alert>

      <button
        v-if="!billingLoaded"
        disabled type="button" class="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center m-auto mt-20 inline-flex items-center w-2/12 cursor-not-allowed"
      >
        <Loader />
        Chargement...
      </button>

      <div v-if="billingLoaded && !alreadyPaid">
        <Info :info="info" />
        <Address :info="info" />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/default';
import { Info, Address } from '@/components/legal-ads/billing';
import Loader from '@/components/shared/loader';
import * as ClientBillingService from '@/services/client-billing-service';

export default {
  name: 'BillingPage',
  components: { Layout, Info, Address, Loader },
  data() {
    return {
      info: {},
      billingLoaded: false,
      alreadyPaid: false
    };
  },
  mounted() {
    this.fetchLegalAd();
  },
  methods: {
    async fetchLegalAd() {
      const encodedLegalAdId = this.$route.params.legalAdId;

      if (encodedLegalAdId) {
        ClientBillingService.fetchLegalAd(encodedLegalAdId)
        .then((response) => {
          if (response.data && !response.data.order.paid) {
            this.info = response.data;
          } else {
            this.alreadyPaid = true;
          }
          this.billingLoaded = true;
        });
      }
    }
  }
};
</script>
