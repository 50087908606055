<template>
  <div class="p-6">
    <h1 class="flex pb-4 text-2xl font-extrabold border-b border-yellow-300 text-primary">
      Facturation client
    </h1>

    <t-alert class="mt-5">
      <p>Votre client recevra un lien de paiement par mail et il renseignera ses informations de facturation lors du règlement.</p>
      <p class="mt-4 mb-4 text-lg font-extrabold">Merci de renseigner la date de parution souhaitée.</p>
      <p>
        Si vous souhaitez décaler cette date de publication vous pouvez la modifier dès à présent en cliquant
        <router-link
          :to="{ name: 'editLegalAdDraft', params: { legalAdId: legalAdId } }"
          class="font-extrabold"
        >
          <span>ici</span>
        </router-link>
      </p>
      <p>* Attention cette date est conditionnée au paiement de votre client.</p>

      <p class="mt-2 font-bold"> Une fois le lien envoyé, vous ne pourrez plus modifier ce devis.</p>
    </t-alert>

    <form class="mt-8" @submit.prevent="sendPaymentLinkToClient">
      <div class="grid grid-cols-1 mt-8">
        <div class="col-span-1">
          <div class="input">
            <label for="fullname">Email client</label>
            <input v-model="clientEmail" type="email" />
          </div>
        </div>
      </div>

      <button type="submit" class="mt-5 btn btn-success" :class="{ 'cursor-not-allowed': !clientEmail }">
        Envoyer
      </button>
    </form>
  </div>
</template>

<script>
  import * as ClientBillingService from '@/services/client-billing-service';

  export default {
    name: 'ClientBillingModal',
    props: {
      encodedId: {
        type: String,
        required: true,
        default: ''
      },
      legalAdId: {
        type: Number,
        required: true,
        default: null
      }
    },
    data() {
      return {
        clientEmail: null,
        error: false
      };
    },
    methods: {
      async sendPaymentLinkToClient() {
        if (this.clientEmail && this.encodedId) {
          ClientBillingService.sendPaymentLink(this.clientEmail, this.encodedId)
            .then((status) => {
              if (status === 204) {
                this.$emit('modal-closed');
                this.$modal.hide('client-billing-modal');
              }
            });
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .btn {
    @apply px-4 py-2 text-xs rounded rounded-xl transition-all;
    &.btn-success {
      @apply bg-success text-white hover:bg-opacity-90 w-1/3;
    }
  }
</style>
