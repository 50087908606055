import HomePage from '@/views/home';
import AuthPage from '@/views/auth';
import DashboardPage from '@/views/dashboard';

export default [
  {
    name: 'home',
    path: '/',
    component: HomePage,
  },
  {
    name: 'signIn',
    path: '/se-connecter',
    component: AuthPage,
    meta: {
      form: 'signIn',
      strictlyUnprotected: true
    }
  },
  {
    name: 'signUp',
    path: '/creer-compte',
    component: AuthPage,
    meta: {
      form: 'signUp',
      strictlyUnprotected: true
    }
  },
  {
    name: 'resetPassword',
    path: '/nouveau-mot-de-passe/:resetPasswordToken',
    component: AuthPage,
    meta: {
      form: 'resetPassword',
      strictlyUnprotected: true
    }
  },
  {
    name: 'myDashboard',
    path: '/dashboard',
    component: DashboardPage,
    meta: {
      protected: true,
      pro: true
    }
  },
  {
    path: '*',
    name: 'pageNotFound',
    component: () =>
      import('@/views/not-found.vue'),
  },
];