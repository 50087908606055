import { validatePresenceOf, formatLegalActDate } from '@/shared/utils';
import { buildSimpleContent as buildPersonContent  } from './person';

export const validate = (decision, options) => {
  return validatePresenceOf(decision, ['type', 'date', 'actor'], options);
};

export const buildAdministratorContent = decision => {
  var line = `Aux termes ${decision.type} en date du ${formatLegalActDate(decision.date)}`;
  if (decision.effectiveDate)
    line = `${line}, à compter du ${formatLegalActDate(decision.effectiveDate)}`;
  line = `${line}, ${decision.actor} acte de la modification d'administrateur :`;
  return [line];
};

export const buildAuditorContent = decision => {
  var line = `Aux termes ${decision.type} en date du ${formatLegalActDate(decision.date)}`;
  if (decision.effectiveDate)
    line = `${line}, à compter du ${formatLegalActDate(decision.effectiveDate)}`;
  line = `${line}, ${decision.actor} acte de la modification de commissaire aux comptes :`;
  return [line];
};

export const buildChiefExecutiveContent = decision => {
  var line = `Aux d'une décision en date du ${formatLegalActDate(decision.date)}`;
  if (decision.effectiveDate)
    line = `${line}, à compter du ${formatLegalActDate(decision.effectiveDate)}`;
  line = `${line}, ${decision.actor} acte de la modification de la direction :`;
  return [line];
};

export const buildOwnerContent = decision => {
  var line = `Aux termes ${decision.type} en date du ${formatLegalActDate(decision.date)}`;
  if (decision.effectiveDate)
    line = `${line}, à compter du ${formatLegalActDate(decision.effectiveDate)}`;
  line = `${line}, ${decision.actor} acte de la modification de la gérance de la société :`;
  return [line];
};

export const buildChairmanContent = ({ decision, nominatedChairman }) => {
  var line = `Aux termes ${decision.type} en date du ${formatLegalActDate(decision.date)}`;
  if (decision.effectiveDate)
    line = `${line}, à compter du ${formatLegalActDate(decision.effectiveDate)}`;
  line = `${line}, ${decision.actor} de nommer en qualité de ${nominatedChairman.quality}`;
  line = `${line}, ${buildPersonContent(nominatedChairman)}`;
  return [line];
};
