import * as Act from '@/services/legal-ad-form/components/act';
import * as Company from '@/services/legal-ad-form/components/company';
import * as HeadOffice from '@/services/legal-ad-form/components/head-office';
import * as GeneralMeetings from '@/services/legal-ad-form/components/general-meetings';
import * as Appointments from '@/services/legal-ad-form/components/appointments/sacs';
import * as AuditorInformation from '@/services/legal-ad-form/components/auditor-information';
import { trimObject } from '@/shared/utils';

export default {
  id: 'sacs',
  label: 'Avis de constitution SA à conseil de surveillance',
  shortLabel: 'Avis de constitution',
  componentsData: {
    act: { type: '' },
    company: { legalForm: 'Société anonyme à conseil de surveillance' },
    headOffice: {},
    generalMeetings: {},
    appointments: { enabled: false },
    boardMemberList: [],    
    chairman: { type: 'physical' }, 
    viceChairman: { type: 'physical' },
    boardChairman: {},
    otherBoardMemberList: [],
    mainAuditor: {},
    alternateAuditor: {},
    auditorInformation: {}
  },
  component: () => import('@/components/legal-ads/forms/models/registration/sacs.vue'),   
  validate(modelData) {
    return trimObject({ 
      act: Act.validate(modelData.act),
      company: Company.validate(modelData.company),
      headOffice: HeadOffice.validate(modelData.headOffice),
      generalMeetings: GeneralMeetings.validate(modelData.generalMeetings),
      ...Appointments.validate(modelData),
      ...AuditorInformation.validate(modelData),
    });
  },  
  buildContent(modelData) {
    return [
      Act.buildContent(modelData.act, 'une société'),
      Company.buildContent(modelData.company),
      HeadOffice.buildContent(modelData.headOffice),
      GeneralMeetings.buildContent(modelData.generalMeetings),
      Appointments.buildContent(modelData),
      AuditorInformation.buildContent(modelData),
      HeadOffice.buildRegistrationContent(modelData.headOffice),
    ].flat().join("\n").replace(/undefined/g, '?');
  }
};