import * as Act from '@/services/legal-ad-form/components/selling/act';
import * as Person from '@/services/legal-ad-form/components/selling/person';
import * as Leasing from '@/services/legal-ad-form/components/selling/leasing';
import { trimObject } from '@/shared/utils';

export default {
  id: 'location-gerance',
  label: 'Location gérance',
  shortLabel: 'Location gérance',
  componentsData: {
    act: { type: '' },
    lessor: { type: '', civility: '', legalForm: '' },
    tenant: { type: '', civility: '', legalForm: '' },
    leasing: { renewal: `Il se renouvellera ensuite par tacite reconduction pour une durée indéterminée.` },    
  },
  component: () => import('@/components/legal-ads/forms/models/selling/leasing-management.vue'),   
  validate(modelData) {
    return trimObject({ 
      act: Act.validate(modelData.act),
      lessor: Person.validate(modelData.lessor),    
      tenant: Person.validate(modelData.tenant),    
      leasing: Leasing.validate(modelData.leasing, { add: ['startDate', 'endDate', 'duration', 'renewal'], skip: ['date'] }),      
    });
  },  
  buildContent(modelData) {
    return [
      Act.buildEndLeasing(modelData.act),
      Person.buildSimpleContent(modelData.lessor),
      Person.buildContent(modelData.tenant, 'A donné en location-gérance à'),
      Leasing.buildLeasingManagementContent(modelData.leasing),           
    ].flat().join("\n").replace(/undefined/g, '?');
  }
};