import { formatLegalActDate } from '@/shared/utils';
import { validate as validateAppointments } from './index';
import { validate as validatePerson, validateMany as validatePeople, buildContent as buildPersonContent } from '@/services/legal-ad-form/components/person';

export const validate = ({ appointments, boardMemberList, chairman, viceChairman, boardChairman, otherBoardMemberListEnabled, otherBoardMemberList }) => {
  if (appointments &&!appointments.enabled) return {};
  return {
    appointments: validateAppointments(appointments),
    boardMemberList: validatePeople(boardMemberList, true),
    chairman: chairman?.enabled ? validatePerson(chairman) : {},
    viceChairman: viceChairman?.enabled ? validatePerson(viceChairman) : {},
    boardChairman: boardChairman?.enabled ? validatePerson(boardChairman) : {},
    otherBoardMemberList: otherBoardMemberListEnabled ? validatePeople(otherBoardMemberList) : {}
  }
}

export const buildContent = ({ appointments, boardMemberList, chairman, viceChairman, boardChairman, otherBoardMemberListEnabled, otherBoardMemberList }) => {
  var lines = [];

  if (appointments && !appointments.enabled) return lines;

  if (boardMemberList && boardMemberList.length > 0) {
    lines.push(`Ont été nommés en qualité de premiers membres du conseil de surveillance, pour une durée de ${appointments.financialYears} exercices, soit jusqu'à l'issue de l'assemblée générale ordinaire appelée à statuer sur les comptes de l'exercice :`);

    boardMemberList.forEach(member => {
      lines = lines.concat(buildPersonContent(member))
    });  

    lines.push(`Aux termes du procès-verbal de la première réunion du conseil de surveillance, en date du ${formatLegalActDate(appointments.date)} à ${appointments.place}, les administrateurs ont été nommés :`);

    if (chairman?.enabled) lines.push(buildPersonContent(chairman, 'Président du directoire'));
    if (viceChairman?.enabled) lines.push(buildPersonContent(viceChairman, 'Vice-Président'));
    if (boardChairman?.enabled) lines.push(buildPersonContent(boardChairman, 'Président du conseil de surveillance'));

    if (otherBoardMemberListEnabled && otherBoardMemberList.length > 0) {
      lines.push('Membres du directoire : ');

      otherBoardMemberList.forEach(member => {
        lines = lines.concat(buildPersonContent(member))
      }); 
    }
  }

  return lines;
}