<template>
  <div class="flex">
    <div class="flex-1 flex-shrink-0">
      <alert v-if="errorMessage">
        {{ errorMessage }}
      </alert>

      <form-wrapper :withPublishingInfo="false" :edit-mode="proHasLegalAdModel">
        <default-form v-if="legalAd" />
        
        <div class="mt-6 text-right">  
          <t-submit-button
            type="button"
            :buttonState="buttonState"
            :labels="{ default: 'Enregistrer', inProgress: 'En cours...', success: 'Modèle modifié !', fail: 'Échec' }"
            @click="persist"
            v-if="legalAd" 
          />            
        </div>
      </form-wrapper>
    </div>
    <div class="pt-24 ml-8 w-96">  
      <preview-toggler @change="setPreviewMode" :mode="previewMode" />
      <component v-bind:is="previewComponent" :legalAd="legalAd" />
    </div>      
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import * as MyAccountService from '@/services/my-account';
import FormWrapper from '@/components/legal-ads/forms/wrapper';
import DefaultForm from '@/components/legal-ads/forms/default';
import { PreviewToggler } from '@/components/previews';
import { buildContent } from '@/shared/pdf/samples';
import SafeEditingMixin from '@/mixins/safe-editing';
import PreviewMixin from '@/mixins/preview';

export default {  
  name: 'MyLegalAdSettings',
  components: { FormWrapper, DefaultForm, PreviewToggler },
  mixins: [SafeEditingMixin, PreviewMixin],
  data() {
    return {
      errorMessage: false,
      buttonState: 'default'
    }
  },
  async mounted() {
    MyAccountService.show()
    .then(async ({ account: { companyLogoUrl, defaultLegalAdContent } }) => {      
      var content = await buildContent(companyLogoUrl, defaultLegalAdContent, this.isCurrentAccountPro)
      this.startEditingLegalAd({ content })
    })
    .catch(() => {
      console.log('could not load the account')
    });
  },
  computed: {
    ...mapState(['legalAd'])
  },
  methods: {      
    ...mapActions(['startEditingLegalAd']),  
    persist() {
      this.resetForm()
      this.buttonState = 'inProgress'
      var { content: { logo, ...content } } = this.legalAd
      MyAccountService.update({ defaultLegalAdContent: content, base64CompanyLogo: logo?.data || null })
      .then(() => this.onSuccess())
      .catch(() => this.onError())
    },
    onSuccess() {
      this.buttonState = 'success'
    },
    onError() {
      this.buttonState = 'fail'
      this.errorMessage = 'Veuillez contacter notre support technique'
    }, 
    resetForm() {
      this.buttonState = 'default'
      this.errorMessage = null     
    }
  }
}
</script>