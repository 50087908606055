import * as Person from '@/services/legal-ad-form/components/people/person';
import * as Modification from '@/services/legal-ad-form/components/people/modification';
import { trimObject } from '@/shared/utils';

export default {
  id: 'changement-nom-enfants',
  label: `Changement de nom des enfants`,
  shortLabel: 'Changement de\nnom des enfants',
  componentsData: {
    appellant: { type: '', civility: '' },
    childList: [],
    modification: {}
  },
  component: () => import('@/components/legal-ads/forms/models/people/children-naming.vue'),   
  validate(modelData) {
    return trimObject({ 
      appellant: Person.validate(modelData.appellant),    
      childList: Person.validateMany(modelData.childList, true, { skip: ['civility', 'address', 'zipCode', 'city'] }),      
      modification: Modification.validate(modelData.modification)
    });
  },  
  buildContent(modelData) {
    return [
      Person.buildContent(modelData.appellant),
      Modification.buildChildremNamingContent(modelData),           
    ].flat().join("\n").replace(/undefined/g, '?');
  }
};