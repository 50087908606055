<template>
  <div class="input block" :class="{ 'required': required }">
    <label v-if="hasLabel">{{ label }}</label>
    <slot></slot>
    <div v-if="error" class="error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: 'FormInput',
  props: {
    label: {
      type: String,
      default: undefined
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: undefined
    },
  },
  computed: {
    hasLabel() {
      return this.label && this.label.length > 0;
    }
  }
};
</script>