<template>
  <table class="w-full mt-8">
    <thead class="bg-primary text-white">
      <th
        v-for="(column, columnIndex) in columns"
        :key="`column-${columnIndex}-${column}-${randomNumber()}`"
        class="text-left py-4 lg:py-6 px-2 lg:px-8"
      >
        {{ column }}
      </th>
    </thead>
    <tbody class="bg-white">
      <tr
        v-for="(row, rowIndex) in rows"
        :key="`row-${rowIndex}-${row.id}-${randomNumber()}`"
        class="border-b"
      >
        <td
          v-for="(key, keyIndex) in keys"
          :key="`key-${keyIndex}-${rowIndex}-${key.name}-${randomNumber()}`"
          class="text-left py-4 lg:py-6 px-2 lg:px-8"
        >
          {{ displayValue(row, key) }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { random } from 'lodash';

export default {
  name: 'DataTable',
  props: {
    columns: { type: Array, required: true },
    rows: { type: Array, required: true },
    keys: {type: Array, required: true}
  },
  methods: {
    displayValue(row, key) {
      switch (key.type) {
        case 'date':
          return this.formatDate(new Date(row[key.name]));
        case 'price':
          return this.numberToCurrency(row[key.name]);
        default:
          return row[key.name];
      }
    },
    randomNumber() {
      return random(0, 9.9);
    }
  }
};
</script>
