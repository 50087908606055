import store from '@/store';
import * as AuthService from '@/services/authentication';

export const requireAuthentication = (to, from, next) => {
  const isAuthenticated = store.state.isAuthenticated;
  const protectedURL = to.matched.some(record => record.meta.protected);
  const strictlyUnprotected = to.matched.some(record => record.meta.strictlyUnprotected);

  if (isAuthenticated && strictlyUnprotected) {
    next({ name: 'myProfile' });
  } else if (protectedURL && !isAuthenticated) {
    next({ name: 'home' });
  } else {
    requireAdminAuthorization(to, from, next);
  }
};

export const requireAdminAuthorization = (to, from, next) => {
  const isAuthenticated = store.state.isAuthenticated;
  const account = store.state.account;
  const inAdminSection = to.matched.some(record => record.meta.admin);
  const token = to.query.token;

  if (inAdminSection) {
    if (isAuthenticated && !token) {
      if (account.admin) { next(); } else next({ name: 'home' });
    } else if (token) {
      AuthService.authenticateFromToken(token).then(account => {
        if (account.admin) { next(); } else next({ name: 'home' });
      });
    } else
      next({ name: 'home' });
  } else
    requireProfessionalAuthorization(to, from, next);
};

export const requireProfessionalAuthorization = (to, _from, next) => {
  const account = store.state.account;
  const inProfessionalSection = to.matched.some(record => record.meta.pro);

  if (inProfessionalSection) {
    if (!account.pro) next({name: 'home'});
    else next();
  } else
    next();
};
