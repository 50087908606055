<template>
  <div class="px-8 pt-8">
    <div class="font-semibold">1. Choisissez votre formalité</div>

    <div class="list">
      <div
        v-for="category in categories"
        :key="category.id"
        class="list-item"
        @click="selectCategory(category)"
      >
        <div
          class="flex items-center justify-center flex-shrink-0 w-4 h-4 border rounded-full border-1"
          :class="{
            'border-primary': currentCategory && category.id === currentCategory.id,
            'border-dark-gray': !currentCategory || category.id !== currentCategory.id,
          }"
        >
          <div
            v-if="currentCategory && category.id === currentCategory.id"
            class="w-2/3 rounded-full h-2/3 bg-primary">
          </div>
        </div>
        <span
          class="ml-2"
          :class="{ 'font-semibold': currentCategory && category.id === currentCategory.id }"
        >
          {{ category.label }}
        </span>
      </div>
    </div>

    <div v-if="currentCategory" class="mt-8">
      <div class="font-semibold">2. Choisissez votre modèle d'annonce</div>

      <div class="list">
        <div
          v-for="model in models"
          :key="`${model.id}-${model.label}`"
          class="list-item"
          @click="selectModel(model)"
        >
          <div
            class="flex items-center justify-center flex-shrink-0 w-4 h-4 border rounded-full border-1"
            :class="{
              'border-primary': currentModel && model.id === currentModel.id,
              'border-dark-gray': !currentModel || model.id !== currentModel.id,
            }"
          >
            <div
              v-if="currentModel && model.id === currentModel.id"
              class="w-2/3 rounded-full h-2/3 bg-primary">
            </div>
          </div>
          <span
            class="ml-2"
            :class="{ 'font-semibold': currentModel && model.id === currentModel.id }"
          >
            {{ model.label }}
          </span>
        </div>
      </div>
    </div>

    <div ref="validateButton" class="mt-8 text-right">
      <button
        v-if="currentModel"
        type="button"
        class="px-12 py-3 ml-6 text-white transition-all hover:bg-opacity-90 bg-primary"
        @click="() => $emit('model-selected', currentModel)"
      >
        Valider
      </button>
    </div>

  </div>
</template>

<script>
import * as LegalAdFormService from '@/services/legal-ad-form';

export default {
  name: 'LegalAdModelPicker',
  data() {
    return { currentCategory: null, currentModel: null };
  },
  computed: {
    categories() {
      return LegalAdFormService.categories;
    },
    models() {
      return LegalAdFormService.findAllByCategory(this.currentCategory);
    },
  },
  methods: {
    selectCategory(category) {
      this.currentCategory = category;
    },
    selectModel(model) {
      this.currentModel = model;
      window.scroll({
        top: this.$refs.validateButton.offsetTop,
        behavior: 'smooth'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .list {
    @apply mt-6 grid grid-cols-4 gap-8;
  }

  .list-item {
    @apply col-span-1 px-4 py-2 flex items-center opacity-90 cursor-pointer transition-all;
  }
</style>
