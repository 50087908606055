import * as Act from '@/services/legal-ad-form/components/selling/act';
import * as Person from '@/services/legal-ad-form/components/selling/person';
import * as Leasing from '@/services/legal-ad-form/components/selling/leasing';
import { trimObject } from '@/shared/utils';

export default {
  id: 'fin-location-gerance',
  label: 'Fin de location gérance',
  shortLabel: 'Fin de\nlocation gérance',
  componentsData: {
    act: { type: '' },
    lessor: { type: '', civility: '', legalForm: '' },
    tenant: { type: '', civility: '', legalForm: '' },
    leasing: {},    
  },
  component: () => import('@/components/legal-ads/forms/models/selling/end-leasing.vue'),   
  validate(modelData) {
    return trimObject({ 
      act: Act.validate(modelData.act),
      lessor: Person.validate(modelData.lessor),    
      tenant: Person.validate(modelData.tenant),    
      leasing: Leasing.validate(modelData.leasing),      
    });
  },  
  buildContent(modelData) {
    return [
      Act.buildEndLeasing(modelData.act),
      Person.buildSimpleContent(modelData.lessor),
      Person.buildContent(modelData.tenant, 'À'),
      Leasing.buildEndLeasingContent(modelData.leasing),           
    ].flat().join("\n").replace(/undefined/g, '?');
  }
};