<template>
  <div class="mb-3 px-3 text-center">
    <div v-if="mode === 'PDF'">
      <button
        class="px-4 py-3 hover:bg-opacity-90 transition-all bg-gray-800 text-white"
        @click="change('HTML')"
      >
        Retour au mode HTML
      </button>
    </div>

    <div v-if="mode === 'HTML'">
      <p class="mb-2">Vous voulez voir la version PDF de votre annonce ?</p>
      <button
        class="px-4 py-3 hover:bg-opacity-90 transition-all bg-gray-800 text-white"
        @click="change('PDF')"
      >
        Voir le PDF
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreviewToggler',
  props: {
    mode: { type: String, default: 'PDF' }
  },
  methods: {
    change(mode) {
      this.$emit('change', mode);
    }
  }
};
</script>
