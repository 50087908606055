import BusinessModel from './business';
import LeaseholdModel from './leasehold';
import EndLeasingModel from './end-leasing';
import LeasingManagementModel from './leasing-management';

export default [
  BusinessModel,
  LeaseholdModel,
  EndLeasingModel,
  LeasingManagementModel
];