<template>
  <div class="panel xl:mr-8">
    <h3 class="panel-title">Mes {{ type }}</h3>
    <div class="flex items-center justify-between">
      <router-link :to="{ name: 'newModelLegalAd' }" class="text-center text-white btn bg-primary w-52">
        {{ type === 'annonces' ? 'Créer une annonce avec formulaire' : 'Créer un devis avec formulaire' }}
      </router-link>
      <router-link :to="{ name: 'newRawLegalAd' }" class="text-center bg-white btn text-primary w-52">
        {{ type === 'annonces' ? 'Copier coller votre texte d\'annonce' : 'Copier coller votre texte' }}
      </router-link>
    </div>
    <slot />
    <div class="flex justify-end w-full">
      <router-link v-if="type === 'annonces'" :to="{ name: 'myLegalAds' }" class="ml-auto mr-0 text-white btn bg-primary">
        Toutes les annonces
      </router-link>
      <router-link v-if="type === 'devis'" :to="{ name: 'myLegalAdDrafts' }" class="ml-auto mr-0 text-white btn bg-primary">
        Tous les devis
      </router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DashboardPanel',
  props: {
    type: { type: String, required: true }
  }
};
</script>

<style lang="postcss" scoped>
.panel {
  @apply w-full my-4 xl:w-auto xl:my-0 xl:flex-1 bg-gray-100 p-8;
}

.panel-title {
  @apply font-bold text-lg mb-6;
}

.btn {
  @apply py-4 px-8;
}
</style>