import * as Company from '@/services/legal-ad-form/components/management-modification/company';
import * as HeadOffice from '@/services/legal-ad-form/components/management-modification/head-office';
import * as Decision from '@/services/legal-ad-form/components/management-modification/decision';
import * as Person from '@/services/legal-ad-form/components/management-modification/person';
import * as Mention from '@/services/legal-ad-form/components/management-modification/mention';
import { trimObject } from '@/shared/utils';

export default {
  id: 'directeur',
  label: 'Directeur général / Directeur général délégué / Directeur général unique',
  shortLabel: 'Directeur général',
  componentsData: {
    company: { legalForm: '' },
    headOffice: {},
    decision: { type: '', actor: '' },
    replacementChiefExecutiveList: [],
    nominatedChiefExecutiveList: [],
    leavingChiefExecutiveList: [],    
    mention: { registry: 'Mention sera portée au RCS de ...(à compléter)' } 
  },
  component: () => import('@/components/legal-ads/forms/models/management-modification/chief-executive.vue'),   
  validate(modelData) {
    return trimObject({ 
      company: Company.validate(modelData.company),
      headOffice: HeadOffice.validate(modelData.headOffice),    
      decision: Decision.validate(modelData.decision, { skip: ['type'] }),
      replacementChiefExecutiveList: Person.validateMany(modelData.replacementChiefExecutiveList, false, { add: ['quality', 'simpleName'] }),
      nominatedChiefExecutiveList: Person.validateMany(modelData.nominatedChiefExecutiveList, false, { add: ['quality'] }),
      leavingChiefExecutiveList: Person.validateMany(modelData.leavingChiefExecutiveList, false, { add: ['quality', 'simpleName'] }),
      mention: Mention.validate(modelData.mention)
    });
  },  
  buildContent(modelData) {
    return [
      Company.buildContent(modelData.company, { withShareCapital: false }),
      HeadOffice.buildContent(modelData.headOffice),     
      Decision.buildChiefExecutiveContent(modelData.decision),
      Person.buildManyContent(modelData.replacementChiefExecutiveList),      
      Person.buildManyContent(modelData.nominatedChiefExecutiveList, 'nomination'),
      Person.buildManyContent(modelData.leavingChiefExecutiveList, 'partant'),
      Mention.buildContent(modelData.mention)
    ].flat().join("\n").replace(/undefined/g, '?');
  }
};