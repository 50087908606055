<template>
  <Layout>
    <div class="px-6 my-6">
      <h2 class="text-lg text-gray-700">
        Notre service n'est pas disponible pour le moment sur téléphone mobile.<br/>
        Veuillez nous retrouver sur tablette ou sur ordinateur.
      </h2>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/default';

export default {
  name: 'MobilePage',
  components: { Layout },
};
</script>
