<template>
  <Layout>
    <div class="container mx-auto py-8 px-8 flex flex-col flex-grow">
      <h1 class="text-primary text-xl font-bold">Mes devis annonces légales</h1>

      <MyLegalAdDrafts class="flex-grow" />
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/default";
import MyLegalAdDrafts from "@/components/my-account/legal-ad-drafts";

export default {
  name: "MyLegalAdDraftsPage",
  components: { Layout, MyLegalAdDrafts },
};
</script>
