import * as Act from '@/services/legal-ad-form/components/people/act';
import * as Person from '@/services/legal-ad-form/components/people/person';
import * as Assets from '@/services/legal-ad-form/components/people/assets';
import { trimObject } from '@/shared/utils';

export default {
  id: 'declaration-insaisissabilite',
  label: 'Déclaration d\'insaisissabilité',
  shortLabel: 'Déclaration\nd\'insaisissabilité',
  componentsData: {
    act: {},
    appellant: { type: '', civility: '' },
    assets: {}
  },
  component: () => import('@/components/legal-ads/forms/models/people/assets.vue'),   
  validate(modelData) {
    return trimObject({ 
      act: Act.validate(modelData.act),
      appellant: Person.validate(modelData.appellant, { add: ['job'], skip: ['birthday', 'birthPlace'] }),    
      assets: Assets.validate(modelData.assets),
    });
  },  
  buildContent(modelData) {
    return [
      Act.buildContent(modelData.act),
      Assets.buildContent(modelData),      
    ].flat().join("\n").replace(/undefined/g, '?');
  }
};