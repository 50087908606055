import api from '@/shared/api';
import { toDataURL, parseDateRecursively } from '@/shared/utils';

export const findAll = page => {
  return api.get('/legal_ad_drafts', { params: { page } })
  .then(({ data }) => data)
  .catch(error => ({ list: [], meta: {}, error }));
};

export const find = id => {
  return api.get(`/legal_ad_drafts/${id}`)
  .then(async ({ data: { id, name, altName, confirmed, publishingAt, content, model, logoUrl, format, newspaperId, clientEmail } }) => {    
    return { 
      id,
      name,
      altName,
      confirmed,
      format,
      newspaperId,
      clientEmail,
      publishingAt: publishingAt ? new Date(publishingAt) : null,
      content: { ...content, logo: logoUrl ? await toDataURL(logoUrl) : null },
      model: parseDateRecursively(model)
    };
  })
  .catch(error => {
    console.log('error loading a legal ad draft', error);
    return null;
  })
}

export const create = (legalAd) => {
  var attributes = { ...legalAd, content: { ...legalAd.content }, model: { ...legalAd.model }, base64Logo: legalAd.content.logo?.data };
  delete(attributes.content.logo);
  if (attributes.publishingAt) {
    var date = attributes.publishingAt;
    attributes.publishingAt = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;    
  }
  return api.post(`/legal_ad_drafts`, { legalAd: attributes }).then(({ data }) => data); 
}

export const update = (id, legalAd) => {
  var attributes = { ...legalAd, content: { ...legalAd.content }, model: { ...legalAd.model }, base64Logo: legalAd.content.logo?.data };
  delete(attributes.content.logo);
  if (attributes.publishingAt) {
    var date = attributes.publishingAt;
    attributes.publishingAt = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;    
  }
  return api.put(`/legal_ad_drafts/${id}`, { legalAd: attributes }).then(({ data }) => data); 
}

export const destroy = id => {
  return api.delete(`/legal_ad_drafts/${id}`).then(({ data }) => data); 
}