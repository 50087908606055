import * as Company from '@/services/legal-ad-form/components/transformation/company';
import * as HeadOffice from '@/services/legal-ad-form/components/transformation/head-office';
import * as Decision from '@/services/legal-ad-form/components/transformation/decision';
import * as Person from '@/services/legal-ad-form/components/transformation/person';
import * as Transformation from '@/services/legal-ad-form/components/transformation';
import * as Mention from '@/services/legal-ad-form/components/transformation/mention';
import { trimObject } from '@/shared/utils';

export default {
  id: 'transformation-en-sas-sasu',
  label: 'Transformation d\'une société en SAS / SASU',
  shortLabel: 'Transformation\nd\'une société en\nSAS / SASU',
  componentsData: {
    company: { legalForm: '' },
    headOffice: {},
    decision: {},
    transformation: {},
    chairman: {},
    chiefExecutiveList: [],
    representativeChiefExecutiveList: [],
    mainAuditorList: [],
    alternateAuditorList: [],
    mention: { registry: 'Mention sera portée au RCS de ...(à compléter)' } 
  },
  component: () => import('@/components/legal-ads/forms/models/transformation/sas.vue'),   
  validate(modelData) {
    return trimObject({ 
      company: Company.validate(modelData.company),
      headOffice: HeadOffice.validate(modelData.headOffice),    
      decision: Decision.validate(modelData.decision),
      chairman: Person.validate(modelData.chairman),
      chiefExecutiveList: Person.validateMany(modelData.chiefExecutiveList, false),
      representativeChiefExecutiveList: Person.validateMany(modelData.representativeChiefExecutiveList, false),      
      mainAuditorList: Person.validateMany(modelData.mainAuditorList, false),
      alternateAuditorList: Person.validateMany(modelData.alternateAuditorList, false),
      transformation: Transformation.validateSAS(modelData.transformation),
      mention: Mention.validate(modelData.mention)
    });
  },  
  buildContent(modelData) {
    return [
      Company.buildContent(modelData.company, { withShareCapital: true }),
      HeadOffice.buildContent(modelData.headOffice),     
      Decision.buildSASContent(modelData.decision),
      Person.buildContent(modelData.chairman, 'Président'),      
      Person.buildManyContent(modelData.chiefExecutiveList, 'Directeur général'),
      Person.buildManyContent(modelData.representativeChiefExecutiveList, 'Directeur général délégué'),
      Person.buildManyContent(modelData.mainAuditorList, 'Commissaire aux comptes titulaire'),
      Person.buildManyContent(modelData.alternateAuditorList, 'Commissaire aux comptes suppléant'),
      Transformation.buildSASContent(modelData),
      Mention.buildContent(modelData.mention)
    ].flat().join("\n").replace(/undefined/g, '?');
  }
};