import MyLegalAdsPage from '@/views/my-account/legal-ads';
import MyLegalAdDraftsPage from '@/views/my-account/legal-ad-drafts';
import MyLegalAdSettingsPage from '@/views/my-account/legal-ad-settings';
import EditMyAccountPage from '@/views/my-account/edit';

export default [
  {
    name: 'myProfile',
    path: '/mon-profil',
    redirect: '/mon-profil/annonces-legales',
  },
  {
    name: 'myLegalAds',
    path: '/mon-profil/annonces-legales',
    component: MyLegalAdsPage,
    meta: {
      protected: true,
    },
  },
  {
    name: 'myLegalAdDrafts',
    path: '/mon-profil/annonces-legales-devis',
    component: MyLegalAdDraftsPage,
    meta: {
      protected: true,
    },
  },
  {
    name: 'myLegalAdSettings',
    path: '/mon-profil/modele-annonce',
    component: MyLegalAdSettingsPage,
    meta: {
      protected: true,
    },
  },
  {
    name: 'editMyAccount',
    path: '/mon-profil/edition',
    component: EditMyAccountPage,
    meta: {
      protected: true,
    }
  },
];