import { validatePresenceOf } from '@/shared/utils';

export const validate = (headOffice, options) => {
  return validatePresenceOf(
    headOffice, 
    ['address', 'zipCode', 'city', 'registrationNumber', 'registrationPlace'], 
    options
  );  
}

export const buildContent = headOffice => {
  var lines = [];
  if (headOffice?.address || headOffice?.zipCode || headOffice?.city) {
    var address = 'Siège social : ';
    if (headOffice.address) address += headOffice.address;
    if (headOffice.zipCode || headOffice.address) address += ', ';
    if (headOffice.zipCode) address += headOffice.zipCode;
    if (headOffice.city) {
      if (headOffice.zipCode) address += ' ';
      address += headOffice.city;
    }
    address += '.';
    lines.push(address);
  }

  if (headOffice?.registrationNumber && headOffice?.registrationPlace)
    lines.push(`${headOffice.registrationNumber} RCS ${headOffice.registrationPlace}.`)

  return lines;
}
