<template>
  <div class="p-3 rounded bg-primary bg-opacity-10">
    <h2 class="font-bold">Commande</h2>

    <hr class="my-4">

    <table class="w-full">
      <tbody>
        <tr>
          <th class="font-normal text-left text-gray-600">Journal</th>
          <td class="text-right">{{ newspaperTitle }}</td>
        </tr>
        <tr>
          <th class="font-normal text-left text-gray-600">Format</th>
          <td class="text-right">{{ legalAdFormat }}</td>
        </tr>
        <tr>
          <th class="font-normal text-left text-gray-600">Date de parution</th>
          <td class="text-right">{{ legalAd.publishingAt | formatDate }}</td>
        </tr>
      </tbody>
    </table>

    <hr class="my-4">

    <div v-if="billing">
      <div class="flex items-center mb-2">
        <h4 class="text-gray-600">Montant HT</h4>
        <div class="ml-auto">{{ amount }}</div>
      </div>

      <div class="flex items-center mb-2">
        <h4 class="text-gray-600">Montant TVA <small>(20%)</small></h4>
        <div class="ml-auto">{{ taxAmount }}</div>
      </div>

      <div class="flex items-center text-lg">
        <h4>Total TTC</h4>
        <div class="ml-auto font-bold">{{ totalAmount }}</div>
      </div>

      <hr v-if="displayButton" class="my-4">
    </div>

    <div v-if="displayButton" class="flex items-center justify-center">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import * as OrderService from '@/services/order';

export default {
  name: 'Cart',
  props: {
    legalAdId: { type: String, default: null, },
    legalAd: { type: Object, default: null, },
    displayButton: { type: Boolean, default: true }
  },
  data() {
    return {
      billing: undefined
    };
  },
  computed: {
    newspaperTitle() {
      return this.$store.getters.newspaperTitle(this.legalAd.newspaperId);
    },
    legalAdFormat() {
      return { web: 'Site internet', print: 'Journal papier' }[this.legalAd.format];
    },
    amount() {
      return this.numberToCurrency(this.billing.amount);
    },
    taxAmount() {
      return this.numberToCurrency(this.billing.taxAmount);
    },
    totalAmount() {
      return this.numberToCurrency(this.billing.totalAmount);
    },
  },
  watch: {
    legalAd: {
      immediate: false,
      handler() {
        this.debouncedRefreshBilling();
      }
    }
  },
  created() {
    this.debouncedRefreshBilling = debounce(this.refreshBilling, 500);
  },
  mounted() {
    this.debouncedRefreshBilling();
  },
  methods: {
    refreshBilling() {
      if (!this.legalAd) return ;
      OrderService.getBillingInformation(this.legalAdId, this.legalAd).then(billing => {
        this.billing = billing;
      });
    }
  }
};
</script>
