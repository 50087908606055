import axios from 'axios';
import { API_BASE_URL } from './config';
import * as AuthService from '@/services/authentication';
import store from '@/store';

export const authHeaders = (token) => {
  return { 
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': `Bearer ${token}`
  };
};

const api = axios.create({
  baseURL: API_BASE_URL,
  timeout: 50000,
  headers: { 
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  transformResponse(data) {
    // Do whatever you want to transform the data
    // console.log('[DEBUG] API response', data);
    return data ? JSON.parse(data) : {};
  },
});

api.interceptors.request.use(config => {
  if (store.state.isAuthenticated && store.state.token && !config.headers['Authorization'])
    config.headers['Authorization'] = `Bearer ${store.state.token}`;

  return config;
}, error => Promise.reject(error));

api.interceptors.response.use(response => {
  return response;
}, error => {
  console.log('API ERROR', error);  
  if (error.response?.status === 401) {
    AuthService.signOut();
  } else {
    error.blocked = error.response === undefined; // Request certainly blocked by the AdBlock plus Chrome plugin
    return Promise.reject(error);
  }
});

export default api;
