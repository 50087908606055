import * as Act from '@/services/legal-ad-form/components/act';
import * as Asl from '@/services/legal-ad-form/components/asl';
import * as HeadOffice from '@/services/legal-ad-form/components/head-office';
import { trimObject } from '@/shared/utils';

export default {
  id: 'asl',
  label: 'Avis de constitution ASL',
  shortLabel: 'Avis de constitution',
  componentsData: {
    act: { type: '' },  
    asl: {},
    headOffice: {}  
  },
  component: () => import('@/components/legal-ads/forms/models/registration/asl.vue'),   
  validate(modelData) {
    return trimObject({ 
      act: Act.validate(modelData.act),  
      asl: Asl.validate(modelData.asl),  
      headOffice: HeadOffice.validate(modelData.headOffice, { skip: ['registrationPlace'] }),    
    });
  },  
  buildContent(modelData) {
    return [
      Act.buildContent(modelData.act, 'une Association Syndicale'),
      Asl.buildContent(modelData.asl), 
      HeadOffice.buildContent(modelData.headOffice),     
      HeadOffice.buildRegistrationContent(modelData.headOffice), 
    ].flat().join("\n").replace(/undefined/g, '?');
  }
};