import { validatePresenceOf, formatLegalActDate } from '@/shared/utils';

export const validateShareCapital = modification => {
  return validatePresenceOf(modification, ['shareCapital', 'statusText']);
};

export const validateCompanyName = modification => {
  return validatePresenceOf(modification, ['companyName']);
};

export const validateCompanyObject = modification => {
  return validatePresenceOf(modification, ['type', 'companyObject']);
};

export const validateDuration = modification => {
  return validatePresenceOf(modification, ['duration']);
};

export const validateTransfer = modification => {
  return validatePresenceOf(modification, ['address', 'city', 'zipCode']);
};

export const buildShareCapitalContent = ({ decision, company, modification: { shareCapital, statusText } }) => {
  var lines = [];

  lines.push(`Aux termes ${decision.type} en date du ${formatLegalActDate(decision.date)}, ${decision.actor} de modifier le capital social en le portant de ${company.shareCapital} euros à ${shareCapital} euros.`);

  if (statusText) lines.push(`${statusText}.`);

  return lines;
};

export const buildCompanyNameContent = ({ decision, modification }) => {
  var lines = [];

  var line = `Aux termes ${decision.type} en date du ${formatLegalActDate(decision.date)}, ${decision.actor}`;
  if (modification.date) line = `${line} à compter du ${formatLegalActDate(modification.date)}`;
  line = `${line} de modifier la dénomination sociale et d'adopter la dénomination ${modification.companyName}.`;

  lines.push(line);

  if (modification.companyAcronym)
    lines.push(`Le sigle devient ${modification.companyAcronym}.`);

  if (modification.statusText) lines.push(`${modification.statusText}.`);

  return lines;
};

export const buildCompanyObjectContent = ({ decision, modification }) => {
  var lines = [];

  var line = `Aux termes ${decision.type} en date du ${formatLegalActDate(decision.date)}, ${decision.actor}`;
  if (modification.date) line = `${line} à compter du ${formatLegalActDate(modification.date)}`;

  if (modification.type === 'extension')
    line = `${line} d'étendre l'objet social aux activités de : ${modification.companyObject}.`;
  else if (modification.type === 'modification')
    line = `${line} de modifier l'objet social comme suit : ${modification.companyObject}.`;

  lines.push(line);

  if (modification.statusText) lines.push(`${modification.statusText}.`);

  return lines;
};

export const buildDurationContent = ({ decision, modification }) => {
  var lines = [];

  var line = `Aux termes ${decision.type} en date du ${formatLegalActDate(decision.date)}, ${decision.actor}`;
  if (modification.date) line = `${line} à compter du ${formatLegalActDate(modification.date)}`;
  line = `${line} de porter la durée de la société à ${modification.duration} ans.`;

  lines.push(line);

  if (modification.statusText) lines.push(`${modification.statusText}.`);

  return lines;
};

export const buildTransferContent = ({ decision, modification }) => {
  var lines = [];

  var line = `Aux termes ${decision.type} en date du ${formatLegalActDate(decision.date)}, ${decision.actor}`;
  if (modification.date) line = `${line} à compter du ${formatLegalActDate(modification.date)}`;
  line = `${line} de transférer le siège social au ${modification.address}, ${modification.zipCode} ${modification.city}.`;

  lines.push(line);

  if (modification.statusText) lines.push(`${modification.statusText}.`);

  return lines;
};

export const buildSocialObjectContent = ({ modification }) => {
  const lines = [];

  if (modification.socialObject)
    lines.push(`Objet : ${modification.socialObject}`);

  return lines;
};