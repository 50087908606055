import * as Company from '@/services/legal-ad-form/components/modification/company';
import * as HeadOffice from '@/services/legal-ad-form/components/modification/head-office';
import * as Decision from '@/services/legal-ad-form/components/modification/decision';
import * as Modification from '@/services/legal-ad-form/components/modification';
import * as Mention from '@/services/legal-ad-form/components/modification/mention';
import { trimObject } from '@/shared/utils';

export default { //transfert_siege_hors_ressort_nouveau_departement_societe_civile
  id: 'transfert-siege-nouveau-departement-civile',
  label: 'Transfert de siège hors ressort - Nouveau département (société civile)',
  shortLabel: 'Transfert de siège\nsocial',
  componentsData: {
    company: { legalForm: '' },
    headOffice: {},
    decision: { type: '', actor: '' },
    modification: {},
    mention: { registry: 'Radiation du RCS [Ancien greffe] et immatriculation au RCS [Nouveau greffe]' } 
  },
  component: () => import('@/components/legal-ads/forms/models/modification/transfer-new-state-1.vue'),   
  validate(modelData) {
    return trimObject({ 
      company: Company.validate(modelData.company),
      headOffice: HeadOffice.validate(modelData.headOffice),    
      decision: Decision.validate(modelData.decision),
      modification: Modification.validateTransfer(modelData.modification),
      mention: Mention.validate(modelData.mention)
    });
  },  
  buildContent(modelData) {
    return [
      Company.buildContent(modelData.company, { withShareCapital: true }),
      HeadOffice.buildContent(modelData.headOffice),     
      Modification.buildTransferContent(modelData),
      Modification.buildSocialObjectContent(modelData),
      Mention.buildContent(modelData.mention)
    ].flat().join("\n").replace(/undefined/g, '?');
  }
};