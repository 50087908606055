import * as Act from '@/services/legal-ad-form/components/act';
import * as Company from '@/services/legal-ad-form/components/company';
import * as HeadOffice from '@/services/legal-ad-form/components/head-office';
import * as OwnerList from '@/services/legal-ad-form/components/owner-list';
import { trimObject } from '@/shared/utils';

export default {
  id: 'eurl',
  label: 'Avis de constitution EURL',
  shortLabel: 'Avis de constitution',
  componentsData: {
    act: { type: '' },
    company: { legalForm: 'Entreprise unipersonnelle à responsabilité limitée' },
    headOffice: {},
    ownerList: []
  },
  component: () => import('@/components/legal-ads/forms/models/registration/eurl.vue'),   
  validate(modelData) {
    return trimObject({ 
      act: Act.validate(modelData.act),
      company: Company.validate(modelData.company),
      headOffice: HeadOffice.validate(modelData.headOffice),
      ownerList: OwnerList.validate(modelData.ownerList)
    });
  },  
  buildContent(modelData) {
    return [
      Act.buildContent(modelData.act, 'une société'),
      Company.buildContent(modelData.company),
      HeadOffice.buildContent(modelData.headOffice),
      OwnerList.buildContent(modelData.ownerList),
      HeadOffice.buildRegistrationContent(modelData.headOffice),
    ].flat().join("\n").replace(/undefined/g, '?');
  }
};