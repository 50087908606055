<template>
  <div>
    <h2 class="font-bold text-dark">Informations de publication</h2>

    <div class="grid grid-cols-2 gap-8 mt-4" v-if="allNewspapers">
      <div class="col-span-1">
        <div class="input required">
          <label for="newspaper-zip-code">Département</label>
          <select
            id="newspaper-zip-code"
            :class="{'legal-ad-input-required': !publishingInfo.zipCode}"
            v-model="publishingInfo.zipCode"
          >
            <option
              v-for="zipCode in zipCodes"
              :key="zipCode.value"
              :value="zipCode.value"
            >
              {{ zipCode.value }} - {{ zipCode.label }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-span-1" v-if="publishingInfo.zipCode">
        <div class="input required">
          <label for="newspaper-id">Journal</label>
          <select
            id="newspaper-id"
            :class="{'legal-ad-input-required': !publishingInfo.newspaperId}"
            v-model="publishingInfo.newspaperId"
          >
            <option
              v-for="newspaper in newspapers"
              :key="newspaper.id"
              :value="newspaper.id"
            >
              {{ newspaper.title }}
            </option>
          </select>
        </div>
      </div>

      <div
        class="col-span-1 border-1"
        v-if="publishingInfo.newspaper"
      >
        <div class="input required">
          <label for="newspaper-format">Mode de parution</label>
          <select
            id="newspaper-format"
            :disabled="formats && formats.length === 1"
            :class="{'legal-ad-input-required': !publishingInfo.format}"
            v-model="publishingInfo.format"
          >
            <option
              v-for="format in formats"
              :key="format.value"
              :value="format.value"
            >
              {{ format.label }}
            </option>
          </select>
          <div v-if="!withPublishingInfo && currentFormat" class="mt-2 ml-1">
            <span class="mr-1 font-bold text-red-600">Ancien format : </span>{{ oldFormat }}
          </div>
        </div>
      </div>

      <div
        :class="{
          'col-start-1 col-span-1': isCurrentAccountPro,
          'col-span-2': !isCurrentAccountPro,
        }"
        v-if="publishingInfo.format && withPublishingInfo"
      >
        <div class="input required">
          <label for="legalad-date">Date de publication</label>
          <div class="mb-4 w-100 d-flex justify-content-center">
            <vc-date-picker
              v-model="publishingInfo.date"
              timezone="UTC"
              :min-date="nextOpenDay"
              :max-date="maxPublishingDate"
              :disabled-dates="{ weekdays: disabledWeekDays }"
              locale="fr"
              color="purple"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input type="text" :value="inputValue" v-on="inputEvents" />
              </template>
            </vc-date-picker>
          </div>
          <div class="hint" v-if="publishingInfo.format == 'web'">
            Vous devez choisir la date de publication de votre annonce. Afin de
            nous laisser le temps de valider votre annonce, la date minimale de
            publication est J+{{ businessDaysThreshold }} (uniquement jours
            ouvrés).
          </div>
          <div class="hint" v-else>
            Vous devez choisir la date de publication de votre annonce.
            Ce journal publie tous les <u>{{ printPublishingDayName }}s</u> de chaque semaine.
            Afin de nous laisser le temps de valider votre annonce, la date minimale de
            publication est J+{{ businessDaysThreshold }} (uniquement jours
            ouvrés).
          </div>
        </div>
      </div>

      <div class="col-span-1" v-if="isCurrentAccountPro && publishingInfo.format">
        <div class="input required">
          <label for="legalad-name">Votre référence interne</label>
          <input
            type="text"
            :class="{ 'reference-required': !altName }"
            v-model="altName"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { weekDayName } from '@/shared/utils';

export default {
  name: 'PublishingInfoPaneLegalAdForm',
  props: {
    withPublishingInfo: { type: Boolean, default: true, required: false }
  },
  data() {
    return {
      fetching: false,
      currentFormat: null,
      publishingInfo: {
        zipCode: null,
        newspaperId: null,
        newspaper: null,
        format: null,
        date: null,
      },
    };
  },
  computed: {
    ...mapState(['legalAd']),
    zipCodes() {
      return this.$store.getters.newspaperZipCodes;
    },
    allNewspapers() {
      return this.$store.state.newspapers;
    },
    newspapers() {
      return this.allNewspapers.filter(
        (newspaper) => newspaper.zipCode === this.publishingInfo.zipCode
      );
    },
    altName: {
      get() { return this.legalAd.altName; },
      set(value) { this.change('altName', value); }
    },
    oldFormat() {
      switch (this.currentFormat) {
        case "web":
          return 'Site internet';
        case "print":
          return 'Journal papier';
        default:
          return '';
      }
    },
    formats() {
      switch (this.publishingInfo.newspaper.legalAdFormat) {
        case "web":
          return [{ label: "Site internet", value: "web" }];
        case "print":
          return [{ label: "Journal papier", value: "print" }];
        default:
          return [
            { label: "Site internet", value: "web" },
            { label: "Journal papier", value: "print" },
          ];
      }
    },
    disabledWeekDays() {
      return this.publishingInfo.format === 'print' ? 
      [1, 2, 3, 4, 5, 6, 7].filter(d => d !== this.publishingInfo.newspaper.printPublishingWeekDay + 1) : 
      [1, 7]
    },
    printPublishingDayName() {
      return weekDayName(this.publishingInfo.newspaper.printPublishingWeekDay)
    },
    businessDaysThreshold() {
      return this.publishingInfo.newspaper[
        `${this.publishingInfo.format}BusinessDaysThreshold`
      ];
    },
    nextOpenDay() {
      return (new Date(this.publishingInfo.newspaper[
        `${this.publishingInfo.format}MinPublishingDate`
      ]) || new Date);
    },
    maxPublishingDate() {
      const newDate = new Date(this.nextOpenDay.valueOf())    
      return new Date(newDate.setMonth(newDate.getMonth() + 1))
    }
  },
  mounted() {
    if (this.$route.name === 'editLegalAdDraft') this.handleDateForDraft();
  },
  methods: {
    ...mapActions(['changeLegalAd']),
    fetch() {
      if (!this.legalAd?.newspaperId || this.allNewspapers.length === 0) return;
      this.fetching = true;
      const { newspaperId, format, publishingAt } = this.legalAd;
      const newspaper = this.allNewspapers.find(
        (newspaper) => newspaper.id === newspaperId
      );
      this.publishingInfo = {
        zipCode: newspaper.zipCode,
        newspaperId,
        newspaper,
        format,
        date: publishingAt,
      };
      this.currentFormat = format;
      this.$nextTick(() => {
        this.fetching = false;
      });
    },
    change(attribute, value) {
      this.changeLegalAd({ changes: { [attribute]: value } });
    },
    emptyPublishingInfo() {
      return {
        zipCode: null,
        newspaperId: null,
        newspaper: null,
        format: null,
        date: null,
      };
    },
    reset() {
      this.publishingInfo = this.emptyPublishingInfo();
    },
    updateLegalAd() {
      this.changeLegalAd({
        changes: {
          newspaperId: this.publishingInfo.newspaperId,
          format: this.publishingInfo.format,
          publishingAt: this.publishingInfo.date,
        },
      });
    },
    // Check if date is in the past and set it to the next open day
    handleDateForDraft() {
      const draftDate = new Date(this.publishingInfo.date)
      const today = new Date();

      if (draftDate <= today) {
        this.publishingInfo.date = this.nextOpenDay;
        this.updateLegalAd();
      }
    }
  },
  watch: {
    allNewspapers: {
      immediate: true,
      handler() {
        this.fetch();
      },
    },
    "legalAd.id": {
      handler(legalAdId) {
        if (this.isBlank(legalAdId)) this.reset();
        else this.fetch();
      },
    },
    "publishingInfo.zipCode"(zipCode, oldZipCode) {
      if (this.fetching || zipCode === oldZipCode) return;
      this.publishingInfo = { ...this.emptyPublishingInfo(), zipCode };
      this.updateLegalAd();
    },
    "publishingInfo.newspaperId"(newspaperId, oldNewspaperId) {
      if (this.fetching || !newspaperId || newspaperId === oldNewspaperId)
        return;
      this.publishingInfo = {
        ...this.publishingInfo,
        newspaper: this.allNewspapers.find(
          (newspaper) => newspaper.id === newspaperId
        ),
        format: null,
        date: null,
      };
      this.updateLegalAd();
    },
    "publishingInfo.newspaper"(newspaper, oldNewspaper) {
      if (this.fetching || !newspaper || newspaper === oldNewspaper) {
        return;
      }
      this.publishingInfo = {
        ...this.publishingInfo,
        format: this.formats?.length === 1 ? this.formats[0].value : null
      }
    },
    "publishingInfo.format"(format, oldFormat) {
      if (this.fetching || !format || format === oldFormat) return;
      this.publishingInfo = {
        ...this.publishingInfo,
        date: new Date(this.nextOpenDay),
      };
    },
    "publishingInfo.date"(date, oldDate) {
      if (this.fetching || !date || date === oldDate) return;
      this.updateLegalAd();
    },
  },
};
</script>
