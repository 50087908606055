<template>
  <div class="relative">
    <button
      id="options-menu"
      type="button"
      class="inline-flex justify-center w-full px-5 py-3 border border-gray-100 rounded hover:bg-gray-100 hover:bg-opacity-5 focus:outline-none"
      aria-expanded="true"
      aria-haspopup="true"
      @click="toggleDropdown"
    >
      Bonjour {{ currentAccount.fullName }}
      <!-- Heroicon name: solid/chevron-down -->
      <svg
        class="w-5 h-5 ml-2 -mr-1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </button>

    <div
      v-if="showDropdown"
      class="absolute right-0 z-50 mt-2 origin-top-right bg-white rounded shadow-lg w-72 ring-1 ring-black ring-opacity-5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
    >
      <div class="py-1" role="none">

        <div v-if="isCurrentAccountPro" class="dropdown-item">
          <router-link :to="{ name: 'myDashboard' }" class="text-dark">
            Mon dashboard
            <span
              class="px-1 py-1 ml-2 text-xs font-semibold text-white uppercase rounded bg-primary">PRO</span>
          </router-link>
        </div>

        <div v-if="isCurrentAccountPro" class="dropdown-divider"></div>

        <div v-if="!isCurrentAccountAdmin">
          <router-link :to="{ name: 'newRawLegalAd' }" class="dropdown-item">
            Nouvelle annonce texte libre
          </router-link>
          <router-link :to="{ name: 'newModelLegalAd' }" class="dropdown-item">
            Nouvelle annonce via formulaire
          </router-link>

          <hr class="my-3" />

          <router-link :to="{ name: 'myLegalAds' }" class="dropdown-item">
            Mes annonces
          </router-link>
          <router-link
            v-if="isCurrentAccountPro"
            :to="{ name: 'myLegalAdDrafts' }"
            class="dropdown-item"
          >
            Mes devis
          </router-link>
          <router-link :to="{ name: 'editMyAccount' }" class="dropdown-item">
            Mon profil
            <span
              v-if="isCurrentAccountPro"
              class="px-1 py-1 ml-2 text-xs font-semibold text-white uppercase rounded bg-primary"
              >PRO</span
            >
          </router-link>
          <router-link
            v-if="isCurrentAccountPro"
            :to="{ name: 'myLegalAdSettings' }"
            class="dropdown-item"
          >
            Mon modèle d'annonce
          </router-link>

          <hr class="my-3" />
        </div>

        <button class="dropdown-item" @click.prevent="signOut">
          <font-awesome-icon icon="sign-out-alt" class="mr-2" />
          Se déconnecter
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import * as AuthService from "@/services/authentication";

export default {
  name: "KitHeaderAccount",
  data() {
    return { showDropdown: false };
  },
  mounted () {
    document.addEventListener('click', this.close);
  },
  beforeDestroy () {
    document.removeEventListener('click',this.close);
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    // Close dropdown on outside click
    close(event) {
      if (!this.$el.contains(event.target)) {
        this.showDropdown = false;
      }
    },
    signOut() {
      AuthService.signOut();
      try {
        this.$router.push({ name: "home" });
      } catch (err) {
        if (err.name !== "NavigationDuplicated") throw err;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-item {
  @apply block text-left w-full px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 transition-all;
}
</style>