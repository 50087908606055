<template>
  <tr class="row">
    <th class="text-center" scope="row">{{ legalAd.reference }}</th>
    <td class="text-center" scope="row">{{ legalAd.altName }}</td>
    <td class="text-center">{{ totalAmount }}</td>
    <td class="text-center">
      <div v-if="clientBilling">
        <font-awesome-icon icon="check" class="text-success" />
        <p v-if="!legalAd.paid" class="text-xs">Lien de paiement : {{ legalAd.paymentLink }}</p>
      </div>

      <div v-else>-</div>
    </td>
    <td class="text-center">
      <a :href="legalAd.quotePDFUrl" class="btn btn-success" target="_blank">
        <font-awesome-icon icon="file-download" class="mr-2" size="lg" />
        Devis
      </a>

      <button v-if="!clientBilling" @click="$emit('on-client-billing', {encodedId: legalAd.paymentLink.split('/').pop(), id: legalAd.id})" class="my-2 ml-2 btn btn-secondary">
        <font-awesome-icon icon="credit-card" class="mr-1" size="md" />
        Facturer mon client
      </button>

      <router-link
        v-if="!clientBilling"
        :to="{ name: 'editLegalAdDraft', params: { legalAdId: legalAd.id } }"
        class="ml-2 btn btn-secondary btn-xs"
      >
        <font-awesome-icon icon="pencil-ruler" class="mr-2" />
        Éditer
      </router-link> 

      <button @click="deleteDraft" class="mt-2 ml-2 btn btn-danger">
        <font-awesome-icon icon="trash" class="mr-2" size="lg" />
        Supprimer
      </button>
    </td>
  </tr>
</template>

<script>
import * as LegalAdDraftService from '@/services/legal-ad-draft';

export default {
  name: 'MyAccountLegalAdDraftTableRow',
  props: {
    legalAd: {
      type: Object,
      default: null
    }
  },
  computed: {
    totalAmount() {
      return this.numberToCurrency(this.legalAd.totalAmount);
    },
    clientBilling() {
      return this.legalAd?.clientEmail?.length > 0;
    }
  },
  methods: {
    deleteDraft() {
      if (confirm('Êtes-vous sûr(e) ?')) {
        LegalAdDraftService.destroy(this.legalAd.id).then(() => {
          this.$emit('on-delete');
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .row {
    td, th {
      @apply border-b border-gray-200 py-3 px-3;
    }
  }

  .btn {
    @apply px-4 py-2 text-xs rounded rounded-xl transition-all;
    &.btn-success {
      @apply bg-success text-white hover:bg-opacity-90;
    }

    &.btn-secondary {
      @apply bg-gray-800 text-white hover:bg-opacity-90;
    }

    &.btn-danger {
      @apply bg-red-700 text-white hover:bg-opacity-90;
    }

  }
</style>
