import * as Company from '@/services/legal-ad-form/components/closing/company';
import * as HeadOffice from '@/services/legal-ad-form/components/closing/head-office';
import * as Decision from '@/services/legal-ad-form/components/closing/decision';
import * as Person from '@/services/legal-ad-form/components/closing/person';
import * as Mention from '@/services/legal-ad-form/components/closing/mention';
import { trimObject } from '@/shared/utils';

export default {
  id: 'dissolution',
  label: 'Dissolution anticipée',
  shortLabel: 'Dissolution\nanticipée',
  componentsData: {
    company: { legalForm: '' },
    headOffice: {},
    decision: { type: '', actor: '' },
    liquidator: { type: '' },    
    mention: { registry: 'Le mandat des commissaires aux comptes ne se poursuit pas.' } 
  },
  component: () => import('@/components/legal-ads/forms/models/closing/dissolution.vue'),   
  validate(modelData) {
    return trimObject({ 
      company: Company.validate(modelData.company),
      headOffice: HeadOffice.validate(modelData.headOffice),    
      decision: Decision.validate(modelData.decision, { add: ['place'] }),
      liquidator: Person.validate(modelData.liquidator),
      mention: Mention.validate(modelData.mention, { skip: ['registry'] })
    });
  },  
  buildContent(modelData) {
    return [
      Company.buildContent(modelData.company, { withShareCapital: true }),
      HeadOffice.buildContent(modelData.headOffice),     
      Decision.buildDissolutionContent(modelData),      
      Mention.buildContent(modelData.mention)
    ].flat().join("\n").replace(/undefined/g, '?');
  }
};