<template>
  <Layout>
    <div class="container mx-auto py-8 px-8 flex flex-col flex-grow">
      <h1 class="text-primary text-xl font-bold">
        Mon modèle d'annonce
      </h1>
      <EditSettingsForm class="mt-8" />
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/default';
import EditSettingsForm from '@/components/my-account/legal-ads/edit-settings';

export default {
  name: 'MyLegalAdSettingsPage',
  components: { Layout, EditSettingsForm },
};
</script>
