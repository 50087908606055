import * as Company from '@/services/legal-ad-form/components/management-modification/company';
import * as HeadOffice from '@/services/legal-ad-form/components/management-modification/head-office';
import * as Decision from '@/services/legal-ad-form/components/management-modification/decision';
import * as Person from '@/services/legal-ad-form/components/management-modification/person';
import * as Mention from '@/services/legal-ad-form/components/management-modification/mention';
import { trimObject } from '@/shared/utils';

export default {
  id: 'gerant',
  label: 'Gérant',
  shortLabel: 'Gérant',
  componentsData: {
    company: { legalForm: '' },
    headOffice: {},
    decision: { type: '', actor: '' },
    replacementOwnerList: [],
    nominatedOwnerList: [],
    leavingOwnerList: [],    
    mention: { registry: 'Mention sera portée au RCS de ...(à compléter)' } 
  },
  component: () => import('@/components/legal-ads/forms/models/management-modification/owner.vue'),   
  validate(modelData) {
    return trimObject({ 
      company: Company.validate(modelData.company),
      headOffice: HeadOffice.validate(modelData.headOffice),    
      decision: Decision.validate(modelData.decision),
      replacementOwnerList: Person.validateMany(modelData.replacementOwnerList, false, { add: ['simpleName'] }),
      nominatedOwnerList: Person.validateMany(modelData.nominatedOwnerList, false),
      leavingOwnerList: Person.validateMany(modelData.leavingOwnerList, false, { add: ['simpleName'] }),
      mention: Mention.validate(modelData.mention)
    });
  },  
  buildContent(modelData) {
    return [
      Company.buildContent(modelData.company, { withShareCapital: false }),
      HeadOffice.buildContent(modelData.headOffice),     
      Decision.buildOwnerContent(modelData.decision),
      Person.buildManyContent(modelData.replacementOwnerList),      
      Person.buildManyContent(modelData.nominatedOwnerList, 'nomination'),
      Person.buildManyContent(modelData.leavingOwnerList, 'partant'),
      Mention.buildContent(modelData.mention)
    ].flat().join("\n").replace(/undefined/g, '?');
  }
};