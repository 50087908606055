import { validatePresenceOf } from '@/shared/utils';

export const validate = (headOffice, options) => {
  return validatePresenceOf(
    headOffice,
    ['address', 'zipCode', 'city', 'registrationPlace'],
    options
  );
};

export const buildContent = headOffice => {
  var lines = [];

  if (headOffice && (headOffice.address || headOffice.zipCode || headOffice.city)) {
    var address = 'Siège social : ';
    if (headOffice.address) address += headOffice.address;
    if (headOffice.zipCode || headOffice.address) address += ', ';
    if (headOffice.zipCode) address += headOffice.zipCode;
    if (headOffice.city) {
      if (headOffice.zipCode) address += ' ';
      address += headOffice.city;
    }
    address += '.';
    lines.push(address);
  }
  return lines;
};

export const buildRegistrationContent = headOffice => {
  var lines = [];
  if (headOffice && headOffice.registrationPlace)
    lines.push(`La société sera immatriculée au RCS de ${headOffice.registrationPlace}.`);
  return lines;
};