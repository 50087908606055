import { validatePresenceOf } from '@/shared/utils';
import { buildSimpleContent as buildPersonContent } from './person';

export const validate = assets => {
  return validatePresenceOf(assets, ['type', 'address', 'section', 'area']);
};

export const buildContent = ({ assets, appellant }) => {
  var lines = [];

  var person = buildPersonContent(appellant);
  lines.push(`${person} a, en vertu des articles L 526-1 à L 526-4 du code de commerce déclaré insaisissable son bien, constituant en une :`);

  lines.push(`- Nature du bien : ${assets.type}.`);
  lines.push(`- Adresse du bien : ${assets.address}.`);
  lines.push(`- Figurant au cadastre section(s) :${assets.section}.`);
  lines.push(`Pour une superficie de ${assets.area}.`);

  return lines;
};