import AdministratorModel from './administrator';
import AuditorModel from './auditor';
import ChiefExecutiveModel from './chief-executive';
import OwnerModel from './owner';
import ChairmanModel from './chairman';

export default [
  AdministratorModel,
  AuditorModel,
  ChiefExecutiveModel,
  OwnerModel,
  ChairmanModel
];