import testLogo from '@/assets/logos/fidal2.jpg';
import { toDataURL } from '@/shared/utils';

export const buildContent = async (logoUrl, content, isPro) => {
  var logo = undefined;
  try {
    if (logoUrl)
      logo = await toDataURL(logoUrl);
  } catch { /* was unable to load the logo, not an issue */ }
  return { ...buildEmptyModel(isPro), logo, ...content };
}

export const buildEmptyModel = isPro => {
  return {
    preBlock1: {
      title: isPro ? 'Votre Titre En-tête' : 'Nom de votre société',
      body: isPro ? 'Votre Texte' : 'Coordonnées complètes',
      enabled: isPro
    },
    preBlock2: {
      title: isPro ? 'Titre Client' : 'Nom de la société concernée par l\'avis',
      body: isPro ? 'Texte Client' : 'Coordonnées complètes',
      enabled: true
    },
    title: 'Titre Acte',
    body: 'Votre texte d\'annonce',
    signature: 'Pour avis'
  }
}

export const buildContentEmptyAd = async (logoUrl, title, predefinedContent, isPro) => {
  var logo = undefined;
  try {
    if (logoUrl)
      logo = await toDataURL(logoUrl);
  } catch { /* was unable to load the logo, not an issue */ }

  return {
    logo,
    preBlock1: {
      title: predefinedContent?.preBlock1?.title || (isPro ? '*UID QUE NONSEQUI DICTO VOLUPTIURS*' : 'Nom de votre société'),
      body: predefinedContent?.preBlock1?.body || (isPro ? '*Société d’Avocats 2, rue des Feuillants - BP 3127 31026 Toulouse cedex 3*' : 'Coordonnées complètes'),
      enabled: false
    },
    preBlock2: {
      title: predefinedContent?.preBlock2?.title || (isPro ? '*UID QUE NONSEQUI DICTO VOLUPTIU*' : 'Nom de la société concernant par l\'avis'),
      body: predefinedContent?.preBlock2?.body || (isPro ? '*Société d\'Avocats 2, rue des Feuillants - BP 3127 31026 Toulouse cedex 3*' : 'Coordonnées complètes'),
      enabled: false
    },
    title: title || 'TITRE',
    body: '',
    signature: 'Pour avis.',
  }
}

export const buildContentAd = async () => {
  return await toDataURL(testLogo).then(logo => {
    return {
      logo,
      preBlock1: {
        title: '*UID QUE NONSEQUI DICTO VOLUPTIURS*',
        body: '*Société d\'Avocats 2, rue des Feuillants - BP 3127 31026 Toulouse cedex 3*',
        enabled: true
      },
      preBlock2: {
        title: '*UID QUE NONSEQUI DICTO VOLUPTIU*',
        body: '*Société d\'Avocats 2, rue des Feuillants - BP 3127 31026 Toulouse cedex 3*',
        enabled: true
      },
      title: 'Avis de constitution',
      body: '<p>*enimusdant <strong>doluptatem</strong> fugiae: -<strong>nihil</strong>- luptur maximus elles dolorep tatenis aut proratem. <strong>Asi opta seque et am</strong>, que et omnim auda autet<br/> venet inctatiis dentias a sim eiunt ut ut quiatius et eatiane quaeped eatis quam, ne ea audamus aut ligniam, con net est, que nullicium quodis voluptatur. Quid que nonsequi dicto voluptiur am harum solupta volupist omnihil imagnimo quia doluptatium qui dolut et qui inia qua- tusdant labo. Ut que eiur. Ga. Ut et quo voles ese volupta quidel- latis dolorio. Itam, sitae vel ipsam, nos erum imporecaepe que eatur, odi dolesto to beruntur magnatur que eatur, <strong>odi</strong>*</p>',
      signature: '*a signature\nDe quelcu*'
    }
  });
}