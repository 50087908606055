import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserCog, faBold, faCircleNotch, faFilePdf, faUserCircle, faUser, faLock,
  faCheck, faExclamation, faSignOutAlt, faKey, faEnvelope, faPlus, faCogs, faFileDownload, faChevronRight, faSpinner, faPencilRuler, faTrash, faPhone, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add([
  faUserCog,
  faBold,
  faCircleNotch,
  faFilePdf,
  faFileDownload,
  faUserCircle,
  faLock,
  faUser,
  faCheck,
  faExclamation,
  faSignOutAlt,
  faKey,
  faEnvelope,
  faPlus,
  faCogs,
  faSpinner,
  faChevronRight,
  faFacebookF,
  faTwitter,
  faInstagram,
  faYoutube,
  faPencilRuler,
  faTrash,
  faPhone,
  faCreditCard
]);

Vue.component('font-awesome-icon', FontAwesomeIcon);