import { CIVILITY, validatePresenceOf, formatLegalActDate } from '@/shared/utils';

export const validate = (person, options) => {
  return validatePresenceOf(person,
    ['civility', 'firstName', 'lastName', 'address', 'zipCode', 'city', 'birthday', 'birthPlace'],
    options
  );
};

export const validateMany = (list, required, options) => {
  var errors = { list: {} };
  if (list.length === 0 && required) {
    errors.self = 'Cette liste ne peut pas être vide';
  } else {
    list.forEach(person => errors.list[person.id] = validate(person, options));
  }
  return errors;
};

export const buildSimpleContent = person => {
  var fullName = `${CIVILITY[person.civility]} ${person.firstName} ${person.lastName}`;
  var address = `${person.address} ${person.zipCode} ${person.city}`;

  return `${fullName}, demeurant ${address}, exerçant la profession de ${person.job}`;
};

export const buildChildContent = person => {
  var fullName = `${person.firstName} ${person.lastName}`;
  return `${fullName} né le ${formatLegalActDate(person.birthday)} à ${person.birthPlace}.`;
};

export const buildContent = person => {
  var lines = [];
  var fullName = `${CIVILITY[person.civility]} ${person.firstName} ${person.lastName}`;
  var address = `${person.address} ${person.zipCode} ${person.city}`;

  lines.push(`${fullName} né le ${formatLegalActDate(person.birthday)} à ${person.birthPlace}.`);
  lines.push(`Demeurant ${address}.`);

  return lines;
};

export const buildCoupleContent = ({ partner1, partner2 }) => {
  var lines = [];

  var person = `${CIVILITY[partner1.civility]} ${partner1.firstName} ${partner1.lastName}`;
  lines.push(`${person} né le ${formatLegalActDate(partner1.birthday)} à ${partner1.birthPlace}.`);

  lines.push(`Et`);

  person = `${CIVILITY[partner2.civility]} ${partner2.firstName} ${partner2.lastName}`;
  lines.push(`${person} né le ${formatLegalActDate(partner2.birthday)} à ${partner2.birthPlace}.`);

  return lines;
};