<template>
  <div class="p-6">
    <h1 class="flex pb-4 text-2xl font-extrabold border-b border-yellow-300 text-primary">
      Besoin d'un site internet ?
    </h1>

    <div v-if="isCurrentAccountPro">
      <p class="mt-8">Vos clients lancent leur activité ? Ont besoin de créer ou de refondre leur site ? D'améliorer leur présence sur le web ? Ou de mettre en place une stratégie sur les réseaux sociaux ?</p>
      <p class="mt-8">Saisissez le nom de la société ou l'email dans la case ci-contre afin que nous puissions échanger avec eux sur leur problématique.</p>
    </div>

    <div v-else>
      <p class="mt-8">Qu'il soit vitrine, e-commerce, mobile, communautaire ou institutionnel, avoir un site internet est aujourd'hui primordial pour une entreprise.</p>
      <p class="mt-2">C'est pour cela que nous vous accompagnons dans la création ou la refonte de votre site mais également dans son référencement (création de contenu écrit, audio ou vidéo, réseaux sociaux...)</p>
    </div>

    <form @submit.prevent="sendRequest" class="mt-8">
      <div v-if="!isCurrentAccountPro" class="grid grid-cols-1 mt-8">
        <div class="col-span-1">
          <div class="input">
            <label for="contact-request-email">Message (optionnel)</label>
            <textarea-autosize
              class="form-control form-input" 
              size="3"
              placeholder="Entrez votre message"
              v-model="contactRequest.message"
              :min-height="100"
            />
          </div>
        </div>
      </div>

      <div v-if="isCurrentAccountPro" class="grid grid-cols-1 mt-8">
        <div class="col-span-1">
          <div class="input">
            <label for="contact-request-email">Email ou nom de la société</label>
            <input type="text" id="contact-request-email-or-society" required placeholder="Entrez un email ou un nom de cosiété" v-model="contactRequest.email" />
          </div>
        </div>
      </div>

      <p v-if="isCurrentAccountPro" class="mt-2 text-sm">En soumettant ce formulaire, j'accepte que les données saisies soient utilisées pour répondre à ma demande de contact.</p>
      <p v-else class="mt-2 text-sm">En soumettant ce formulaire, j'accepte que mes données soient utilisées pour répondre à ma demande de contact.</p>

      <t-alert class="mt-8" v-if="errorMessage && errorMessage.length > 0">
        {{ errorMessage }}
      </t-alert>

      <!-- TODO: @fralps replace href with Fabien website when it's ready -->
      <div class="flex items-center justify-end">
        <div v-if="false" class="mt-12 text-left">
          <a
            href="https://google.fr"
            rel="noopener noreferrer"
            target="_blank"
            class="px-4 py-3 text-white transition-all bg-accent hover:bg-opacity-90"
          >
            Nos solutions
          </a>
        </div>

        <div class="mt-12 text-right">
          <button 
            type="button" 
            class="mr-4 font-semibold hover:text-gray-900"
            @click="() => $modal.hide('additional-services-modal')"
          >
            Fermer
          </button>

          <t-submit-button
            :buttonState="buttonState"
            :labels="buttonLabels"
          />
        </div>
      </div>
      <p class="mt-2 text-sm">
        {{ isCurrentAccountPro ? '*Nous les rappellerons dans les meilleurs délais.' : '*Nous vous rappellerons dans les meilleurs délais.' }}
      </p>
    </form>
  </div>
</template>

<script>
  import * as AdditionalService from '@/services/additional-service';
  import { numbersOnly } from '@/helpers';

  export default {
    name: 'AdditionalServicesModal',
    data() {
      return {
        contactRequest: {
          fullName: null,
          phoneNumber: null,
          email: null,
          message: null
        },
        phoneNumbersOnly: false,
        buttonState: 'default',
        buttonLabels: {
          default: '',
          inProgress: 'En cours...',
          success: 'Message envoyé !',
          fail: 'Échec'
        },
        errorMessage: null
      }    
    },
    mounted() {
      this.fillData();
      this.buttonLabels.default = this.isCurrentAccountPro ? 'Envoyer*' : 'Être rappelé *';
    },
    methods: {
      fillData() {
        this.contactRequest = {
          fullName: this.currentAccount?.fullName,
          email: this.currentAccount?.email,
          phoneNumber: this.isCurrentAccountPro ? '-' : this.currentAccount?.phoneNumber,
          message: this.isCurrentAccountPro ? 'Pas de message' : ''
        };
      },
      checkInput(event) {
        this.phoneNumbersOnly = numbersOnly(event);
      },
      resetForm() {
        this.buttonState = 'success';
        this.contactRequest = {};
      },
      handleError() {
        this.buttonState = 'fail';
        this.errorMessage = 'Une erreur est survenue, veuillez réessayer plus tard.';
      },
      handleSuccess() {
        this.resetForm();
        setTimeout(() => {
          this.$modal.hide('additional-services-modal');
        }, 3000);
      },
      sendRequest() {
        this.buttonState = 'inProgress';
        AdditionalService.sendRequest(this.currentAccount.id, this.contactRequest)
          .then(() => this.handleSuccess())
          .catch((error) => this.handleError(error));
      }
    }
  }
</script>
